package ru.arty_bikini.crm_frontend.ui.input

import csstype.ClassName
import csstype.PropertiesBuilder
import emotion.react.css
import react.Props
import react.dom.html.ReactHTML
import react.useEffect
import react.useState
import ru.arty_bikini.crm_frontend.ui.root.tryFC
import ru.arty_bikini.crm_frontend.util.plus

external interface InlineInputProps : Props {
    var css: ((PropertiesBuilder) -> Unit)?
    var value: String?
    var onBlur: ((String) -> Unit)?

}

val InlineInput = tryFC<InlineInputProps> { props ->

    var value: String by useState(props.value ?: "")

    useEffect(props.value) {
        value = props.value ?: ""
    }


    ReactHTML.input {
        css {
            props.css?.invoke(this)
        }

        className = className + ClassName("form-control form-control-sm")

        this.value = value

        onChange = {
            value = it.target.value
        }

        onBlur = {
            val value = it.target.value

            props.onBlur?.invoke(value)
        }
    }

}