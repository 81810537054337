package ru.arty_bikini.crm_frontend.ui.bootstrap

import csstype.ClassName
import ru.arty_bikini.crm_frontend.util.plus

object Bootstrap {

    val card = ClassName("card")
    val cardHeader = ClassName("card-header")
    val cardBody = ClassName("card-body")

    val tableDefault = ClassName("table table-sm table-striped table-hover")

    fun alert(color: BootstrapColor): ClassName {
        return ClassName("alert alert-${color.suffix}")
    }

    object Utils {
        val m4 = ClassName("m-4")
        val justifyContentCenter = ClassName("justify-content-center")
        val shadow = ClassName("shadow")
    }
}

object BootstrapButton {
    val btnColorless = ClassName("btn btn-sm")
    val btn = btnColorless + ClassName("btn-outline-secondary")

    fun outline(color: BootstrapColor) = btnColorless + ClassName("btn-outline-${color.suffix}")
    fun painted(color: BootstrapColor) = btnColorless + ClassName("btn-${color.suffix}")
}
