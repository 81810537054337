package ru.arty_bikini.crm_frontend.table.config

import ru.arty_bikini.crm.dto.dict.ExpressDTO
import ru.arty_bikini.crm_frontend.table.common.dictTablePage
import ru.arty_bikini.crm_frontend.ui.input.table.*

val ExpressTablePage = dictTablePage(
    serializer = ExpressDTO.serializer(),
    load = { dict.getExpress()?.expressDTOList },
    save = { dict.editExpress(it) },
    addNew = { dict.addExpress(ExpressDTO().apply {
        this.minDays = it.toIntOrNull() ?: 0
        this.maxDays = 1 + (it.toIntOrNull() ?: 0)
    }) },
) {

    addInt(FieldFlag.NotNull, "Дней (от)", ExpressDTO::minDays)
    addInt(FieldFlag.NotNull, "Дней (до)", ExpressDTO::maxDays)
    addCost(FieldFlag.NotNull, "Надбавка за срочность", ExpressDTO::cost)
//    addInt(FieldFlag.NotNull, "Сортировка", options = TableColumnRenderOptions(defaultSort = true)) { this::priority }
//    addBoolean(FieldFlag.NotNull, "Показать") { this::visible }

}
