package ru.arty_bikini.crm_frontend.network

import ru.arty_bikini.crm.dto.packet.auth.EditUserRequest
import ru.arty_bikini.crm.dto.packet.auth.EditUserResponse
import ru.arty_bikini.crm.dto.packet.core.PullRequest
import ru.arty_bikini.crm.dto.packet.core.PullResponse
import ru.arty_bikini.crm_frontend.ClientCore

class CoreService(clientCore: ClientCore) : BaseService(clientCore) {
    suspend fun pull(): ServerResponse<PullResponse> {
        return call(url = "/api/core/pull", body = PullRequest())
    }
}
