package ru.arty_bikini.crm_frontend.util

import ru.arty_bikini.crm.dto.UserDTO
import ru.arty_bikini.crm.dto.orders.*
import ru.arty_bikini.crm.dto.orders.google.OrderDataTypeDTO
import ru.arty_bikini.crm.dto.work.WorkTypeDTO
import ru.arty_bikini.crm_frontend.ClientCore
import ru.arty_bikini.crm_frontend.measure.MeasureType
import ru.arty_bikini.crm_frontend.table.production.worksTMP

fun OrderDTO.personalData(): PersonalDataDTO = personalData ?: PersonalDataDTO().also { personalData = it }
fun OrderDTO.design(): DesignDTO = design ?: DesignDTO().also { design = it }
fun OrderDTO.leadInfo(): LeadInfoDTO = leadInfo ?: LeadInfoDTO().also { leadInfo = it  }
fun OrderDTO.statusInfo(): StatusInfoDTO = statusInfo ?: StatusInfoDTO().also { statusInfo = it  }


val OrderDTO.allOk get() = statusInfo().isMeasureAll && statusInfo().isDesignAll && statusInfo().isMeasureAllTanya && statusInfo().isDesignAllTanya
val OrderDTO.allFilled get() = statusInfo().isMeasureAll && statusInfo().isDesignAll

fun OrderDTO.hasAllWorks(workTypes: List<WorkTypeDTO>, forUser: UserDTO): Boolean {
    return workTypes
        .filterBy(forUser, this)
        .all { w -> worksTMP.any { it.works?.contains(w) == true } }
}
fun OrderDTO.hasAllWorks(workTypes: List<WorkTypeDTO>, forUser: Nothing?): Boolean {
    return workTypes
        .filterBy(forUser, this)
        .all { w -> worksTMP.any { it.works?.contains(w) == true } }
}