package ru.arty_bikini.crm_frontend.ui.bootstrap

import csstype.ClassName
import csstype.PropertiesBuilder
import emotion.react.css
import react.ChildrenBuilder
import react.dom.html.ReactHTML.span
import ru.arty_bikini.crm_frontend.util.plus

fun ChildrenBuilder.badge(
    color: BootstrapColor,
    text: String,
    extraClass: ClassName? = null,
    title: String? = null,
    extraCss: (PropertiesBuilder.() -> Unit)? = null,
) {
    span {
        css {
            extraCss?.invoke(this)
        }
        
        className = className + ClassName("badge bg-${color.suffix} ${color.textDark}") + extraClass

        + text

        this.title = title
    }
}

enum class BootstrapColor(val suffix: String, val light: Boolean) {
    PRIMARY("primary", false),
    SECONDARY("secondary", false),
    DANGER("danger", false),
    WARNING("warning", true),
    SUCCESS("success", false),
    INFO("info", true),
    LIGHT("light", true),
    ;

    val textDark: ClassName = if (light) ClassName("text-dark") else ClassName("")
}