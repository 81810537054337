package ru.arty_bikini.crm_frontend.network

import ru.arty_bikini.crm.dto.orders.OrderDTO
import ru.arty_bikini.crm.dto.orders.stone.CalcPresetDTO
import ru.arty_bikini.crm.dto.orders.stone.OrderRhinestoneAmountDTO
import ru.arty_bikini.crm.dto.packet.order_stone.*
import ru.arty_bikini.crm_frontend.ClientCore

class OrderStoneService(clientCore: ClientCore) : BaseService(clientCore) {

    suspend fun saveOrderStones(order: OrderDTO, amount: List<OrderRhinestoneAmountDTO>): SaveResponse? {
        return call<SaveResponse, SaveRequest>(
            "/api/order-stone/save", body = SaveRequest(
                order.id,
                amount
            )
        ).body
    }

    suspend fun getPresets(): GetPresetsResponse? {
        return call<GetPresetsResponse>("/api/order-stone/get-presets").body
    }

    suspend fun addPreset(preset: CalcPresetDTO): AddPresetsResponse? {
        return call<AddPresetsResponse, AddPresetsRequest>("/api/order-stone/add-presets", body = AddPresetsRequest(preset)).body
    }

    suspend fun editPreset(preset: CalcPresetDTO): EditPresetsResponse? {
        return call<EditPresetsResponse, EditPresetsRequest>("/api/order-stone/edit-presets", body = EditPresetsRequest(preset)).body
    }

}