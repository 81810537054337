package ru.arty_bikini.crm.dto.other

import kotlinx.serialization.Serializable
import ru.arty_bikini.crm.dto.EntityDTO
import ru.arty_bikini.crm.dto.UserDTO
import ru.arty_bikini.crm.dto.enums.HasDisplayValue

@Serializable
class HistoryDTO : EntityDTO(){
    override var id = 0
    var entityType : String? = null
    var entityId = -1
    var entityName: String? = null

    var editTime: Long? = null
    var editor: UserDTO? = null

    var oldValue: String? = null
    var newValue: String? = null

    var actionType: HistoryActionType? = null
    var actionFlags: List<HistoryActionFlag> = emptyList()

}

enum class HistoryActionType(override val displayName: String) : HasDisplayValue {
    ADD_ORDER("Заказ добавлен"),
    EDIT_ORDER("Заказ изменен"),
    LINK_GOOGLE("Мерки привязаны"),
    UNLINK_GOOGLE("Мерки отвязаны"),
    EDIT_ORDER_MEASURE("Мерки изменены"),
    EDIT_ORDER_STONE("Стразы изменены"),
}

enum class HistoryActionFlag(override val displayName: String) : HasDisplayValue {

}
