/*
 * Copyright 2010-2022 JetBrains s.r.o. and Kotlin Programming Language contributors.
 * Use of this source code is governed by the Apache 2.0 license that can be found in the license/LICENSE.txt file.
 */

@file:kotlin.jvm.JvmMultifileClass
@file:kotlin.jvm.JvmName("URangesKt")

package kotlin.ranges

//
// NOTE: THIS FILE IS AUTO-GENERATED by the GenerateStandardLib.kt
// See: https://github.com/JetBrains/kotlin/tree/master/libraries/stdlib
//

import kotlin.random.*

/**
 * Returns the first element.
 * 
 * @throws NoSuchElementException if the progression is empty.
 */
@SinceKotlin("1.7")
public fun UIntProgression.first(): UInt {
    if (isEmpty())
        throw NoSuchElementException("Progression $this is empty.")
    return this.first
}

/**
 * Returns the first element.
 * 
 * @throws NoSuchElementException if the progression is empty.
 */
@SinceKotlin("1.7")
public fun ULongProgression.first(): ULong {
    if (isEmpty())
        throw NoSuchElementException("Progression $this is empty.")
    return this.first
}

/**
 * Returns the first element, or `null` if the progression is empty.
 */
@SinceKotlin("1.7")
public fun UIntProgression.firstOrNull(): UInt? {
    return if (isEmpty()) null else this.first
}

/**
 * Returns the first element, or `null` if the progression is empty.
 */
@SinceKotlin("1.7")
public fun ULongProgression.firstOrNull(): ULong? {
    return if (isEmpty()) null else this.first
}

/**
 * Returns the last element.
 * 
 * @throws NoSuchElementException if the progression is empty.
 * 
 * @sample samples.collections.Collections.Elements.last
 */
@SinceKotlin("1.7")
public fun UIntProgression.last(): UInt {
    if (isEmpty())
        throw NoSuchElementException("Progression $this is empty.")
    return this.last
}

/**
 * Returns the last element.
 * 
 * @throws NoSuchElementException if the progression is empty.
 * 
 * @sample samples.collections.Collections.Elements.last
 */
@SinceKotlin("1.7")
public fun ULongProgression.last(): ULong {
    if (isEmpty())
        throw NoSuchElementException("Progression $this is empty.")
    return this.last
}

/**
 * Returns the last element, or `null` if the progression is empty.
 * 
 * @sample samples.collections.Collections.Elements.last
 */
@SinceKotlin("1.7")
public fun UIntProgression.lastOrNull(): UInt? {
    return if (isEmpty()) null else this.last
}

/**
 * Returns the last element, or `null` if the progression is empty.
 * 
 * @sample samples.collections.Collections.Elements.last
 */
@SinceKotlin("1.7")
public fun ULongProgression.lastOrNull(): ULong? {
    return if (isEmpty()) null else this.last
}

/**
 * Returns a random element from this range.
 * 
 * @throws IllegalArgumentException if this range is empty.
 */
@SinceKotlin("1.5")
@WasExperimental(ExperimentalUnsignedTypes::class)
@kotlin.internal.InlineOnly
public inline fun UIntRange.random(): UInt {
    return random(Random)
}

/**
 * Returns a random element from this range.
 * 
 * @throws IllegalArgumentException if this range is empty.
 */
@SinceKotlin("1.5")
@WasExperimental(ExperimentalUnsignedTypes::class)
@kotlin.internal.InlineOnly
public inline fun ULongRange.random(): ULong {
    return random(Random)
}

/**
 * Returns a random element from this range using the specified source of randomness.
 * 
 * @throws IllegalArgumentException if this range is empty.
 */
@SinceKotlin("1.5")
@WasExperimental(ExperimentalUnsignedTypes::class)
public fun UIntRange.random(random: Random): UInt {
    try {
        return random.nextUInt(this)
    } catch(e: IllegalArgumentException) {
        throw NoSuchElementException(e.message)
    }
}

/**
 * Returns a random element from this range using the specified source of randomness.
 * 
 * @throws IllegalArgumentException if this range is empty.
 */
@SinceKotlin("1.5")
@WasExperimental(ExperimentalUnsignedTypes::class)
public fun ULongRange.random(random: Random): ULong {
    try {
        return random.nextULong(this)
    } catch(e: IllegalArgumentException) {
        throw NoSuchElementException(e.message)
    }
}

/**
 * Returns a random element from this range, or `null` if this range is empty.
 */
@SinceKotlin("1.5")
@WasExperimental(ExperimentalStdlibApi::class, ExperimentalUnsignedTypes::class)
@kotlin.internal.InlineOnly
public inline fun UIntRange.randomOrNull(): UInt? {
    return randomOrNull(Random)
}

/**
 * Returns a random element from this range, or `null` if this range is empty.
 */
@SinceKotlin("1.5")
@WasExperimental(ExperimentalStdlibApi::class, ExperimentalUnsignedTypes::class)
@kotlin.internal.InlineOnly
public inline fun ULongRange.randomOrNull(): ULong? {
    return randomOrNull(Random)
}

/**
 * Returns a random element from this range using the specified source of randomness, or `null` if this range is empty.
 */
@SinceKotlin("1.5")
@WasExperimental(ExperimentalStdlibApi::class, ExperimentalUnsignedTypes::class)
public fun UIntRange.randomOrNull(random: Random): UInt? {
    if (isEmpty())
        return null
    return random.nextUInt(this)
}

/**
 * Returns a random element from this range using the specified source of randomness, or `null` if this range is empty.
 */
@SinceKotlin("1.5")
@WasExperimental(ExperimentalStdlibApi::class, ExperimentalUnsignedTypes::class)
public fun ULongRange.randomOrNull(random: Random): ULong? {
    if (isEmpty())
        return null
    return random.nextULong(this)
}

/**
 * Returns `true` if this range contains the specified [element].
 * 
 * Always returns `false` if the [element] is `null`.
 */
@SinceKotlin("1.5")
@WasExperimental(ExperimentalUnsignedTypes::class)
@kotlin.internal.InlineOnly
public inline operator fun UIntRange.contains(element: UInt?): Boolean {
    return element != null && contains(element)
}

/**
 * Returns `true` if this range contains the specified [element].
 * 
 * Always returns `false` if the [element] is `null`.
 */
@SinceKotlin("1.5")
@WasExperimental(ExperimentalUnsignedTypes::class)
@kotlin.internal.InlineOnly
public inline operator fun ULongRange.contains(element: ULong?): Boolean {
    return element != null && contains(element)
}

/**
 * Checks if the specified [value] belongs to this range.
 */
@SinceKotlin("1.5")
@WasExperimental(ExperimentalUnsignedTypes::class)
public operator fun UIntRange.contains(value: UByte): Boolean {
    return contains(value.toUInt())
}

/**
 * Checks if the specified [value] belongs to this range.
 */
@SinceKotlin("1.5")
@WasExperimental(ExperimentalUnsignedTypes::class)
public operator fun ULongRange.contains(value: UByte): Boolean {
    return contains(value.toULong())
}

/**
 * Checks if the specified [value] belongs to this range.
 */
@SinceKotlin("1.5")
@WasExperimental(ExperimentalUnsignedTypes::class)
public operator fun ULongRange.contains(value: UInt): Boolean {
    return contains(value.toULong())
}

/**
 * Checks if the specified [value] belongs to this range.
 */
@SinceKotlin("1.5")
@WasExperimental(ExperimentalUnsignedTypes::class)
public operator fun UIntRange.contains(value: ULong): Boolean {
    return (value shr UInt.SIZE_BITS) == 0uL && contains(value.toUInt())
}

/**
 * Checks if the specified [value] belongs to this range.
 */
@SinceKotlin("1.5")
@WasExperimental(ExperimentalUnsignedTypes::class)
public operator fun UIntRange.contains(value: UShort): Boolean {
    return contains(value.toUInt())
}

/**
 * Checks if the specified [value] belongs to this range.
 */
@SinceKotlin("1.5")
@WasExperimental(ExperimentalUnsignedTypes::class)
public operator fun ULongRange.contains(value: UShort): Boolean {
    return contains(value.toULong())
}

/**
 * Returns a progression from this value down to the specified [to] value with the step -1.
 * 
 * The [to] value should be less than or equal to `this` value.
 * If the [to] value is greater than `this` value the returned progression is empty.
 */
@SinceKotlin("1.5")
@WasExperimental(ExperimentalUnsignedTypes::class)
public infix fun UByte.downTo(to: UByte): UIntProgression {
    return UIntProgression.fromClosedRange(this.toUInt(), to.toUInt(), -1)
}

/**
 * Returns a progression from this value down to the specified [to] value with the step -1.
 * 
 * The [to] value should be less than or equal to `this` value.
 * If the [to] value is greater than `this` value the returned progression is empty.
 */
@SinceKotlin("1.5")
@WasExperimental(ExperimentalUnsignedTypes::class)
public infix fun UInt.downTo(to: UInt): UIntProgression {
    return UIntProgression.fromClosedRange(this, to, -1)
}

/**
 * Returns a progression from this value down to the specified [to] value with the step -1.
 * 
 * The [to] value should be less than or equal to `this` value.
 * If the [to] value is greater than `this` value the returned progression is empty.
 */
@SinceKotlin("1.5")
@WasExperimental(ExperimentalUnsignedTypes::class)
public infix fun ULong.downTo(to: ULong): ULongProgression {
    return ULongProgression.fromClosedRange(this, to, -1L)
}

/**
 * Returns a progression from this value down to the specified [to] value with the step -1.
 * 
 * The [to] value should be less than or equal to `this` value.
 * If the [to] value is greater than `this` value the returned progression is empty.
 */
@SinceKotlin("1.5")
@WasExperimental(ExperimentalUnsignedTypes::class)
public infix fun UShort.downTo(to: UShort): UIntProgression {
    return UIntProgression.fromClosedRange(this.toUInt(), to.toUInt(), -1)
}

/**
 * Returns a range from this value up to but excluding the specified [to] value.
 * 
 * If the [to] value is less than or equal to `this` value, then the returned range is empty.
 */
@SinceKotlin("1.7")
@ExperimentalStdlibApi
@kotlin.internal.InlineOnly
public inline operator fun UByte.rangeUntil(to: UByte): UIntRange {
    return until(to)
}

/**
 * Returns a range from this value up to but excluding the specified [to] value.
 * 
 * If the [to] value is less than or equal to `this` value, then the returned range is empty.
 */
@SinceKotlin("1.7")
@ExperimentalStdlibApi
@kotlin.internal.InlineOnly
public inline operator fun UInt.rangeUntil(to: UInt): UIntRange {
    return until(to)
}

/**
 * Returns a range from this value up to but excluding the specified [to] value.
 * 
 * If the [to] value is less than or equal to `this` value, then the returned range is empty.
 */
@SinceKotlin("1.7")
@ExperimentalStdlibApi
@kotlin.internal.InlineOnly
public inline operator fun ULong.rangeUntil(to: ULong): ULongRange {
    return until(to)
}

/**
 * Returns a range from this value up to but excluding the specified [to] value.
 * 
 * If the [to] value is less than or equal to `this` value, then the returned range is empty.
 */
@SinceKotlin("1.7")
@ExperimentalStdlibApi
@kotlin.internal.InlineOnly
public inline operator fun UShort.rangeUntil(to: UShort): UIntRange {
    return until(to)
}

/**
 * Returns a progression that goes over the same range in the opposite direction with the same step.
 */
@SinceKotlin("1.5")
@WasExperimental(ExperimentalUnsignedTypes::class)
public fun UIntProgression.reversed(): UIntProgression {
    return UIntProgression.fromClosedRange(last, first, -step)
}

/**
 * Returns a progression that goes over the same range in the opposite direction with the same step.
 */
@SinceKotlin("1.5")
@WasExperimental(ExperimentalUnsignedTypes::class)
public fun ULongProgression.reversed(): ULongProgression {
    return ULongProgression.fromClosedRange(last, first, -step)
}

/**
 * Returns a progression that goes over the same range with the given step.
 */
@SinceKotlin("1.5")
@WasExperimental(ExperimentalUnsignedTypes::class)
public infix fun UIntProgression.step(step: Int): UIntProgression {
    checkStepIsPositive(step > 0, step)
    return UIntProgression.fromClosedRange(first, last, if (this.step > 0) step else -step)
}

/**
 * Returns a progression that goes over the same range with the given step.
 */
@SinceKotlin("1.5")
@WasExperimental(ExperimentalUnsignedTypes::class)
public infix fun ULongProgression.step(step: Long): ULongProgression {
    checkStepIsPositive(step > 0, step)
    return ULongProgression.fromClosedRange(first, last, if (this.step > 0) step else -step)
}

/**
 * Returns a range from this value up to but excluding the specified [to] value.
 * 
 * If the [to] value is less than or equal to `this` value, then the returned range is empty.
 */
@SinceKotlin("1.5")
@WasExperimental(ExperimentalUnsignedTypes::class)
public infix fun UByte.until(to: UByte): UIntRange {
    if (to <= UByte.MIN_VALUE) return UIntRange.EMPTY
    return this.toUInt() .. (to - 1u).toUInt()
}

/**
 * Returns a range from this value up to but excluding the specified [to] value.
 * 
 * If the [to] value is less than or equal to `this` value, then the returned range is empty.
 */
@SinceKotlin("1.5")
@WasExperimental(ExperimentalUnsignedTypes::class)
public infix fun UInt.until(to: UInt): UIntRange {
    if (to <= UInt.MIN_VALUE) return UIntRange.EMPTY
    return this .. (to - 1u).toUInt()
}

/**
 * Returns a range from this value up to but excluding the specified [to] value.
 * 
 * If the [to] value is less than or equal to `this` value, then the returned range is empty.
 */
@SinceKotlin("1.5")
@WasExperimental(ExperimentalUnsignedTypes::class)
public infix fun ULong.until(to: ULong): ULongRange {
    if (to <= ULong.MIN_VALUE) return ULongRange.EMPTY
    return this .. (to - 1u).toULong()
}

/**
 * Returns a range from this value up to but excluding the specified [to] value.
 * 
 * If the [to] value is less than or equal to `this` value, then the returned range is empty.
 */
@SinceKotlin("1.5")
@WasExperimental(ExperimentalUnsignedTypes::class)
public infix fun UShort.until(to: UShort): UIntRange {
    if (to <= UShort.MIN_VALUE) return UIntRange.EMPTY
    return this.toUInt() .. (to - 1u).toUInt()
}

/**
 * Ensures that this value is not less than the specified [minimumValue].
 * 
 * @return this value if it's greater than or equal to the [minimumValue] or the [minimumValue] otherwise.
 * 
 * @sample samples.comparisons.ComparableOps.coerceAtLeastUnsigned
 */
@SinceKotlin("1.5")
@WasExperimental(ExperimentalUnsignedTypes::class)
public fun UInt.coerceAtLeast(minimumValue: UInt): UInt {
    return if (this < minimumValue) minimumValue else this
}

/**
 * Ensures that this value is not less than the specified [minimumValue].
 * 
 * @return this value if it's greater than or equal to the [minimumValue] or the [minimumValue] otherwise.
 * 
 * @sample samples.comparisons.ComparableOps.coerceAtLeastUnsigned
 */
@SinceKotlin("1.5")
@WasExperimental(ExperimentalUnsignedTypes::class)
public fun ULong.coerceAtLeast(minimumValue: ULong): ULong {
    return if (this < minimumValue) minimumValue else this
}

/**
 * Ensures that this value is not less than the specified [minimumValue].
 * 
 * @return this value if it's greater than or equal to the [minimumValue] or the [minimumValue] otherwise.
 * 
 * @sample samples.comparisons.ComparableOps.coerceAtLeastUnsigned
 */
@SinceKotlin("1.5")
@WasExperimental(ExperimentalUnsignedTypes::class)
public fun UByte.coerceAtLeast(minimumValue: UByte): UByte {
    return if (this < minimumValue) minimumValue else this
}

/**
 * Ensures that this value is not less than the specified [minimumValue].
 * 
 * @return this value if it's greater than or equal to the [minimumValue] or the [minimumValue] otherwise.
 * 
 * @sample samples.comparisons.ComparableOps.coerceAtLeastUnsigned
 */
@SinceKotlin("1.5")
@WasExperimental(ExperimentalUnsignedTypes::class)
public fun UShort.coerceAtLeast(minimumValue: UShort): UShort {
    return if (this < minimumValue) minimumValue else this
}

/**
 * Ensures that this value is not greater than the specified [maximumValue].
 * 
 * @return this value if it's less than or equal to the [maximumValue] or the [maximumValue] otherwise.
 * 
 * @sample samples.comparisons.ComparableOps.coerceAtMostUnsigned
 */
@SinceKotlin("1.5")
@WasExperimental(ExperimentalUnsignedTypes::class)
public fun UInt.coerceAtMost(maximumValue: UInt): UInt {
    return if (this > maximumValue) maximumValue else this
}

/**
 * Ensures that this value is not greater than the specified [maximumValue].
 * 
 * @return this value if it's less than or equal to the [maximumValue] or the [maximumValue] otherwise.
 * 
 * @sample samples.comparisons.ComparableOps.coerceAtMostUnsigned
 */
@SinceKotlin("1.5")
@WasExperimental(ExperimentalUnsignedTypes::class)
public fun ULong.coerceAtMost(maximumValue: ULong): ULong {
    return if (this > maximumValue) maximumValue else this
}

/**
 * Ensures that this value is not greater than the specified [maximumValue].
 * 
 * @return this value if it's less than or equal to the [maximumValue] or the [maximumValue] otherwise.
 * 
 * @sample samples.comparisons.ComparableOps.coerceAtMostUnsigned
 */
@SinceKotlin("1.5")
@WasExperimental(ExperimentalUnsignedTypes::class)
public fun UByte.coerceAtMost(maximumValue: UByte): UByte {
    return if (this > maximumValue) maximumValue else this
}

/**
 * Ensures that this value is not greater than the specified [maximumValue].
 * 
 * @return this value if it's less than or equal to the [maximumValue] or the [maximumValue] otherwise.
 * 
 * @sample samples.comparisons.ComparableOps.coerceAtMostUnsigned
 */
@SinceKotlin("1.5")
@WasExperimental(ExperimentalUnsignedTypes::class)
public fun UShort.coerceAtMost(maximumValue: UShort): UShort {
    return if (this > maximumValue) maximumValue else this
}

/**
 * Ensures that this value lies in the specified range [minimumValue]..[maximumValue].
 * 
 * @return this value if it's in the range, or [minimumValue] if this value is less than [minimumValue], or [maximumValue] if this value is greater than [maximumValue].
 * 
 * @sample samples.comparisons.ComparableOps.coerceInUnsigned
 */
@SinceKotlin("1.5")
@WasExperimental(ExperimentalUnsignedTypes::class)
public fun UInt.coerceIn(minimumValue: UInt, maximumValue: UInt): UInt {
    if (minimumValue > maximumValue) throw IllegalArgumentException("Cannot coerce value to an empty range: maximum $maximumValue is less than minimum $minimumValue.")
    if (this < minimumValue) return minimumValue
    if (this > maximumValue) return maximumValue
    return this
}

/**
 * Ensures that this value lies in the specified range [minimumValue]..[maximumValue].
 * 
 * @return this value if it's in the range, or [minimumValue] if this value is less than [minimumValue], or [maximumValue] if this value is greater than [maximumValue].
 * 
 * @sample samples.comparisons.ComparableOps.coerceInUnsigned
 */
@SinceKotlin("1.5")
@WasExperimental(ExperimentalUnsignedTypes::class)
public fun ULong.coerceIn(minimumValue: ULong, maximumValue: ULong): ULong {
    if (minimumValue > maximumValue) throw IllegalArgumentException("Cannot coerce value to an empty range: maximum $maximumValue is less than minimum $minimumValue.")
    if (this < minimumValue) return minimumValue
    if (this > maximumValue) return maximumValue
    return this
}

/**
 * Ensures that this value lies in the specified range [minimumValue]..[maximumValue].
 * 
 * @return this value if it's in the range, or [minimumValue] if this value is less than [minimumValue], or [maximumValue] if this value is greater than [maximumValue].
 * 
 * @sample samples.comparisons.ComparableOps.coerceInUnsigned
 */
@SinceKotlin("1.5")
@WasExperimental(ExperimentalUnsignedTypes::class)
public fun UByte.coerceIn(minimumValue: UByte, maximumValue: UByte): UByte {
    if (minimumValue > maximumValue) throw IllegalArgumentException("Cannot coerce value to an empty range: maximum $maximumValue is less than minimum $minimumValue.")
    if (this < minimumValue) return minimumValue
    if (this > maximumValue) return maximumValue
    return this
}

/**
 * Ensures that this value lies in the specified range [minimumValue]..[maximumValue].
 * 
 * @return this value if it's in the range, or [minimumValue] if this value is less than [minimumValue], or [maximumValue] if this value is greater than [maximumValue].
 * 
 * @sample samples.comparisons.ComparableOps.coerceInUnsigned
 */
@SinceKotlin("1.5")
@WasExperimental(ExperimentalUnsignedTypes::class)
public fun UShort.coerceIn(minimumValue: UShort, maximumValue: UShort): UShort {
    if (minimumValue > maximumValue) throw IllegalArgumentException("Cannot coerce value to an empty range: maximum $maximumValue is less than minimum $minimumValue.")
    if (this < minimumValue) return minimumValue
    if (this > maximumValue) return maximumValue
    return this
}

/**
 * Ensures that this value lies in the specified [range].
 * 
 * @return this value if it's in the [range], or `range.start` if this value is less than `range.start`, or `range.endInclusive` if this value is greater than `range.endInclusive`.
 * 
 * @sample samples.comparisons.ComparableOps.coerceInUnsigned
 */
@SinceKotlin("1.5")
@WasExperimental(ExperimentalUnsignedTypes::class)
public fun UInt.coerceIn(range: ClosedRange<UInt>): UInt {
    if (range is ClosedFloatingPointRange) {
        return this.coerceIn<UInt>(range)
    }
    if (range.isEmpty()) throw IllegalArgumentException("Cannot coerce value to an empty range: $range.")
    return when {
        this < range.start -> range.start
        this > range.endInclusive -> range.endInclusive
        else -> this
    }
}

/**
 * Ensures that this value lies in the specified [range].
 * 
 * @return this value if it's in the [range], or `range.start` if this value is less than `range.start`, or `range.endInclusive` if this value is greater than `range.endInclusive`.
 * 
 * @sample samples.comparisons.ComparableOps.coerceInUnsigned
 */
@SinceKotlin("1.5")
@WasExperimental(ExperimentalUnsignedTypes::class)
public fun ULong.coerceIn(range: ClosedRange<ULong>): ULong {
    if (range is ClosedFloatingPointRange) {
        return this.coerceIn<ULong>(range)
    }
    if (range.isEmpty()) throw IllegalArgumentException("Cannot coerce value to an empty range: $range.")
    return when {
        this < range.start -> range.start
        this > range.endInclusive -> range.endInclusive
        else -> this
    }
}

