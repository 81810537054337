package ru.arty_bikini.crm_frontend.ui.bootstrap

import csstype.ClassName
import csstype.PropertiesBuilder
import emotion.react.css
import react.ChildrenBuilder
import react.ReactDsl
import react.dom.html.InputType
import react.dom.html.ReactHTML
import react.dom.html.ReactHTML.button
import react.dom.html.ReactHTML.input
import react.dom.html.ReactHTML.span
import ru.arty_bikini.crm_frontend.util.plus
import kotlin.js.Date

@ReactDsl
interface InputBlockDSL {
    val cb: ChildrenBuilder

    fun value(text: String?, color: ClassName? = null, style: PropertiesBuilder.() -> Unit = {})

}

fun ChildrenBuilder.inputGroup(extraClassName: ClassName? = null, init: InputGroupDSL.() -> Unit) {

    ReactHTML.div {
        className = ClassName("input-group input-group-sm") + extraClassName

        init(InputGroupDSL(this))

    }
}

@ReactDsl
class InputGroupDSL(override val cb: ChildrenBuilder) : InputBlockDSL {

    fun name(name: String, classes: ClassName? = null, style: PropertiesBuilder.() -> Unit = {}) {
        with(cb) {

            ReactHTML.span {

                css {
                    style()
                }

                className = className + ClassName("input-group-text") + classes

                +name

                title = name
            }

        }
    }

    override fun value(text: String?, color: ClassName?, style: PropertiesBuilder.() -> Unit) {
        if (text != null){

            cb.apply {
                input {

                    css(style)

                    className = className + ClassName("form-control form-control-sm") + color

                    type = InputType.text
                    disabled = true
                    readOnly = true

                    value = text

                    title = text
                }
            }
        } else {
            warn(BootstrapColor.LIGHT, "Нет", style)
        }
    }

    fun warn(color: BootstrapColor = BootstrapColor.DANGER, text: String = "Ошибка", style: PropertiesBuilder.() -> Unit = {}) {
        with(cb) {

            ReactHTML.span {

                css {
                    style()
                }

                className = className + ClassName("input-group-text")

                badge(color, text)

            }

        }
    }

    fun raw(init: ChildrenBuilder.() -> Unit) {
        init(cb)
    }

    fun btn(color: BootstrapColor, text: String, outline: Boolean = true, action: () -> Unit) {
        with(cb) {
            button {
                if(outline) {
                    className = BootstrapButton.outline(color)
                } else {
                    className = BootstrapButton.painted(color)
                }

                + text

                onClick = {
                    action()
                }
            }
        }
    }
    fun btn(color: BootstrapColor, text: ChildrenBuilder.() -> Unit, outline: Boolean = true, action: () -> Unit) {
        with(cb) {
            button {
                if(outline) {
                    className = BootstrapButton.outline(color)
                } else {
                    className = BootstrapButton.painted(color)
                }

                text()

                onClick = {
                    action()
                }
            }
        }
    }

    fun dropdown(color: BootstrapColor, text: String, menu: ChildrenBuilder.() -> Unit) {
        cb.dropdown(buttonTitle = text, color = color, menu = menu, noWrap = true)
    }
}


fun ChildrenBuilder.inputColumn(extraClasses: ClassName? = null, init: InputColumnDSL.() -> Unit) {

    ReactHTML.div {
        className = ClassName("input-group-sm") + extraClasses

        init(InputColumnDSL(this))

    }
}

@ReactDsl
class InputColumnDSL(override val cb: ChildrenBuilder): InputBlockDSL {

    fun name(name: String, classes: ClassName? = null, style: PropertiesBuilder.() -> Unit = {}) {
        with(cb) {

            ReactHTML.span {

                css {
                    style()
                }

                className = className + ClassName("form-label") + classes

                + name

                title = name
            }

        }
    }

    override fun value(text: String?, color: ClassName?, style: PropertiesBuilder.() -> Unit) {

        with(cb) {
            if (text != null) {

                input {
                    className = ClassName("form-control form-control-sm") + color

                    type = InputType.text
                    readOnly = true

                    value = text

                    title = text
                }


            } else {
                ReactHTML.span {
                    className = ClassName("input-group-text") + color

                    badge(BootstrapColor.LIGHT, "Нет")
                }
            }
        }
    }

    fun raw(init: ChildrenBuilder.() -> Unit) {
        init(cb)
    }
}

fun ChildrenBuilder.inputDateReadonly(date: Date, float: String? = null) {
    if (float != null) {
        ReactHTML.div {
            className = ClassName("form-floating")

            inputDateReadonly(date, null)

            ReactHTML.label {
                +float
            }
        }
    } else {
        input {
            className = ClassName("form-control form-control-sm")

            type = InputType.date
            readOnly = true

            value = date.toISOString().split("T").first()
        }
    }
}
