package ru.arty_bikini.crm_frontend.ui.select2

import csstype.ClassName
import kotlinx.js.jso
import org.w3c.dom.HTMLElement
import org.w3c.dom.HTMLSelectElement
import org.w3c.dom.Node
import react.*
import react.dom.html.ReactHTML.option
import react.dom.html.ReactHTML.select
import ru.arty_bikini.crm_frontend.ui.root.tryFC

external interface Select2Props<T> : Props {
    var options: List<T>
    var getKey: (T) -> String
    var getText: (T) -> String
    var onSelect: (List<T>) -> Unit
}

fun <T> Select2Props<T>.findOption(key: String): T? {

    console.log(options.toTypedArray())

    return options.find { getKey(it) == key }
}

val select2 = tryFC<Select2Props<*>> { props ->

    console.log(props.options.toTypedArray())

    val ref = createRef<HTMLSelectElement>()

    useEffectOnce {
        val current = ref.current ?: return@useEffectOnce

        val jQuery = jQuery(current)

        jQuery.select2 {
            width = "width: '100%'"
            theme = "bootstrap-5"
        }
    }

    useEffect {
        val current = ref.current ?: return@useEffect

        val jQuery = jQuery(current)

        jQuery.on("change") {
            val result = jQuery.select2("data").unsafeCast<Array<Select2Item>>()

            console.log(result)

            result
                .map { props.findOption(it.id) }
                .also { console.log(it.toTypedArray()) }
                .let { props.unsafeCast<Select2Props<Any?>>().onSelect(it) }
        }
    }

    render(props, ref)
}

private fun <T> ChildrenBuilder.render(props: Select2Props<T>, ref: Ref<HTMLSelectElement>) {
    select {

        className = ClassName("form-select")

        this.ref = ref

        placeholder = "Назначить работу"

        props.options.forEach { item ->
            option {
                value = props.getKey(item)

                + props.getText(item)
            }
        }
        option {
            value = "<null>"

            disabled = true
            selected = true


            + ""
        }
    }
}

fun <T> ChildrenBuilder.select2(
    options: List<T>,
    getKey: (T) -> String,
    getText: (T) -> String = { it.toString() },
    onSelect: (List<T>) -> Unit = { },
) {
    select2 {
        val props = this.unsafeCast<Select2Props<T>>()
        props.options = options
        props.getKey = getKey
        props.getText = getText
        props.onSelect = onSelect
    }
}
