package ru.arty_bikini.crm_frontend.ui.bootstrap

import csstype.ClassName
import org.w3c.dom.HTMLDivElement
import react.ChildrenBuilder
import react.Ref
import react.dom.events.MouseEvent
import react.dom.html.HTMLAttributes
import react.dom.html.ReactHTML
import react.dom.html.ReactHTML.button
import react.dom.html.ReactHTML.div
import ru.arty_bikini.crm_frontend.util.plus

fun ChildrenBuilder.dropdown(
    buttonTitle: String,
    extraClasses: ClassName? = null,
    withToggleIcon: Boolean = false,
    color: BootstrapColor = BootstrapColor.SECONDARY,
    noWrap: Boolean = false,
    menu: ChildrenBuilder.() -> Unit
) {
    dropdown(buttonTitle = { +buttonTitle }, extraClasses = extraClasses, color = color, withToggleIcon = withToggleIcon, noWrap = noWrap, menu = menu)
}
fun ChildrenBuilder.dropdown(
    buttonTitle: ChildrenBuilder.() -> Unit,
    buttonAsDiv: Boolean = false,
    extraClasses: ClassName? = null,
    color: BootstrapColor = BootstrapColor.SECONDARY,
    menuExtraClasses: ClassName? = null,
    withToggleIcon: Boolean = false,
    noWrap: Boolean = false,
    ref: Ref<HTMLDivElement>? = null,
    menu: ChildrenBuilder.() -> Unit
) {
    fun ChildrenBuilder.inner(attrs: HTMLAttributes<HTMLDivElement>?) {
        attrs?.className = ClassName("dropdown") + extraClasses

        attrs?.ref = ref
    
        (if (!buttonAsDiv) button else div) {
            
            if (!buttonAsDiv) {
                className = BootstrapButton.outline(color) + if (withToggleIcon) ClassName("dropdown-toggle") else null
            }
            
            buttonTitle(this)
    
            asDynamic()["data-bs-toggle"] = "dropdown"
            asDynamic()["data-bs-auto-close"] = "outside"
    
        }
        ReactHTML.div {
            className = ClassName("dropdown-menu") + menuExtraClasses

            menu()
        }
    }

    if (noWrap) {
        inner(null)
    } else {
        div {
            inner(this)
        }
    }
}

fun ChildrenBuilder.dropdownItem(text: String, onClick: (event: MouseEvent<*, *>) -> Unit) {
    dropdownItem(content = { +text }, onClick = onClick)
}
fun ChildrenBuilder.dropdownItem(content: ChildrenBuilder.() -> Unit, onClick: (event: MouseEvent<*, *>) -> Unit) {

    ReactHTML.a {
        className = ClassName("dropdown-item")

        content()

        this.onClick = onClick
    }
}

fun ChildrenBuilder.dropdownHeader(name: String) {
    ReactHTML.h6 {
        className = ClassName("dropdown-header")

        +name
    }
}

fun ChildrenBuilder.dropdownHeader(content: ChildrenBuilder.() -> Unit) {
    ReactHTML.h6 {
        className = ClassName("dropdown-header")
    
        content()
    }
}

fun ChildrenBuilder.dropdownDivider() {
    ReactHTML.div {
        className = ClassName("dropdown-divider")
    }
}