package ru.arty_bikini.crm_frontend.ui.bootstrap

import csstype.ClassName
import react.ChildrenBuilder
import react.dom.html.ReactHTML.div
import ru.arty_bikini.crm_frontend.util.plus

inline fun ChildrenBuilder.alertDanger(extraClasses: ClassName? = null, crossinline content: ChildrenBuilder.() -> Unit) {
    alert(BootstrapColor.DANGER, extraClasses, content)
}
inline fun ChildrenBuilder.alertInfo(extraClasses: ClassName? = null, crossinline content: ChildrenBuilder.() -> Unit) {
    alert(BootstrapColor.INFO, extraClasses, content)

}

inline fun ChildrenBuilder.alert(color: BootstrapColor, extraClasses: ClassName? = null, crossinline content: ChildrenBuilder.() -> Unit) {

    div {
        className = Bootstrap.alert(color) + extraClasses

        content(this)
    }

}
