package ru.arty_bikini.crm_frontend.table.report

import react.useState
import ru.arty_bikini.crm.dto.dict.TrainerDTO
import ru.arty_bikini.crm.dto.enums.UserGroup
import ru.arty_bikini.crm.dto.enums.personalData.ClientType
import ru.arty_bikini.crm.dto.filter.OrderFilter
import ru.arty_bikini.crm.dto.orders.OrderDTO
import ru.arty_bikini.crm.dto.orders.PersonalDataDTO
import ru.arty_bikini.crm_frontend.form.main.ArchiveMainForm
import ru.arty_bikini.crm_frontend.table.common.commonPage
import ru.arty_bikini.crm_frontend.ui.bootstrap.BootstrapColor
import ru.arty_bikini.crm_frontend.ui.bootstrap.alert
import ru.arty_bikini.crm_frontend.ui.bootstrap.cardLightShadow
import ru.arty_bikini.crm_frontend.ui.bootstrap.inputGroup
import ru.arty_bikini.crm_frontend.ui.input.form.FormInput
import ru.arty_bikini.crm_frontend.ui.input.table.*
import ru.arty_bikini.crm_frontend.ui.input.table.data.DataSourceLoadingState
import ru.arty_bikini.crm_frontend.ui.input.table.data.month.MonthPagerDataProviderOrder
import ru.arty_bikini.crm_frontend.ui.input.types.or
import ru.arty_bikini.crm_frontend.util.personalData
import ru.arty_bikini.crm_frontend.util.useCache
import kotlin.math.min

val TrainerPaymentTablePage = commonPage(
    requireGroups = listOf(UserGroup.ADMIN, UserGroup.TANYA)
) { props ->

    val trainers = useCache(props.client.cache.trainers)

    var current by useState<TrainerDTO>()

    cardLightShadow {
        FormInput(props.client, null) {
            inputGroup {
                name("Тренер")
                addSelect(FieldFlag.Nullable, trainers, { it.id.toString() }, { it.name }, { current }, { current = it })
            }
        }
    }

    if (current == null) {
        alert(BootstrapColor.DANGER) { + "Выберите тренера!" }
        return@commonPage
    }


    MonthPagerDataProviderOrder(
        client = props.client,
        defaultFilter = { OrderFilter().apply  { trainer = current }}
    ) { data ->

        cardLightShadow {
            if (data.state != DataSourceLoadingState.LOADED) {
                alert(BootstrapColor.WARNING) { +"Идет загрузка данных" }
            }

            if (data.state == DataSourceLoadingState.LOADED) {

                ConversionReport {
                    this.client = props.client
                    this.data = data.currentData
                    this.defaultVisible = false
                }

                MoneyReport {
                    this.client = props.client
                    this.data = data.currentData
                    this.defaultVisible = false
                }

            }

        }

        cardLightShadow {

            TablePanelOrderSource(props.client, data, "t-r-trainer") {
                addText(FieldFlag.ReadOnlyProp, "ФИО", OrderDTO::name, TableColumnRenderOptions(editButtonForm = ArchiveMainForm))
                addBoolean(FieldFlag.ReadOnlyProp, "Архив", OrderDTO::archive)
                addSelect(FieldFlag.ReadOnlyProp, "Тип", ClientType.values(), OrderDTO::type)
                addDateTime(FieldFlag.ReadOnlyProp, "Дата появления лида", OrderDTO::personalData or ::PersonalDataDTO then PersonalDataDTO::createdTime, TableColumnRenderOptions(defaultSortLegacy = true))
                addText(FieldFlag.ReadOnlyProp, "Предоплата", OrderDTO::personalData or ::PersonalDataDTO then PersonalDataDTO::prepayment)
                addInt(FieldFlag.ReadOnlyProp, "Сумма заказа", OrderDTO::personalData or ::PersonalDataDTO then PersonalDataDTO::payment)
                addInt(FieldFlag.ReadOnlyValue, "Скидка клиенту (вверх)") { trainerDiscount }
                addInt(FieldFlag.ReadOnlyValue, "Вознаграждение тренеру (вниз)") { trainerPay }
                addBoolean(FieldFlag.NotNull, "Выплачено", OrderDTO::personalData or ::PersonalDataDTO then PersonalDataDTO::payTrainer)

            }
        }
    }
}

fun min(total: Int, max: Int?, percent: Int?): Int {

    val max = max?.coerceIn(0, total) ?: 0
    val percent = percent?.coerceIn(0, 100) ?: 0

    if (max < 1 && percent < 1) return 0
    if (max < 1) return total * (percent) / 100
    if (percent < 1) return max
    return min(max, total * (percent) / 100)
}
