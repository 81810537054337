package ru.arty_bikini.crm_frontend.form.calc

import csstype.ClassName
import react.Props
import react.dom.html.InputType
import react.dom.html.ReactHTML
import react.useEffect
import react.useState
import ru.arty_bikini.crm.dto.dict.RhinestoneTypeDTO
import ru.arty_bikini.crm.dto.enums.CalcPresetRuleType
import ru.arty_bikini.crm.dto.orders.stone.CalcPresetRuleDTO
import ru.arty_bikini.crm_frontend.ui.bootstrap.*
import ru.arty_bikini.crm_frontend.ui.root.tryFC

external interface CalculatorInputProps : Props {
    var type: RhinestoneTypeDTO
    var version: Int
    var currentRule: CalcPresetRuleDTO
    var storedRule: CalcPresetRuleDTO?
    var setRule: (CalcPresetRuleDTO) -> Unit
}

val CalculatorInput = tryFC<CalculatorInputProps> { props ->

    var currentRuleType by useState(CalcPresetRuleType.COST_PERCENT)

    useEffect(props.currentRule.type) {
        currentRuleType = props.currentRule.type ?: currentRuleType
    }

    inputGroup {

        val auto = props.currentRule.auto
        btn(if (auto) BootstrapColor.DANGER else BootstrapColor.SECONDARY, "Остаток", outline = !auto) {
            val newValue = !props.currentRule.auto
            props.setRule(props.currentRule.copy(auto = newValue))
        }
        with(cb) {
            ReactHTML.input {
                className = ClassName("form-control")

                value = props.currentRule.value.toString()

                type = InputType.number

                onChange = {

                    var newValue = it.target.value.toIntOrNull() ?: 0

                    newValue = when (currentRuleType) {
                        CalcPresetRuleType.COST_PERCENT -> newValue.coerceIn(0, 100)
                        else -> newValue.coerceAtLeast(0)
                    }

                    props.setRule(props.currentRule.copy(type = currentRuleType, value = newValue))

                }
            }
        }

        name(currentRuleType.displayName)

        cb.dropdown("", withToggleIcon = true) {
            CalcPresetRuleType.values().forEach { rule ->
                dropdownItem(rule.displayName) {
                    currentRuleType = rule

                    props.setRule(props.currentRule.copy(type = rule))
                }
            }
        }
    }


    if (props.storedRule != null) {
        badge(BootstrapColor.SECONDARY, "Было рассчитано: ${props.storedRule?.value} ${props.storedRule?.type?.displayName}")

        if (props.storedRule?.auto == true) {
            badge(BootstrapColor.SECONDARY, "+ Остаток")
        }
    }

}

