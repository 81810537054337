package ru.arty_bikini.crm_frontend.ui.root

import ru.arty_bikini.crm_frontend.table.ArchiveTablePage
import ru.arty_bikini.crm_frontend.table.GoogleTablePage
import ru.arty_bikini.crm_frontend.table.LeadTablePage
import ru.arty_bikini.crm_frontend.table.admin.*
import ru.arty_bikini.crm_frontend.table.report.ConversionTablePage
import ru.arty_bikini.crm_frontend.table.report.MoneyTablePage
import ru.arty_bikini.crm_frontend.table.report.TrainerPaymentTablePage
import ru.arty_bikini.crm_frontend.table.config.*
import ru.arty_bikini.crm_frontend.table.production.ProductionTablePage
import ru.arty_bikini.crm_frontend.table.production.worker.OwnWorksTablePage
import ru.arty_bikini.crm_frontend.table.production.worker.WorkerProductionTablePage
import ru.arty_bikini.crm_frontend.table.report.WorkerPaymentTablePage

object RouteList {
    val all = buildRoutes {
        single("/table/lead", "Лиды", LeadTablePage)
        group("Производство") {
            route("/table/production/manager", "Производство (Манагер)", ProductionTablePage)
            route("/table/production/worker", "Производство (Сотрудник)", WorkerProductionTablePage)
        }
        single("/table/import-google", "Мерки из Формы", GoogleTablePage)
        single("/table/own-works", "Мои работы", OwnWorksTablePage)
        group("Отчеты") {
            route("/table/report/archive", "Архив", ArchiveTablePage)
            route("/table/report/conversion", "Конверсия", ConversionTablePage)
            route("/table/report/trainer", "Вознаграждения тренеру", TrainerPaymentTablePage)
            route("/table/report/money", "Расходы/Доходы", MoneyTablePage)
            route("/table/report/worker", "Вознаграждение сотруднику", WorkerPaymentTablePage)
            divider("Техническое")
            route("/table/report/history", "История изменений", HistoryListPage)

        }
        group("Настройки") {
            route("/dict/trainer", "Тренера", TrainerTablePage)
            route("/admin/user", "Сотрудники", UserListPage)
            divider("Заказ")
            route("/dict/product-type", "Товары", ProductTypeTablePage)
            route("/dict/additional-item-type", "Допы", AdditionalItemsTablePage)
            route("/dict/rhinestone-type", "Стразы", RhinestoneTypesTablePage)
            route("/dict/stone-color", "Стразы (Цвета)", StoneColorTablePage)
            route("/dict/express-type", "Срочность", ExpressTablePage)
            route("/dict/straps-type", "Лямки", StrapsTablePage)
            divider("Прочее")
            route("/dict/calc-preset", "Шаблоны калькулятора", CalcPresetTablePage)
            route("/dict/order-data-type", "Настройки мерок и импорта", OrderDataTypeTablePage)
            route("/dict/work-type", "Виды работ", WorkTypeTablePage)
            divider("Техническое")
            route("/admin/file", "Файлы", FileListPage)
            route("/settings/session", "Активные сессии", SessionListPage)
        }
        group("Профиль") {
            route("/settings/client", "Настройки клиента", ClientConfigPage)
        }
    }
}
