package ru.arty_bikini.crm.dto.packet.core

import kotlinx.serialization.Serializable
import ru.arty_bikini.crm.dto.packet.BaseResponse

@Serializable
class PullRequest {

}

@Serializable
class PullResponse(
    override val ok: Boolean,
    override val statusCode: String,
    override val displayMessage: String?,
    val needReload: Boolean,
) : BaseResponse()
