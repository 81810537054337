package ru.arty_bikini.crm_frontend.table.production

import csstype.ClassName
import csstype.Color
import csstype.px
import emotion.react.css
import kotlinx.browser.window
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import react.dom.html.InputType
import react.dom.html.ReactHTML.br
import react.dom.html.ReactHTML.button
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.input
import react.dom.html.ReactHTML.label
import react.dom.html.ReactHTML.table
import react.dom.html.ReactHTML.tbody
import react.dom.html.ReactHTML.td
import react.dom.html.ReactHTML.th
import react.dom.html.ReactHTML.thead
import react.dom.html.ReactHTML.tr
import react.useEffect
import react.useEffectOnce
import react.useState
import ru.arty_bikini.crm.dto.UserDTO
import ru.arty_bikini.crm.dto.enums.UserGroup
import ru.arty_bikini.crm.dto.orders.OrderDTO
import ru.arty_bikini.crm.dto.work.IntervalDTO
import ru.arty_bikini.crm.dto.work.WorkDTO
import ru.arty_bikini.crm_frontend.ClientProps
import ru.arty_bikini.crm_frontend.ui.bootstrap.*
import ru.arty_bikini.crm_frontend.ui.input.panel.InputPanel
import ru.arty_bikini.crm_frontend.ui.root.tryFC
import ru.arty_bikini.crm_frontend.util.*
import kotlin.js.Date

val defaultIntervalSkip: IntervalKt = IntervalKt(7, DateUnitKt.DAY)

val ProductionTable = tryFC<ClientProps> { props ->

    var intervals = useState<List<IntervalDTO>>(emptyList())
    var works = useState<List<WorkDTO>>(emptyList())

    var gluers: List<UserDTO> by useState(emptyList())
    var seamstress: List<UserDTO> by useState(emptyList())

    var orders: List<OrderDTO> by useState(emptyList())

    var dateFrom by usePersistent<DateKt?>(null, track = false)
    var dateTo by usePersistent<DateKt?>(null, track = false)

    var hiddenIncompleteWorks by useState(0L)

    suspend fun loadWorksGroup() {

        props.client.network
            .work
            .getIntervalWork(dateFrom, dateTo)
            ?.let { packet ->

                if (dateFrom == null) {
                    dateFrom = DateUtils.fromNullable(packet.dateStart)
                }

                if (dateTo == null) {
                    dateTo = DateUtils.fromNullable(packet.dateEnd)
                }

                hiddenIncompleteWorks = packet.previousIncompleteWorksCount

                intervals.set(packet.intervalDTOList ?: emptyList())
                works.set(packet.workDTOList ?: emptyList())

//                intervals.update {
//
//                    val newIntervals = it.toMutableList()
//
//                    newIntervals.removeAll { e -> packet.intervalDTOList?.any { n -> e.id == n.id } == true }
//
//                    newIntervals.addAll(packet.intervalDTOList ?: emptyList())
//
//                    newIntervals
//                        .sortBy { it.dateStart }
//
//
////                    console.log(arrayOf(StringUtils.printLocalDate(date), it, packet.intervalDTOList, newIntervals))
//
//                    newIntervals
//                }
//
//                works.update {
//
//                    val newWorks = it.toMutableList()
//
//                    newWorks.removeAll { e -> packet.intervalDTOList?.any { n -> e.interval?.id == n.id } == true }
//
//                    newWorks.addAll(packet.workDTOList ?: emptyList())
//
//                    newWorks.sortBy { it.id }
//
//
//                    newWorks
//                }

            }

    }

    suspend fun reloadOrders() {

        props.client
            .network
            .order
            .getClients()
            ?.orders
            ?.sortedBy { it.name }
            ?.let { orders = it }

    }

    fun reloadAll() {

        GlobalScope.launch {
            launch {
                loadWorksGroup()
            }

            launch {
                props.client
                    .network
                    .auth
                    .getUsers()
                    ?.users
                    ?.filter { it.visible }
                    ?.sortedBy { it.name }
                    ?.sortedBy { it.priority }
                    ?.let {
                        gluers = it.filter { it.group == UserGroup.GLUER }
                        seamstress = it.filter { it.group == UserGroup.SEAMSTRESS }
                    }
            }

            launch {
                reloadOrders()
            }
        }

    }
    useEffectOnce { reloadAll() }
    useEffectOnce {
        var visible = true
        GlobalScope.launch {
            while (visible) {

                delay(1_000)

                reloadOrders()

//                console.log(arrayOf(StringUtils.printLocalDate(dateFrom), StringUtils.printLocalDate(dateTo)))

                loadWorksGroup()

            }
        }

        cleanup {
            visible = false
        }
    }

    table {
        className = Bootstrap.tableDefault

        thead {
            css {
                backgroundColor = Color("white")

                height = 60.px
            }
            className = className + ClassName("sticky-top")

            tr {
                for (user in seamstress + gluers) {
                    th {
                        css {
                            this.minWidth = 150.px
                        }

                        + user.name.orEmpty()
                        br{}
                        badge(BootstrapColor.LIGHT, if (user.group == UserGroup.GLUER) "Расклейщица" else "Швея")
                    }
                }
            }
        }

        tbody {
            if (hiddenIncompleteWorks > 0) {

                tr {
                    td {

                        colSpan = seamstress.size + gluers.size + 1
                        div {
                            className = ClassName("vstack")
                            badge(BootstrapColor.WARNING, "Незавершенные работы в предыдущем интервале: $hiddenIncompleteWorks")
                        }
                    }
                }
            }
            tr {
                td {

                    colSpan = seamstress.size + gluers.size + 1
                    div {
                        className = ClassName("vstack")

                        button {
                            className = BootstrapButton.btn

                            val keep = StringUtils.printLocalDate(dateTo)

                            val dateFromOld = dateFrom ?: DateUtils.loaded
                            val datesOld = StringUtils.printLocalDate(dateFromOld) + " - " + keep
                            val datesNew = StringUtils.printLocalDate(dateFromOld - defaultIntervalSkip) + " - " + keep

                            +"Посмотреть предыдущие: ($datesOld) => ($datesNew)"

                            onClick = {

                                val newDate = dateFromOld - defaultIntervalSkip
                                dateFrom = newDate

                            }
                        }
                    }
                }
            }
            for (interval in intervals.get()) {
                tr {
                    td {
                        colSpan = seamstress.size + gluers.size + 1

                        badge(
                            color = BootstrapColor.SECONDARY,
                            text = StringUtils.printLocalDateWeek(interval.dateStart),
                            extraClass = ClassName("w-100")
                        )
                    }
                }
                val intervalWorks = works.get()
                    .filter { it.interval?.id == interval.id }
                    .sortedBy { it.priority }

                val byUser = intervalWorks.groupBy { it.user?.id }

                val size = maxOf(4, (byUser.maxOfOrNull { it.value.size } ?: 0) + 1)

                for (i in 0 until size) {
                    tr {

                        for (user in seamstress + gluers) {
                            td {
                                className = ClassName("p-0")

                                if (user == props.client.auth.currentUser) {
                                    className += ClassName("bg-info bg-opacity-25")
                                }

                                val byCurrentUser = byUser.get(user.id)
                                val curr = byCurrentUser?.getOrNull(i)

                                if (curr != null) {
                                    WorkCell {
                                        this.client = props.client
                                        this.work = curr
                                        this.reloadAll = ::reloadAll
                                        this.changePriority = { dx: Int ->
                                            val tmp = byCurrentUser.toMutableList()
                                            val idx = tmp.indexOfFirst { it.id == curr.id }

                                            tmp.removeAll { it.id == curr.id }

                                            val newIdx = (idx + dx).coerceIn(0, tmp.size)
                                            tmp.add(newIdx, curr)

                                            GlobalScope.launch {
                                                tmp.forEachIndexed { index, workDTO ->
                                                    val priority = index + 1
                                                    if (workDTO.priority != priority) {
                                                        val copy = workDTO.clone(WorkDTO.serializer())

                                                        copy.priority = priority

                                                        props.client
                                                            .network
                                                            .work
                                                            .editWork(copy)
                                                    }
                                                }
                                            }
                                        }
                                    }
                                } else {
                                    div {
                                        className = ClassName("hstack gap-1")

                                        if (props.client.auth.currentUser?.group?.canEditWork == true) {
                                            AddWorkButton {
                                                this.client = props.client

                                                this.orders = orders
                                                this.user = user
                                                this.interval = interval
                                                this.nextWorkId = byUser.size + 1

                                                this.reloadAll = ::reloadAll
                                            }
                                        }

                                        if (i + 1 == size) {
                                            dropdown(buttonTitle = {
                                                +"?"
                                            }) {

                                                val oldWorks = works.get()
                                                    .asSequence()
                                                    .filter { !it.approved }
                                                    .filter { it.user == user }
                                                    .filter {
                                                        (it.interval?.dateFinish ?: 0L) <= (interval.dateFinish ?: 0L)
                                                    }
                                                    .toList()
                                                val currWorks = ((byCurrentUser ?: emptyList()) + oldWorks).distinct()

                                                val nextInterval = intervals
                                                    .get()
                                                    .filter { (it.dateFinish ?: 0L) > (interval.dateFinish ?: 0L) }
                                                    .minByOrNull { it.dateFinish ?: 0L }

                                                val newWorks = works.get()
                                                    .asSequence()
                                                    .filter { it.user == user }
                                                    .filter { it.interval == nextInterval }
                                                    .toList()

                                                val content = listOf(
                                                    listOf("Принести:"),
                                                    currWorks.map { item ->
                                                        "${item.order?.name} - ${item.works?.joinToString { it.name ?: "???" }}"
                                                    },
                                                    listOf(
                                                        "",
                                                        "",
                                                        "Забрать:",
                                                    ),
                                                    newWorks.map { item ->
                                                        "${item.order?.name} - ${item.works?.joinToString { it.name ?: "???" }}"
                                                    },
//                                                    listOf(
//                                                        "",
//                                                        "",
//                                                        "Проверить:"
//                                                    ),
                                                )
                                                    .flatMap { it }


                                                dropdownHeader {
                                                    div {
                                                        className = ClassName("hstack gap-1 justify-content-between")

                                                        div {
                                                            + "На встречу"
                                                        }
                                                        button {
                                                            className = BootstrapButton.outline(BootstrapColor.PRIMARY)

                                                            + "\uD83D\uDCCB"

                                                            onClick = {
                                                                val str = content.joinToString("\n")

                                                                window.navigator.clipboard.writeText(str)

                                                            }
                                                        }
                                                    }
                                                }

                                                content.forEach { item ->
                                                    dropdownItem(item) {}
                                                }
                                            }
                                        }
                                    }
                                }

                            }
                        }
                    }
                }
            }

            tr {
                td {
                    colSpan = (seamstress.size + gluers.size - 1).coerceAtLeast(1)

                    badge(
                        color = BootstrapColor.SECONDARY,
                        text = StringUtils.printLocalDateWeek(intervals.get().maxByOrNull { it.dateFinish ?: 0 }?.dateFinish),
                        extraClass = ClassName("w-100")
                    )
                }
                td {
                    badge(
                        color = BootstrapColor.SECONDARY,
                        text = "Перенести",
                        extraClass = ClassName("w-100")
                    )
                }
            }

            tr {
                td {

                    colSpan = seamstress.size + gluers.size + 1
                    div {
                        className = ClassName("vstack")

                        button {
                            className = BootstrapButton.btn

                            val keep = StringUtils.printLocalDate(dateFrom)
                            val dateToOld = dateTo ?: DateUtils.loaded

                            val datesOld = keep + "-" + StringUtils.printLocalDate(dateToOld)
                            val datesNew = keep + "-" + StringUtils.printLocalDate(dateToOld + defaultIntervalSkip)

                            +"Посмотреть новые: ($datesOld) => ($datesNew)"

                            onClick = {

                                val newDate = dateToOld + defaultIntervalSkip
                                dateTo = newDate

                            }
                        }
                    }
                }
            }
            tr {
                td {
                    colSpan = (seamstress + gluers).size.coerceAtLeast(1)

                    div {
                        dropdown("Добавить встречу", extraClasses = ClassName("w-100")) {
                            val endDate = intervals.get().asSequence()
                                .map { it.dateFinish }
                                .filterNotNull()
                                .maxOrNull()
                                .let { it ?: Date.now().toLong() }
                                .let { it + 7 * 24 * 60 * 60 * 1000L }
                                .let { Date(it) }

                            InputPanel {
                                val date = addDate("Дата встречи", endDate)

                                onSave {
                                    val newDate = get(date) ?: return@onSave

//                                    console.log(newDate)

                                    GlobalScope.launch {

                                        props.client
                                            .network
                                            .work
                                            .addInterval(newDate)

                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
