package ru.arty_bikini.crm_frontend.ui.input.form

import csstype.ClassName
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import react.ChildrenBuilder
import react.dom.aria.ariaValueText
import react.dom.html.ReactHTML
import react.dom.html.ReactHTML.option
import ru.arty_bikini.crm.dto.enums.HasDisplayValue
import ru.arty_bikini.crm_frontend.network.Entity
import ru.arty_bikini.crm_frontend.ui.bootstrap.BootstrapButton
import ru.arty_bikini.crm_frontend.ui.bootstrap.BootstrapColor
import ru.arty_bikini.crm_frontend.ui.bootstrap.alertInfo
import ru.arty_bikini.crm_frontend.ui.input.table.FieldFlag
import ru.arty_bikini.crm_frontend.ui.input.types.ValueConverter
import kotlin.reflect.KMutableProperty0

abstract class FormInputBuilder<T, VB>(val cb: ChildrenBuilder, val entity: T, readOnly: Boolean, val onSave: suspend (T) -> Unit) {

    private var singletonMarker = false

    protected var readOnlyActual: Boolean = readOnly
        private set

    protected fun <V : VB> build(prop: T.() -> KMutableProperty0<V>) {

        if (singletonMarker) {
            with(cb) {
                alertInfo { + "FormInputBuilder:build called more than once" }
            }
            return
        }
        singletonMarker = true

        with(cb) {

            FormInputCell {
                data = FormInputCellData(this@FormInputBuilder, { prop().get() }, { prop().set(it) })
            }

        }
    }

    protected fun <V : VB> build(get: T.() -> V, set: T.(V) -> Unit) {

        if (singletonMarker) {
            with(cb) {
                alertInfo { + "FormInputBuilder:build called more than once" }
            }
            return
        }
        singletonMarker = true

        with(cb) {

            FormInputCell {
                data = FormInputCellData(this@FormInputBuilder, get, set)
            }

        }
    }

    protected fun <V : VB> buildReadOnly(prop: T.() -> V) {

        if (singletonMarker) {
            with(cb) {
                alertInfo { + "FormInputBuilder:build called more than once" }
            }
            return
        }
        singletonMarker = true

        readOnlyActual = true

        with(cb) {

            FormInputCell {
                data = FormInputCellData(this@FormInputBuilder, { prop() }, { /* no-op */ })
            }

        }
    }

    fun <V : VB> renderInternal(
        cb: ChildrenBuilder,
        value: V,
        update: (V) -> Unit,
        save: (V) -> Unit,
    ) = cb.render(value, update) {
        save(it)

        GlobalScope.launch {
            onSave(entity)
        }
    }

    abstract fun <V : VB> ChildrenBuilder.render(
        value: V,
        update: (V) -> Unit,
        save: (V) -> Unit
    )
}

class FormInputBuilderText<T, V : Any>(cb: ChildrenBuilder, entity: T, readOnly: Boolean, onSave: suspend (T) -> Unit, val converter: ValueConverter<*, V>)
    : FormInputBuilder<T, V?>(cb, entity, readOnly, onSave) {

    var allowNull: Boolean = false
    var allowDelete: Boolean = false

    @Deprecated("specify flag explicitly")
    operator fun invoke(prop: T.() -> KMutableProperty0<V?>) {
        allowNull = true
        build(prop)
    }

    operator fun invoke(flag: FieldFlag.Nullable, allowDelete: Boolean = false, value: T.() -> KMutableProperty0<V?>) {
        allowNull = true
        this.allowDelete = allowDelete
        build(value)
    }

    operator fun invoke(flag: FieldFlag.Nullable, get: T.() -> V?, set: T.(V?) -> Unit, allowDelete: Boolean = false) {
        allowNull = true
        this.allowDelete = allowDelete
        build(get, set)
    }

    operator fun invoke(flag: FieldFlag.NotNull, value: T.() -> KMutableProperty0<V>) {
        allowNull = false
        build(value)
    }

    operator fun invoke(flag: FieldFlag.NotNull, get: T.() -> V, set: T.(V) -> Unit) {
        allowNull = false
        build(get, set)
    }

    operator fun invoke(flag: FieldFlag.ReadOnlyProp, value: T.() -> V?) {
        allowNull = false
        buildReadOnly(value)
    }


    override fun <VN : V?> ChildrenBuilder.render(value: VN, update: (VN) -> Unit, save: (VN) -> Unit) {

        ReactHTML.input {
            this.className = ClassName("form-control form-control-sm")

            this.value = value?.let { converter.fromProtocolToKey(value) } ?: ""

            this.type = converter.inputType

            if (readOnlyActual) {
                this.readOnly = true
                this.disabled = true
            }

            onChange = event@{
                val new = it.currentTarget.value

                val newProtocol = converter.fromKeyToProtocol(new)

                if (!allowNull && newProtocol == null) {
                    console.log("Can't save null")
                    return@event
                }

                update(newProtocol as VN)
            }

            onBlur = {
                save(value)
            }
        }
        if (allowNull && allowDelete) {
            ReactHTML.button {
                className = BootstrapButton.outline(BootstrapColor.DANGER)

                +  "X"

                onClick = {
                    save(null.unsafeCast<VN>())
                }
            }
        }

    }
}

class FormInputBuilderTextArea<T>(cb: ChildrenBuilder, entity: T, readOnly: Boolean, onSave: suspend (T) -> Unit)
    : FormInputBuilder<T, String?>(cb, entity, readOnly, onSave) {

    private var size = 0

    operator fun invoke(size: Int, prop: T.() -> KMutableProperty0<String?>) {
        this.size = size
        build(prop)
    }

    override fun <V : String?> ChildrenBuilder.render(value: V, update: (V) -> Unit, save: (V) -> Unit) {
        ReactHTML.textarea {
            this.className = ClassName("form-control form-control-sm")

            this.value = value ?: ""

            this.rows = size

            if (readOnlyActual) {
                this.readOnly = true
                this.disabled = true
            }

            onChange = {
                val new = it.currentTarget.value

                update(new as V)
            }

            onBlur = {
                save(value)
            }
        }

    }
}

class FormInputBuilderSelect<T>(cb: ChildrenBuilder, entity: T, readOnly: Boolean, onSave: suspend (T) -> Unit)
    : FormInputBuilder<T, Any?>(cb, entity, readOnly, onSave) {

    private var variants: List<PackedVariant<*>> = emptyList()

    var allowNull: Boolean = false

    operator fun <V> invoke(
        flag: FieldFlag.Nullable,
        variants: Array<V>,
        prop: T.() -> KMutableProperty0<V?>
    ) where V : Enum<V>, V : HasDisplayValue {
        this.variants = variants
            .map { PackedVariant(value = it, getText = { it.displayName }, getKey = { it.name }) }
        allowNull = true

        build(prop)
    }

    operator fun <V> invoke(
        flag: FieldFlag.NotNull,
        variants: Array<V>,
        prop: T.() -> KMutableProperty0<V>
    ) where V : Enum<V>, V : HasDisplayValue {
        this.variants = variants
            .map { PackedVariant(value = it, getText = { it.displayName }, getKey = { it.name }) }
        build(prop)
    }

    operator fun <V> invoke(
        flag: FieldFlag.Nullable,
        variants: List<V>,
        toKey: (V) -> String,
        toText: (V) -> String?,
        prop: T.() -> KMutableProperty0<V?>
    ) {
        this.variants = variants
            .map { PackedVariant(value = it, getText = toText, getKey = toKey) }

        allowNull = true

        build(prop)
    }

    operator fun <V : Any> invoke(
        flag: FieldFlag.NotNull,
        variants: List<V>,
        toKey: (V) -> String,
        toText: (V) -> String?,
        prop: T.() -> KMutableProperty0<V>
    ) {
        this.variants = variants
            .map { PackedVariant(value = it, getText = toText, getKey = toKey) }

        build(prop)
    }

    operator fun <V> invoke(
        flag: FieldFlag.Nullable,
        variants: List<V>,
        toKey: (V) -> String,
        toText: (V) -> String?,
        get: T.() -> V?,
        set: T.(V?) -> Unit,
    ) {
        this.variants = variants
            .map { PackedVariant(value = it, getText = toText, getKey = toKey) }

        allowNull = true
        build(get, set)
    }

    operator fun <V : Entity> invoke(
        flag: FieldFlag.Nullable,
        variants: List<V>,
        toText: (V) -> String?,
        prop: T.() -> KMutableProperty0<V?>
    ) {
        this.variants = variants
            .map { PackedVariant(value = it, getText = toText, getKey = { it.id.toString() }) }

        allowNull = true
        build(prop)
    }

    override fun <V : Any?> ChildrenBuilder.render(
        value: V,
        update: (V) -> Unit,
        save: (V) -> Unit
    ) {
        val packed = PackedVariant(
            value = value,
            getText = { variants.firstOrNull()?.unsafeCast<PackedVariant<V>>()?.getText?.invoke(it) ?: "" },
            getKey = { variants.firstOrNull()?.unsafeCast<PackedVariant<V>>()?.getKey?.invoke(it) ?: "<error>" },
        )

        ReactHTML.select {
            this.className = ClassName("form-select form-select-sm pe-4")

            if (readOnlyActual) {
                this.disabled = true
            }

            option {
                className = ClassName("text-muted")

                this.value = "<null>"

                + "<Нет>"

                if (null == value) {

                    selected = true

                    if (!allowNull) {
                        disabled = true
                    }

                }
            }

            if (value != null && variants.none { it.toKey() == packed.toKey() }) {
                option {
                    className = ClassName("text-muted")

                    this.value = "<error>"

                    this.disabled = true

                    + (packed.toText() + " (Устаревшее)")

                    selected = true

                }
            }

            variants.forEach {

                ReactHTML.option {
                    this.value = it.toKey()

                    + it.toText().orEmpty()

                    if (it.toKey() == packed.toKey()) {

                        selected = true

                    }
                }
            }

            this.onChange = {
                val new = it.currentTarget.value

                if (allowNull && value == "<null>") {

                    save(null.unsafeCast<V>())

                } else {

                    val newEnum = variants.firstOrNull() { it.toKey() == new }

                    console.log(newEnum)

                    if (newEnum != null) {
                        save(newEnum.value.unsafeCast<V>())
                    }
                }
            }
        }

    }

}

class PackedVariant<T>(val value: T, val getText: (T & Any) -> String?, val getKey: (T & Any) -> String) {
    fun toText(): String? = value?.let { getText(value) }
    fun toKey(): String =  value?.let { getKey(value) } ?: "null"
}

