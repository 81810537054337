package ru.arty_bikini.crm_frontend.ui.input.types

import ru.arty_bikini.crm_frontend.SimpleCache
import ru.arty_bikini.crm_frontend.network.Entity

interface ValueProvider<T> {
    val cache: SimpleCache<*>?

    fun getAll(): List<T>
}

class EnumValueProvider<T : Enum<T>>(val all: List<T>) : ValueProvider<T> {

    override val cache: SimpleCache<*>? = null

    override fun getAll(): List<T> = all

}

class EntityValueProvider<T : Entity>(val all: SimpleCache<List<T>>) : ValueProvider<T> {

    override val cache: SimpleCache<*>? = all

    override fun getAll(): List<T> = all.get()

}