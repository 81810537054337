package ru.arty_bikini.crm_frontend.form.button

import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import ru.arty_bikini.crm.dto.enums.personalData.ClientType
import ru.arty_bikini.crm.dto.orders.OrderDTO
import ru.arty_bikini.crm_frontend.ui.input.panel.InputPanel
import ru.arty_bikini.crm_frontend.ui.modal.createModal
import ru.arty_bikini.crm_frontend.util.personalData

val ButtonPrePaymentForm = createModal<OrderDTO>(
    title = {"Подтвердите предоплату для $name"},
    autoClose = false
) { props ->

    InputPanel {

        val prePayment = addText("Сумма предоплаты")

        onSave("Подтвердить оплату") {
            val value = get(prePayment)

            if (value == null) {
                return@onSave
            }

            val entity = props.entity

            entity.personalData().prepayment = value
            entity.type = ClientType.CLIENT

            GlobalScope.launch {

                props.client
                    .network
                    .order
                    .saveOrder(entity)

                props.client
                    .event
                    .closeModal()
            }
        }

        onCancel("Отмена") {
            props.client
                .event
                .closeModal()
        }
    }


}
