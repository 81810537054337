package ru.arty_bikini.crm_frontend.ui.bootstrap

import csstype.ClassName
import kotlinx.browser.document
import org.w3c.dom.HTMLDivElement
import react.*
import react.dom.html.ReactHTML
import ru.arty_bikini.crm_frontend.ui.root.tryFC
import ru.arty_bikini.crm_frontend.util.usePersistent

external interface BSModalProps : Props {
    var key: String
    var show: Boolean
    var onClose: (() -> Unit)?
    var body: (ChildrenBuilder) -> Unit
}

private val BSModal = tryFC<BSModalProps> { props ->

    val modalRef = createRef<HTMLDivElement>()

    val handler by usePersistent(props.onClose ?: {})
    var visible by useState(false)

    useEffectOnce {

        try {
            val element = modalRef.current ?: return@useEffectOnce

            element.addEventListener("hidePrevented.bs.modal", { handler() })
            element.addEventListener("shown.bs.modal", { visible = true })
            element.addEventListener("hidden.bs.modal", { visible = false })
        } catch (e: Throwable) {
            e.printStackTrace()
        }

    }

    useEffect(props.show, visible) {

        val element = modalRef.current ?: return@useEffect

        val myModal = Modal.getOrCreateInstance(element)

        if (props.show && !visible) {
            console.log("show modal ${props.key}")
            myModal.show()
        } else if (!props.show && visible) {
            console.log("hide modal ${props.key}")
            myModal.hide()
        }

    }

    ReactHTML.div {
        className = ClassName("modal modal-lg fade")

        ref = modalRef

        asDynamic()["data-bs-backdrop"] = "static"
        asDynamic()["data-bs-keyboard"] = "false"

        ReactHTML.div {
            className = ClassName("modal-dialog")

            ReactHTML.div {
                className = ClassName("modal-content")

                props.body(this)
            }
        }
    }
}

fun ChildrenBuilder.modal(key: String, show: Boolean, onClose: (() -> Unit)?, body: ChildrenBuilder.() -> Unit) {
    BSModal {
        this.key = key
        this.show = show
        this.onClose = onClose
        this.body = body
    }
}