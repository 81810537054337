package ru.arty_bikini.crm_frontend.ui.bootstrap

import csstype.ClassName
import ru.arty_bikini.crm_frontend.util.plus

object BootstrapGrid {

    val col = ClassName("col")
    fun colLg(size: Int?): ClassName {
        if (size == null) {
            return ClassName("col-lg")
        }
        return ClassName("col-lg-$size")
    }
    fun colMd(size: Int?): ClassName {
        if (size == null) {
            return ClassName("col-md")
        }
        return ClassName("col-md-$size")
    }
    fun colSm(size: Int?): ClassName {
        if (size == null) {
            return ClassName("col-sm")
        }
        return ClassName("col-sm-$size")
    }
}
