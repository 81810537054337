package ru.arty_bikini.crm_frontend.table.admin

import csstype.ClassName
import react.dom.html.ReactHTML.div
import ru.arty_bikini.crm.dto.enums.UserGroup
import ru.arty_bikini.crm_frontend.table.common.commonPage
import ru.arty_bikini.crm_frontend.ui.bootstrap.cardLightShadow
import ru.arty_bikini.crm_frontend.ui.bootstrap.inputGroup
import ru.arty_bikini.crm_frontend.ui.input.form.FormInput
import ru.arty_bikini.crm_frontend.ui.input.table.FieldFlag

val ClientConfigPage = commonPage(
    requireGroups = listOf(UserGroup.ADMIN)
) { props ->

    cardLightShadow {

        FormInput(props.client, props.client.settings) {

            onSave { /* instant save on edit */ }

            div {
                className = ClassName("vstack gap-3")

                inputGroup() {
                    name("Показывать пустые подсказки (для редактирования)")
                    addSelect(
                        FieldFlag.NotNull,
                        listOf(true, false),
                        { it.toString() },
                        { it.toString() },
                        { this::showDisabledHints })
                }

                inputGroup() {
                    name("Предпросмотр как сотрудник (не везде)")
                    addSelect(
                        FieldFlag.NotNull,
                        listOf(true, false),
                        { it.toString() },
                        { it.toString() },
                        { this::viewAsWorker })
                }

                inputGroup() {
                    name("Дополнительный опции в разных меню (преимущественно для отладки)")
                    addSelect(
                        FieldFlag.NotNull,
                        listOf(true, false),
                        { it.toString() },
                        { it.toString() },
                        { this::extendedMenu })
                }

                inputGroup() {
                    name("Отладочный вывод")
                    addSelect(
                        FieldFlag.NotNull,
                        listOf(true, false),
                        { it.toString() },
                        { it.toString() },
                        { this::debugLogs })
                }
            }

        }

    }
}
