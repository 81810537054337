package ru.arty_bikini.crm_frontend

import kotlinx.browser.window
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import ru.arty_bikini.crm_frontend.debug.Test
import ru.arty_bikini.crm_frontend.event.EventModule
import ru.arty_bikini.crm_frontend.event.ShowAlertEvent
import ru.arty_bikini.crm_frontend.network.EntitySaveHelper
import ru.arty_bikini.crm_frontend.network.NetworkModule
import ru.arty_bikini.crm_frontend.network.ServerResponseStatus
import ru.arty_bikini.crm_frontend.ui.bootstrap.BootstrapColor

class ClientCore {

    init {
        window.asDynamic()["clientCore"] = this

        GlobalScope.launch {
            Test.test()
        }

        GlobalScope.launch {
            while (true) {
                delay(1_000)

                val status = network.core
                    .pull()

                if (status.status != ServerResponseStatus.SUCCESS) {
//                    event.fireEvent(ShowAlertEvent(BootstrapColor.DANGER, "Ошибка подключения к серверу: ${status.status}"))
                    console.log("Network error: ${status.status}: ${status.serverStatusCode}, ${status.userDisplayMessage}")
                }

                if (status.body?.needReload == true) {
                    event.fireEvent(ShowAlertEvent(BootstrapColor.DANGER, "Ошибка подключения к серверу. Обновите страницу"))
                    console.log("Reload required: ${status.status}: ${status.serverStatusCode}, ${status.userDisplayMessage}")

                }
            }
        }
    }

    val auth = AuthModule(this)
    val network = NetworkModule(this)
    val save = EntitySaveHelper()
    val cache = CacheModule(network)
    val event = EventModule(this)
    val settings = SettingsModule(this)

}

