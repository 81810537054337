package ru.arty_bikini.crm_frontend.table.config

import ru.arty_bikini.crm.dto.dict.PriceDTO
import ru.arty_bikini.crm.dto.dict.StoneColorDTO
import ru.arty_bikini.crm.dto.enums.UserGroup
import ru.arty_bikini.crm_frontend.table.common.dictTablePage
import ru.arty_bikini.crm_frontend.ui.input.table.FieldFlag
import ru.arty_bikini.crm_frontend.ui.input.table.addText

val StoneColorTablePage = dictTablePage(
    requireGroups = listOf(UserGroup.ADMIN),
    serializer = StoneColorDTO.serializer(),
    dict = { dict.stoneColor },
    addNew = { StoneColorDTO().apply { name = it } }
) {

    addText(FieldFlag.Nullable, "Цвет", StoneColorDTO::name)
    addText(FieldFlag.Nullable, "Группа", StoneColorDTO::group)

}
