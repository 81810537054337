package ru.arty_bikini.crm_frontend.table.common

import csstype.ClassName
import kotlinx.serialization.KSerializer
import react.dom.html.ReactHTML
import ru.arty_bikini.crm.dto.enums.UserGroup
import ru.arty_bikini.crm_frontend.ClientProps
import ru.arty_bikini.crm_frontend.network.Entity
import ru.arty_bikini.crm_frontend.network.NetworkModule
import ru.arty_bikini.crm_frontend.network.dict.BaseDictService
import ru.arty_bikini.crm_frontend.ui.input.table.TablePanelEntityAuto
import ru.arty_bikini.crm_frontend.ui.input.table.TablePanelProps
import ru.arty_bikini.crm_frontend.util.useAutoReload


fun <E : Entity> dictTablePage(
    requireGroups: List<UserGroup> = listOf(UserGroup.ADMIN, UserGroup.TANYA),
    serializer: KSerializer<E>,
    load: suspend NetworkModule.() -> List<E>?,
    save: suspend NetworkModule.(E) -> Unit,
    addNew: suspend NetworkModule.(String) -> Unit,
    init: TablePanelProps<E>.(props: ClientProps) -> Unit
) = commonPage(requireGroups) { props ->

    val all = useAutoReload {
        props
            .client
            .network
            .load()
    }

    TablePanelEntityAuto(props.client, "t-dict-${serializer.descriptor.serialName}", all, serializer) {

        save.let {
            this.save = {
                props.client
                    .network
                    .save(it)

                props.client.cache.invalidateAll()
            }

        }

        addNew.let {
            this.addNew = {
                props.client
                    .network
                    .addNew(it)

                props.client.cache.invalidateAll()
            }

        }

        init(props)

    }
}


fun <E : Entity> dictTablePage(
    requireGroups: List<UserGroup> = listOf(UserGroup.ADMIN, UserGroup.TANYA),
    serializer: KSerializer<E>,
    dict: NetworkModule.() -> BaseDictService<E>,
    addNew: suspend (String) -> E,
    init: TablePanelProps<E>.(props: ClientProps) -> Unit
) = dictTablePage(
    requireGroups = requireGroups,
    serializer = serializer,
    load = { dict().getAll().body?.data },
    save = { dict().editEntity(it) },
    addNew = { dict().addEntity(addNew(it)) },
    init = init
)
