package ru.arty_bikini.crm_frontend.table.admin

import csstype.ClassName
import kotlinx.browser.window
import kotlinx.serialization.builtins.ListSerializer
import kotlinx.serialization.json.*
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.span
import react.useEffectOnce
import react.useState
import ru.arty_bikini.crm.dto.UserDTO
import ru.arty_bikini.crm.dto.enums.SortDirection
import ru.arty_bikini.crm.dto.enums.UserGroup
import ru.arty_bikini.crm.dto.orders.OrderDTO
import ru.arty_bikini.crm.dto.orders.stone.OrderRhinestoneAmountDTO
import ru.arty_bikini.crm.dto.other.HistoryActionType
import ru.arty_bikini.crm.dto.other.HistoryDTO
import ru.arty_bikini.crm.dto.packet.auth.HistoryFilter
import ru.arty_bikini.crm_frontend.ClientProps
import ru.arty_bikini.crm_frontend.form.calc.CalculatorForm
import ru.arty_bikini.crm_frontend.form.main.ArchiveMainForm
import ru.arty_bikini.crm_frontend.table.common.commonPage
import ru.arty_bikini.crm_frontend.ui.bootstrap.BootstrapColor
import ru.arty_bikini.crm_frontend.ui.bootstrap.badge
import ru.arty_bikini.crm_frontend.ui.bootstrap.cardLightShadow
import ru.arty_bikini.crm_frontend.ui.bootstrap.inputGroup
import ru.arty_bikini.crm_frontend.ui.input.form.FormInput
import ru.arty_bikini.crm_frontend.ui.input.table.*
import ru.arty_bikini.crm_frontend.ui.input.table.data.DataSource
import ru.arty_bikini.crm_frontend.ui.input.table.data.PagerDataProvider
import ru.arty_bikini.crm_frontend.ui.input.types.or
import ru.arty_bikini.crm_frontend.util.StringUtils
import ru.arty_bikini.crm_frontend.util.clone
import ru.arty_bikini.crm_frontend.util.useCache
import kotlin.math.max

private val json = Json {
    ignoreUnknownKeys = true
    explicitNulls = false
    isLenient = true
}

val HistoryListPage = commonPage(
    requireGroups = listOf(UserGroup.ADMIN, UserGroup.TANYA)
) { props ->

    val workers: List<UserDTO> = useCache(props.client.cache.users)

    var current by useState<HistoryFilter> { HistoryFilter() }

    var enableGrouping by useState(true)


    useEffectOnce {
        val hash = window.location.hash
            .removePrefix("#")
            .removePrefix("?")
            .split("&")
            .map { it.split("=", limit = 2) }
            .map { it.firstOrNull() to it.getOrNull(1) }
            .toMap()

        val entityId = hash.get("entityId")?.toIntOrNull()

        console.log(window.location.hash, hash, entityId)

        if (entityId != null) {
            current = current.clone(HistoryFilter.serializer()).also { it.entityId = entityId }
        }
    }


    cardLightShadow {
        div {
            className = ClassName("vstack gap-2")

            FormInput(props.client, current.clone(HistoryFilter.serializer())) {
                onSave {
                    current = it
                }

                inputGroup {
                    name("Тип")
                    addSelect.invoke(
                        flag = FieldFlag.Nullable,
                        variants = listOf(null, "OrderEntity", "OrderRhinestoneAmountEntity"),
                        toKey = { it ?: "<null>" },
                        toText = { getTitle(it) },
                        prop = { this::entityType }
                    )
                }
                inputGroup {
                    name("Ид")
                    addInt.invoke(
                        FieldFlag.Nullable,
                        allowDelete = true,
                        { this::entityId },
                    )
                }

                inputGroup {
                    name("Сотрудник")
                    addSelect.invoke(
                        flag = FieldFlag.Nullable,
                        variants = workers,
                        toText = { it.name },
                        prop = { this::editor }
                    )
                }

                inputGroup {
                    name("Группировать похожие изменения")
                    addSelect(
                        flag = FieldFlag.Nullable,
                        variants = listOf(true, false),
                        toKey = { it.toString() },
                        toText = { if (it) "Да" else "Нет" },
                        get = { enableGrouping },
                        set = { enableGrouping = it ?: false }
                    )
                }
            }
        }
    }


    PagerDataProvider(props.client, { history.get(it).body }, { current.clone(HistoryFilter.serializer()) }) { data ->

        var dataGrouped: DataSource<HistoryDTO> = data
        if (enableGrouping) {
            dataGrouped = dataGrouped.groupIf(
                rule = { old, new ->


                    if (
                        old.entityType == new.entityType
                        && old.entityId == new.entityId
                        && old.actionType == new.actionType
                        && old.editor == new.editor
                        && isDeltaIn(old.editTime, new.editTime, 600_000)
                    ) {

                        return@groupIf true

                    }

                    return@groupIf false
                },
                merge = { all ->
                    if (all.size == 1) {
                        return@groupIf all.firstOrNull()
                    }
                    val first = all.minBy { it.editTime ?: 0 }
                    val last = all.maxBy { it.editTime ?: 0 }

                    return@groupIf first.clone(HistoryDTO.serializer()).also {
                        it.newValue = last.newValue

                        it.extra.group = true
                        it.extra.groupSize = all.size
                        it.extra.editTimeLast = last.editTime ?: 0
                    }
                }
            )
        }

        TablePanelEntitySource(props.client, "t-admin-history", dataGrouped, ) {
            addText(FieldFlag.ReadOnlyProp, "Тип", HistoryDTO::entityType) {
                getTitle(it)
            }
            addInt(FieldFlag.ReadOnlyProp, "Ид", HistoryDTO::entityId, TableColumnRenderOptions(extraContent = { entity ->
                span {
                    badge(BootstrapColor.INFO, "Похожие");

                    onClick = {
                        current = current.clone(HistoryFilter.serializer()).apply {
                            entityId = entity.entityId
                        }
                    }
                }
            }))
            addText(FieldFlag.ReadOnlyProp, "Новое имя", HistoryDTO::entityName)

            addText(FieldFlag.ReadOnlyProp, "Автор", HistoryDTO::editor or ::UserDTO then UserDTO::name)
            addDateTime(
                flag = FieldFlag.ReadOnlyProp,
                title = "Время",
                value = HistoryDTO::editTime,
                options = TableColumnRenderOptions(
                    defaultSortDirection = SortDirection.DESC,
                    extraContent = { entity ->
                        if (entity.extra.group) {
                            div {
                                className = ClassName("vstack gap-1 mt-1")

                                badge(BootstrapColor.LIGHT, "Группа: " + entity.extra.groupSize)
                                badge(BootstrapColor.LIGHT, "Последнее: " + StringUtils.printLocalDateTime(entity.extra.editTimeLast))

                            }
                        }
                    }
                )
            )
            addSelect(FieldFlag.ReadOnlyProp, "Тип изменения", HistoryActionType.values(), HistoryDTO::actionType)
            addText(FieldFlag.ReadOnlyValue, "Тип изменения") { actionFlags.joinToString { it.displayName } }

            addTextArea(FieldFlag.Nullable, "Старое значение", HistoryDTO::oldValue)
            addButton("Предпросмотр (Старое)") {
                openForm(it, props, it.oldValue)
            }
            addTextArea(FieldFlag.Nullable, "Новое значение", HistoryDTO::newValue)
            addButton("Предпросмотр (Новое)") {
                openForm(it, props, it.newValue)
            }
            addRaw("Изменения") {
                val old: JsonElement = json.parseToJsonElement(it.oldValue ?: "{}")
                val new: JsonElement = json.parseToJsonElement(it.newValue ?: "{}")

                div {
                    className = ClassName("vstack gap-1")
                    getDiff("$", old, new)
                        .forEach {
                            badge(it.color, it.text)
                        }
                }
            }

        }
    }

}

private fun getTitle(type: String?) = when (type) {
    null -> "Все"
    "OrderEntity" -> "Заказ"
    "OrderRhinestoneAmountEntity" -> "Заказ (Стразы)"
    else -> "#$type"
}

private fun openForm(it: HistoryDTO, props: ClientProps, jsonValue: String?) {
    when (it.entityType) {
        "OrderEntity" -> {
            val order = json.decodeFromString(OrderDTO.serializer(), jsonValue ?: "{}")
            order.id = -1
            ArchiveMainForm.openNow(props.client, order)
        }
        "OrderRhinestoneAmountEntity" -> {
            val data = json.decodeFromString(ListSerializer(OrderRhinestoneAmountDTO.serializer()), jsonValue ?: "{}")

            val order = OrderDTO().apply {
                this.id = -1
                this.stones = data
            }

            CalculatorForm.openNow(props.client, order)

        }
    }
}

private fun getDiff(path: String, old: JsonElement?, new: JsonElement?): Sequence<DiffItem> {
    return when {
        old is JsonObject && new is JsonObject -> {
            (old.keys + new.keys)
                .asSequence()
                .sortedBy { it }
                .map { key ->
                    getDiff("$path.$key", old[key], new[key])
                }
                .flatMap { it }
        }
        old is JsonArray && new is JsonArray -> {
            (0 until max(old.size, new.size))
                .asSequence()
                .map { index ->
                    getDiff("$path[$index]", old.getOrNull(index), new.getOrNull(index))
                }
                .flatMap { it }
        }
        old?.equals(new) == true -> emptySequence()
        else -> sequenceOf(DiffItem(path, old ?: JsonNull, new ?: JsonNull))
    }
}

class DiffItem(val path: String, val old: JsonElement, val new: JsonElement) {
    val color: BootstrapColor = when {
        old is JsonNull -> BootstrapColor.SUCCESS
        new is JsonNull -> BootstrapColor.DANGER
        else -> BootstrapColor.WARNING
    }
    val text = "$path: $old -> $new"
}

fun isDeltaIn(start: Long?, end: Long?, delta: Long): Boolean {
    start ?: return false
    end ?: return false

    val dt = start - end
    return dt in -delta .. delta
}

val HistoryDTO.extra: HistoryDTOExtra
    get() {
        if (this.asDynamic()["_extra"] == null) {
            this.asDynamic()["_extra"] = HistoryDTOExtra()
        }
        return this.asDynamic()["_extra"]
    }

class HistoryDTOExtra {
    var group: Boolean = false
    var groupSize: Int = 1
    var editTimeLast: Long = 0
}
