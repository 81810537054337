package ru.arty_bikini.crm.dto.packet.auth

import kotlinx.serialization.Serializable
import ru.arty_bikini.crm.dto.PageDTO
import ru.arty_bikini.crm.dto.UserDTO
import ru.arty_bikini.crm.dto.filter.EntityFilter
import ru.arty_bikini.crm.dto.other.HistoryDTO
import ru.arty_bikini.crm.dto.packet.BaseResponse

@Serializable
class GetHistoryResponse (
    override val ok: Boolean,
    override val statusCode: String,
    override val displayMessage: String?,
    
    var historyDTOList: PageDTO<HistoryDTO>?= null
    
): BaseResponse()

@Serializable
class HistoryFilter : EntityFilter() {

    var entityType : String? = null
    var entityId: Int? = null

    var editor: UserDTO? = null

    var createdTimeFrom: Long? = null
    var createdTimeTo: Long? = null

}

@Serializable
class GetHistoryRequest(
    var filter: HistoryFilter
)
