package ru.arty_bikini.crm_frontend.network

import kotlinx.browser.window
import kotlinx.coroutines.await
import kotlinx.js.jso
import kotlinx.serialization.*
import kotlinx.serialization.builtins.serializer
import kotlinx.serialization.json.Json
import org.w3c.fetch.RequestInit
import org.w3c.fetch.Response
import org.w3c.xhr.FormData
import ru.arty_bikini.crm.dto.packet.BaseResponse
import ru.arty_bikini.crm_frontend.ClientCore
import ru.arty_bikini.crm_frontend.event.ShowAlertEvent
import ru.arty_bikini.crm_frontend.ui.bootstrap.BootstrapColor

@OptIn(BaseServiceInternal::class)
abstract class BaseService(val clientCore: ClientCore) {

    @OptIn(ExperimentalSerializationApi::class)
    val encoder = Json {
        explicitNulls = true
        encodeDefaults = true
    }

    @OptIn(ExperimentalSerializationApi::class)
    val decoder = Json {
        explicitNulls = false
        encodeDefaults = false
        ignoreUnknownKeys = true
    }

    suspend inline fun <reified A : Any> callFile(
        url: String,
        formData: FormData
    ): ServerResponse<A> {
        val answerSerializer = serializer<A>()
        return callInternal<A, Unit>(url, Unit.serializer(), answerSerializer, emptyMap(), Unit, formData)
    }

    suspend inline fun <reified A : Any> call(
        url: String,
        query: Map<String, String>? = null,
    ): ServerResponse<A> = call<A, Unit>(url, query, Unit)

    suspend inline fun <reified A : Any, reified R : Any> call(
        url: String,
        query: Map<String, String>? = null,
        body: R,
    ): ServerResponse<A> {
        val requestSerializer = serializer<R>()
        val answerSerializer = serializer<A>()
        return callInternal(url, requestSerializer, answerSerializer, query, body, null)
    }

    suspend inline fun <A : Any> callGeneric(
        url: String,
        answerSerializer: KSerializer<A>,
    ): ServerResponse<A> {
        return callGeneric(url, Unit.serializer(), answerSerializer, Unit)
    }

    suspend inline fun <A : Any, R : Any> callGeneric(
        url: String,
        requestSerializer: KSerializer<R>,
        answerSerializer: KSerializer<A>,
        body: R,
    ): ServerResponse<A> {
        return callInternal(url, requestSerializer, answerSerializer, null, body, null)
    }

    @BaseServiceInternal
    suspend fun <A : Any, R : Any> callInternal(
        url: String,
        requestSerializer: KSerializer<R>,
        answerSerializer: KSerializer<A>,
        query: Map<String, String>?,
        body: R,
        formData: FormData?
    ): ServerResponse<A> {

        try {
            if (PacketMocks.enabled) {
                return ServerResponse(
                    raw = Response(),
                    text = "<mock>",
                    body = PacketMocks.get(url, query ?: emptyMap(), body)
                )
            }

            val sessionToken = clientCore.auth.getSessionToken() ?: ""

            val fullUrl = url + "?key=" + sessionToken + "&" + query?.entries?.joinToString("&") { it.key + "=" + it.value }.orEmpty()

            val response = window.fetch(
                fullUrl, RequestInit(
                    method = "POST",
                    headers = jso {
                        if (formData == null) {
                            this["Content-Type"] = "application/json"
                        }
                    },
                    body = formData ?: encoder.encodeToString(requestSerializer, body)
                )
            )

            response
                .then { raw -> raw.text().then { ServerResponse<A>(raw, it) } }
                .then {
                    if (it.raw.status != 200.toShort()) {
                        return@then it.copy(status = ServerResponseStatus.NETWORK_ERROR)
                    }
                    val answerBody = try {
                        decoder.decodeFromString(answerSerializer, it.text)
                    } catch (e: Throwable) {
                        return@then it.copy(status = ServerResponseStatus.DECODE_ERROR, exception = e)
                    }
                    it.copy(status = ServerResponseStatus.SUCCESS, body = answerBody)
                }
                .then {
                    var answer = it
                    if (it.body is BaseResponse) {
                        answer.copy(serverStatusCode = it.body.statusCode, userDisplayMessage = it.body.displayMessage)
                        if (it.status == ServerResponseStatus.SUCCESS && !it.body.ok) {
                            answer = answer.copy(status = ServerResponseStatus.SERVER_ERROR)
                        }

                    }
                    answer
                }
                .then(
                    { it },
                    { ServerResponse(Response(), "<lost>", status = ServerResponseStatus.OTHER_ERROR, exception = it) }
                )
                .then {
                    if (clientCore.settings.debugLogs) {
                        console.log(
                            listOfNotNull(
                                it.status.name,
                                it.serverStatusCode,
                                it.userDisplayMessage,
                                it
                            ).toTypedArray()
                        )
                    }
                    it.exception?.printStackTrace()

                    if (it.status != ServerResponseStatus.SUCCESS) {
                        val message = it.userDisplayMessage ?: "Что-то пошло не так."
                        clientCore.event.fireEvent(ShowAlertEvent(BootstrapColor.DANGER, message))
                    }
                    it
                }
                .await()
                .let { return it }
        } catch (e: Throwable) {
            e.printStackTrace()
            return ServerResponse(Response(), "<not available>", status = ServerResponseStatus.OTHER_ERROR, exception = e)
        }

    }

}

data class ServerResponse<T : Any>(
    val raw: Response,
    val text: String,
    val status: ServerResponseStatus = ServerResponseStatus.UNKNOWN,
    val serverStatusCode: String? = null,
    val body: T? = null,
    val exception: Throwable? = null,
    val userDisplayMessage: String? = null
) {
    fun <V : Any> map(action: T.() -> V?): ServerResponse<V> {
        return this
            .unsafeCast<ServerResponse<V>>()
            .copy(body = this.body?.let { action(it) })
    }
}

enum class ServerResponseStatus() {
    UNKNOWN(),
    SUCCESS(),
    NETWORK_ERROR,
    DECODE_ERROR,
    OTHER_ERROR,
    SERVER_ERROR,
}

@RequiresOptIn
private annotation class BaseServiceInternal
