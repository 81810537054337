package ru.arty_bikini.crm_frontend.ui.input.table.data

import csstype.ClassName
import csstype.Length
import emotion.react.css
import react.Props
import react.dom.html.ReactHTML.div
import react.useEffectOnce
import ru.arty_bikini.crm_frontend.ui.bootstrap.BootstrapColor
import ru.arty_bikini.crm_frontend.ui.bootstrap.inputGroup
import ru.arty_bikini.crm_frontend.ui.root.tryFC
import ru.arty_bikini.crm_frontend.util.plus

external interface PagerComponentProps : Props {
    var setPage: (Int) -> Unit

    var currentPage: Int
    var lastPage: Int
}

val PagerComponent = tryFC<PagerComponentProps> { props ->

    useEffectOnce { props.setPage(props.currentPage) }

    fun goToPage(newPage: Int) {
        props.setPage(newPage)
    }

    val rendered = ArrayList<Int>()

    rendered.add(props.currentPage)

    div {
        className = className + ClassName("ms-3 mb-1 sticky-bottom")

        div {
            css {
                width = Length.fitContent
            }

            className = className + ClassName("card p-2")

            inputGroup {
                fun render(value: Int) {
                    if (value < 0 || value > props.lastPage) return
                    if (rendered.contains(value)) return

                    rendered.add(value)
                    btn(BootstrapColor.SECONDARY, "${value + 1}") { goToPage(value) }
                }

                render(0)
                render(props.currentPage - 1)
                value("""${(props.currentPage + 1)} из ${props.lastPage + 1}""")
                render(props.currentPage + 1)
                render(props.lastPage)
            }
        }
    }
}