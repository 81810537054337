package ru.arty_bikini.crm_frontend.ui.input.table.data

import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import react.ChildrenBuilder
import react.useEffect
import react.useState
import ru.arty_bikini.crm.dto.PageDTO
import ru.arty_bikini.crm.dto.filter.EntityFilter
import ru.arty_bikini.crm.dto.filter.OrderFilter
import ru.arty_bikini.crm.dto.orders.OrderDTO
import ru.arty_bikini.crm_frontend.ClientCore
import ru.arty_bikini.crm_frontend.ClientProps
import ru.arty_bikini.crm_frontend.network.NetworkModule
import ru.arty_bikini.crm_frontend.ui.input.table.ColumnStateHandler
import ru.arty_bikini.crm_frontend.ui.input.table.TablePanelEntity
import ru.arty_bikini.crm_frontend.ui.root.tryFC

external interface PagerDataProviderProps<T : Any, F : EntityFilter> : ClientProps {

    var loadPage: suspend (F) -> PageDTO<T>?

    var defaultFilter: () -> F

    var content: (ChildrenBuilder, DataSource<T>) -> Unit
}

private val PagerDataProvider = tryFC<PagerDataProviderProps<*, *>> { bounded(it) }

fun <T : Any, F : EntityFilter> ChildrenBuilder.bounded(props: PagerDataProviderProps<T, F>) {

    val (dataCache, setDataCache) = useState<List<TablePanelEntity<T>>> { emptyList() }

    var loading by useState(DataSourceLoadingState.LOADING)
    var filter by useState(props.defaultFilter)

    var currentPage by useState(0)
    var lastPage by useState(10)
    var lastSortRules by useState<ColumnStateHandler<T>>()

    useEffect(filter, currentPage) {
        loading = DataSourceLoadingState.LOADING

        GlobalScope.launch {
            filter.page = currentPage

            val page = props.loadPage.invoke(filter)
            if (page == null) {
                loading = DataSourceLoadingState.ERROR
                return@launch
            }

            setDataCache(page.data.map { TablePanelEntity(it) })
            lastPage = page.totalPages - 1
            loading = DataSourceLoadingState.LOADED
        }
    }
    useEffect(props.defaultFilter) {
        val new = props.defaultFilter()

        new.page = currentPage

        lastSortRules?.primarySort?.let { (col, sort) ->
            new.orderColumn = col?.prop?.sortName
            new.orderDirection = sort
        }

        filter = new
        currentPage = 0

    }

    val dataSource = DataSource<T>(
        state = loading,
        currentData = dataCache,
        setSort = {
            val new = props.defaultFilter()

            new.page = 0

            it.primarySort?.let { (col, sort) ->
                new.orderColumn = col?.prop?.sortName
                new.orderDirection = sort
            }

            lastSortRules = it
            filter = new
            currentPage = 0
        },
        clientSideSorting = false
    )

    props.content(this, dataSource)

    PagerComponent {
        this.setPage = { page -> if (page != currentPage) currentPage = page }

        this.currentPage = currentPage
        this.lastPage = lastPage
    }
}

fun <T : Any, F : EntityFilter> ChildrenBuilder.PagerDataProvider(
    client: ClientCore,
    loadPage: suspend NetworkModule.(F) -> PageDTO<T>?,
    defaultFilter: () -> F,
    content: ChildrenBuilder.(DataSource<T>) -> Unit
) {
    PagerDataProvider {
        val props = this.unsafeCast<PagerDataProviderProps<T, F>>()

        props.client = client

        props.loadPage = { filter -> client.network.loadPage(filter) }
        props.defaultFilter = defaultFilter

        props.content = content
    }
}

fun ChildrenBuilder.PagerDataProviderOrder(
    client: ClientCore,
    defaultFilter: () -> OrderFilter,
    content: ChildrenBuilder.(DataSource<OrderDTO>) -> Unit
) {
    PagerDataProvider(client, { order.getOrdersPage(it).body }, defaultFilter, content)
}
