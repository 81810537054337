package ru.arty_bikini.crm_frontend.ui.input.types

import react.dom.html.InputType
import ru.arty_bikini.crm.dto.EntityDTO
import ru.arty_bikini.crm.dto.enums.HasDisplayValue
import ru.arty_bikini.crm_frontend.util.StringUtils
import kotlin.js.Date

interface ValueConverter<V : Any, P : Any> {

    val inputType: InputType

    fun toProtocol(value: V): P
    fun fromProtocol(value: P): V

    fun toKey(value: V): String
    fun fromKey(value: String): V?

    fun toText(value: V?): String
    fun toSort(value: V): String

    fun fromProtocolToText(value: P): String = toText(fromProtocol(value))
    fun fromProtocolToKey(value: P): String = toKey(fromProtocol(value))
    fun fromKeyToProtocol(value: String): P? = fromKey(value)?.let { toProtocol(it) }
}

object DateValueConverter : ValueConverter<Date, Long> {

    override val inputType: InputType = InputType.date

    override fun toProtocol(value: Date): Long = value.getTime().toLong()
    override fun fromProtocol(value: Long): Date = Date(value)
    override fun toKey(value: Date): String = value.toISOString().split("T").first()
    override fun toSort(value: Date): String = value.getTime().toLong().toString()
    override fun fromKey(value: String): Date? = Date(Date.parse(value))
    override fun toText(value: Date?): String = value?.let { StringUtils.printLocalDate(it) } ?: ""
}

object ShortDateValueConverter : ValueConverter<Date, Long> {

    override val inputType: InputType = InputType.date

    override fun toProtocol(value: Date): Long = value.getTime().toLong()
    override fun fromProtocol(value: Long): Date = Date(value)
    override fun toKey(value: Date): String = value.toISOString().split("T").first()
    override fun toSort(value: Date): String = value.getTime().toLong().toString()
    override fun fromKey(value: String): Date? = Date(Date.parse(value))
    override fun toText(value: Date?): String = value?.let { StringUtils.printLocalDateShort(it) } ?: ""
}

object DateTimeValueConverter : ValueConverter<Date, Long> {

    override val inputType: InputType = InputType.datetimeLocal

    override fun toProtocol(value: Date): Long = value.getTime().toLong()
    override fun fromProtocol(value: Long): Date = Date(value)
    override fun toKey(value: Date): String = value.toISOString()
    override fun toSort(value: Date): String = value.getTime().toLong().toString()
    override fun fromKey(value: String): Date? = Date(Date.parse(value))
    override fun toText(value: Date?): String = value?.let { StringUtils.printLocalDateTime(it) } ?: ""
}

class IntAsString(val value: String) {
    val asInt: Int = value.replace(notADigit, "").toIntOrNull() ?: 0

    fun normalizeCost(): String {
        return StringUtils.printNumber(value.replace(" ", ""))
    }

    companion object {

        val notADigit = Regex("[^0-9]")
        val notADigitOrSpace = Regex("[^0-9., ]")

        fun fromRaw(raw: String): IntAsString {

            val minus = if (raw.trim().startsWith("-")) "-" else ""

            val raw = raw.replace(notADigitOrSpace, "")

            return IntAsString(minus + raw)
        }

        fun fromInt(raw: Int): IntAsString {
            return IntAsString(raw.toString())
        }
    }
}

object IntValueConverter : ValueConverter<Int, Int> {

    override val inputType: InputType = InputType.number

    override fun toProtocol(value: Int): Int = value
    override fun fromProtocol(value: Int): Int = value
    override fun toKey(value: Int): String = value.toString()
    override fun toSort(value: Int): String = value.toString()
    override fun fromKey(value: String): Int? = value.toIntOrNull() ?: 0
    override fun toText(value: Int?): String = value?.toString() ?: "0"
}

object CostValueConverter : ValueConverter<IntAsString, Int> {

    override val inputType: InputType = InputType.text

    override fun toProtocol(value: IntAsString): Int = value.asInt
    override fun fromProtocol(value: Int): IntAsString = IntAsString.fromInt(value)
    override fun toKey(value: IntAsString): String = value.normalizeCost()
    override fun toSort(value: IntAsString): String = value.asInt.toString()
    override fun fromKey(value: String): IntAsString? = IntAsString.fromRaw(value)
    override fun toText(value: IntAsString?): String = value?.normalizeCost() ?: "0"

}

object PhoneValueConverter : ValueConverter<String, String> {

    override val inputType: InputType = InputType.text

    override fun toProtocol(value: String): String = value
    override fun fromProtocol(value: String): String = value
    override fun toKey(value: String): String = StringUtils.printPhoneOrText(value)
    override fun toSort(value: String): String = value
    override fun fromKey(value: String): String? = StringUtils.parsePhone(value)
    override fun toText(value: String?): String = StringUtils.printPhoneOrText(value) ?: "0"

}

object BooleanValueConverter : ValueConverter<Boolean, Boolean> {

    override val inputType: InputType = InputType.checkbox

    override fun toProtocol(value: Boolean): Boolean = value
    override fun fromProtocol(value: Boolean): Boolean = value
    override fun toKey(value: Boolean): String = value.toString()
    override fun toSort(value: Boolean): String = if (value) "a" else "b"
    override fun fromKey(value: String): Boolean? = value.toBoolean()
    override fun toText(value: Boolean?): String = if (value == true) "Да" else "Нет"

}

object StringValueConverter : ValueConverter<String, String> {

    override val inputType: InputType = InputType.text


    override fun toProtocol(value: String): String = value
    override fun fromProtocol(value: String): String = value
    override fun toKey(value: String): String = value
    override fun toSort(value: String): String = value
    override fun fromKey(value: String): String = value
    override fun toText(value: String?): String = value ?: ""
}


class EnumValueConverter<T>(val variants: ValueProvider<T>) : ValueConverter<T, T>
        where T : Enum<T>,
              T : HasDisplayValue {

    override val inputType: InputType = InputType.text


    override fun toProtocol(value: T): T = value
    override fun fromProtocol(value: T): T = value
    override fun toKey(value: T): String = value.name
    override fun toSort(value: T): String = value.displayName
    override fun fromKey(value: String): T? = variants.getAll().firstOrNull { it.name == value }
    override fun toText(value: T?): String = value?.displayName ?: ""

}

class EntityValueConverter<T>(val variants: ValueProvider<T>, val getName: (T) -> String?) : ValueConverter<T, T>
        where T : EntityDTO {

    override val inputType: InputType = InputType.text


    override fun toProtocol(value: T): T = value
    override fun fromProtocol(value: T): T = value
    override fun toKey(value: T): String = value.id.toString()
    override fun toSort(value: T): String = getName(value) ?: ""
    override fun fromKey(value: String): T? = variants.getAll().firstOrNull { it.id == value.toIntOrNull() }
    override fun toText(value: T?): String = value?.let(getName) ?: ""

}

class UnitValueConverter(val text: String) : ValueConverter<Unit, Unit> {

    override val inputType: InputType = InputType.text


    override fun toProtocol(value: Unit): Unit = value
    override fun fromProtocol(value: Unit): Unit = value
    override fun toKey(value: Unit): String = "Unit"
    override fun toSort(value: Unit): String = "<none>"
    override fun fromKey(value: String): Unit? = Unit
    override fun toText(value: Unit?): String = text

}
