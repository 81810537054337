package ru.arty_bikini.crm_frontend.ui.input.form

import react.*
import ru.arty_bikini.crm_frontend.ui.root.tryFC
import kotlin.reflect.KMutableProperty0

external interface FormInputCellProps : Props {
    var data: FormInputCellData<*, *, *>
}

val FormInputCell = tryFC<FormInputCellProps> {
    it.data.render(this)
}

class FormInputCellData<T, VB, V : VB> constructor(
    val builder: FormInputBuilder<T, VB>,
    val get: T.() -> V,
    val set: T.(V) -> Unit,
) {

    fun render(cb: ChildrenBuilder) {

        with(cb) {
            val prop = get(builder.entity)
            var value by useState { prop }

            useEffect(prop.toString()) {
                value = prop
            }

            builder.renderInternal(
                cb = this,
                value = value,
                update = { new -> value = new},
                save = { new ->

                    value = new
                    set(builder.entity, new)

                }
            )

        }
    }
}
