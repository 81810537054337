package ru.arty_bikini.crm_frontend.ui.bootstrap

import csstype.ClassName
import react.ChildrenBuilder
import react.dom.html.ReactHTML.div
import ru.arty_bikini.crm_frontend.util.plus

inline fun ChildrenBuilder.container(crossinline content: ChildrenBuilder.() -> Unit) {
    div {
        className = ClassName("container")

        content()
    }
}

inline fun ChildrenBuilder.containerFluid(crossinline content: ChildrenBuilder.() -> Unit) {
    div {
        className = ClassName("container-fluid")

        content()
    }
}


inline fun ChildrenBuilder.row(extraClasses: ClassName? = null, title: String? = null, crossinline content: ChildrenBuilder.() -> Unit) {
    div {
        className = ClassName("row") + extraClasses

        this.title = title

        content()
    }
}

inline fun ChildrenBuilder.colLg(size: Int? = null, crossinline content: ChildrenBuilder.() -> Unit) {
    div {
        className = BootstrapGrid.colLg(size) + ClassName("mb-1")

        content()
    }
}

inline fun ChildrenBuilder.colMd(size: Int? = null, crossinline content: ChildrenBuilder.() -> Unit) {
    div {
        className = BootstrapGrid.colMd(size) + ClassName("mb-1")

        content()
    }
}


inline fun ChildrenBuilder.colSm(size: Int? = null, crossinline content: ChildrenBuilder.() -> Unit) {
    div {
        className = BootstrapGrid.colSm(size) + ClassName("mb-1")

        content()
    }
}
