package ru.arty_bikini.crm_frontend.ui.hint

import csstype.Auto
import csstype.ClassName
import csstype.px
import csstype.vw
import emotion.react.css
import kotlinx.browser.window
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import react.ChildrenBuilder
import react.dom.html.ReactHTML.button
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.img
import ru.arty_bikini.crm.dto.enums.UserGroup
import ru.arty_bikini.crm.dto.other.NoteDTO
import ru.arty_bikini.crm_frontend.ClientCore
import ru.arty_bikini.crm_frontend.ClientProps
import ru.arty_bikini.crm_frontend.ui.bootstrap.*
import ru.arty_bikini.crm_frontend.ui.input.form.UploadImagePanel
import ru.arty_bikini.crm_frontend.ui.input.panel.InputPanel
import ru.arty_bikini.crm_frontend.ui.md.MarkdownComponent
import ru.arty_bikini.crm_frontend.ui.root.tryFC
import ru.arty_bikini.crm_frontend.util.*

external interface NoteComponentProps : ClientProps {
    var hintKey: String
    var asBadge: Boolean
    var noWrap: Boolean
}

private val NoteComponent = tryFC<NoteComponentProps> { props ->

    val notes = useCache(props.client.cache.notes)

    val note = notes.find { it.path == props.hintKey }

    if (props.client.settings.showDisabledHints == false && note == null) {
        return@tryFC
    }

    dropdown(
        color = BootstrapColor.INFO,
        buttonTitle = {
            if (props.asBadge) {
                badge(BootstrapColor.LIGHT, "?")
            } else {
                +"?"
            }
        },
        buttonAsDiv = props.asBadge,
        noWrap = props.noWrap,
        menu = {

            if (note?.file != null) {
                img {
                    src = note.file?.location
                }
                dropdownDivider()
            }
            div {
                css {
                    minWidth = min(max(200.px, 50.vw), 95.vw)
                    minHeight = 200.px
                    maxWidth = 95.vw

                    overflowX = Auto.auto
                }
                className = className + ClassName("p-3 fw-normal")

                MarkdownComponent(props.client, note?.content ?: "__Не заполнено__", listOfNotNull(note?.file))
            }
            if (props.client.auth.currentUser?.group == UserGroup.ADMIN) {
                dropdownDivider()

                div {
                    className = ClassName("card m-2 mb-0")

                    div {
                        className = ClassName("card-body p-2 vstack gap-3")
                        InputPanel {
                            val content = addTextArea("MD")

                            onSave {
                                console.log(get(content))

                                val contentValue = get(content) ?: return@onSave

                                val updated = note?.clone(NoteDTO.serializer()) ?: NoteDTO()

                                updated.path = props.hintKey

                                updated.content = contentValue

                                if (contentValue.isBlank()) {
                                    updated.content = null
                                }

                                GlobalScope.launch {
                                    props.client.network.dict.editOrAddNote(updated)

                                    props.client.cache.invalidateAll()
                                }
                            }

                            onCancel("Копировать") {
                                window.navigator.clipboard.writeText(note?.content ?: "")
                            }
                        }

                        UploadImagePanel {
                            client = props.client
                            link = { file ->

                                val updated = note?.clone(NoteDTO.serializer()) ?: NoteDTO()

                                updated.path = props.hintKey

                                updated.file = file

                                val result = props.client.network.dict.editOrAddNote(updated)

                                props.client.cache.invalidateAll()

                                result
                            }

                        }

                        if (note?.file != null) {
                            button {
                                className = BootstrapButton.outline(BootstrapColor.DANGER)

                                +"Удалить файл"

                                onClick = {

                                    val updated = note.clone(NoteDTO.serializer())

                                    updated.path = props.hintKey

                                    updated.file = null

                                    GlobalScope.launch {
                                        props.client.network.dict.editOrAddNote(updated)

                                        props.client.cache.invalidateAll()
                                    }
                                }

                            }
                        }

                    }
                }
            }
        }
    )
}

fun ChildrenBuilder.note(client: ClientCore, hintKey: String, asBadge: Boolean = false, noWrap: Boolean = false) {
    NoteComponent {
        this.client = client
        this.hintKey = hintKey
        this.asBadge = asBadge
        this.noWrap = noWrap
    }
}
