package ru.arty_bikini.crm_frontend.util

import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import react.useEffect
import react.useEffectOnce
import react.useState
import ru.arty_bikini.crm_frontend.SimpleCache
import kotlin.properties.ReadWriteProperty
import kotlin.reflect.KProperty

fun <T> useCache(cache: SimpleCache<T>): T {
    val (get, set) = useState<T>(cache.get())

    useEffectOnce {
        val handlerId = cache.subscribe { set(cache.get()) }

        cleanup {
            cache.unsubscribe(handlerId)
        }
    }

    return get
}

fun <T : Any> useAutoReload(get: suspend () -> List<T>?): List<T> {
    return useAutoReload(emptyList(), get)
}

fun <T : Any> useAutoReload(default: T, get: suspend () -> T?): T {

    var data: T by useState { default }

    var active = true

    useEffectOnce {
        GlobalScope.launch {
            while (active) {

                data = get() ?: default

                delay(1_000)
            }
        }

        cleanup {
            active = false
        }
    }

    return data
}

fun useIncrementor(): Incrementor {
    val (value, set) = useState(1)

    return Incrementor(value) { set { it + 1 } }
}

class Incrementor(val value: Int, val increment: () -> Unit) : ReadWriteProperty<Any?, Int> {
    override fun getValue(thisRef: Any?, property: KProperty<*>): Int {
        return value
    }

    override fun setValue(thisRef: Any?, property: KProperty<*>, value: Int) {
        increment()
    }
}

fun <T> usePersistent(value: T, track: Boolean = true): ReadWriteProperty<Any?, T> {
    val storage by useState<Array<T>>({ arrayOf(value) })

    if (track) {
        useEffect(value) {
            storage[0] = value
        }
    }

    return object : ReadWriteProperty<Any?, T> {
        override fun getValue(thisRef: Any?, property: KProperty<*>): T {
            return storage[0]
        }

        override fun setValue(thisRef: Any?, property: KProperty<*>, value: T) {
            storage[0] = value
        }
    }
}