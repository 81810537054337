package ru.arty_bikini.crm_frontend.event

abstract class Event<E : Event<E>>(val type: EventTypeId<E>)

interface EventHandler<E : Event<E>> {
    fun handle(event: E)

    companion object {
        operator fun <E : Event<E>> invoke(handler: (E) -> Unit): EventHandler<E> {
            return object : EventHandler<E> {
                override fun handle(event: E) {
                    handler(event)
                }
            }
        }
    }
}