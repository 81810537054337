package ru.arty_bikini.crm_frontend.ui.input.panel

import ru.arty_bikini.crm_frontend.util.StringUtils
import kotlin.js.Date


class InputPanelValueKey<T>(val id: Int) {
    override fun equals(other: Any?): Boolean {
        if (this === other) return true
        if (other == null || this::class.js != other::class.js) return false

        other as InputPanelValueKey<*>

        if (id != other.id) return false

        return true
    }

    override fun hashCode(): Int {
        return id
    }
}

class InputPanelItem<T : Any>(
    val key: InputPanelValueKey<T?>,
    val name: String,
    val type: InputPanelItemType<T>,
    val defaultValue: T?,
    val defaultValueAsString: String?,
    val postfix: String?
)

sealed class InputPanelItemType<T> {
    object TEXT : InputPanelItemType<String>()
    object TEXT_AREA : InputPanelItemType<String>()
    object INT : InputPanelItemType<Int>()
    object DATE : InputPanelItemType<Date>()
    object PASSWORD : InputPanelItemType<String>()
    object PHONE : InputPanelItemType<String>()
}

class InputPanelDSL {

    private var nextId = 1

    val items = ArrayList<InputPanelItem<*>>()
    var onSave: (SaveEvent) -> Unit = {}
        private set

    var saveButtonText: String = "Сохранить"

    var onCancel: ((SaveEvent) -> Unit)? = null
        private set

    var cancelButtonText: String = "Отмена"

    fun addText(name: String, defaultValue: String? = null, postfix: String? = null): InputPanelValueKey<String?> {
        val key = InputPanelValueKey<String?>(nextId++)

        items.add(InputPanelItem(key, name, InputPanelItemType.TEXT, defaultValue, defaultValue, postfix))

        return key
    }

    fun addTextArea(name: String, defaultValue: String? = null, postfix: String? = null): InputPanelValueKey<String?> {
        val key = InputPanelValueKey<String?>(nextId++)

        items.add(InputPanelItem(key, name, InputPanelItemType.TEXT_AREA, defaultValue, defaultValue, postfix))

        return key
    }

    fun addInt(name: String, defaultValue: Int? = null, postfix: String? = null): InputPanelValueKey<Int?> {
        val key = InputPanelValueKey<Int?>(nextId++)

        items.add(InputPanelItem(key, name, InputPanelItemType.INT, defaultValue, defaultValue?.toString(), postfix))

        return key
    }

    fun addDate(name: String, defaultValue: Date? = null, postfix: String? = null): InputPanelValueKey<Date?> {
        val key = InputPanelValueKey<Date?>(nextId++)

        items.add(InputPanelItem(
            key,
            name,
            InputPanelItemType.DATE,
            defaultValue,
            defaultValue?.toISOString()?.split("T")?.first(),
            postfix
        ))

        return key
    }

    fun addPhone(name: String, defaultValue: String? = null, postfix: String? = null): InputPanelValueKey<String?> {
        val key = InputPanelValueKey<String?>(nextId++)

        items.add(InputPanelItem(key, name, InputPanelItemType.PHONE, StringUtils.printPhoneOrText(defaultValue, force = true), defaultValue, postfix))

        return key
    }

    fun addPassword(name: String, defaultValue: String? = null): InputPanelValueKey<String?> {
        val key = InputPanelValueKey<String?>(nextId++)

        items.add(InputPanelItem(key, name, InputPanelItemType.PASSWORD, defaultValue, defaultValue, null))

        return key
    }

    fun onSave(title: String? = null, action: SaveEvent.() -> Unit) {
        if (title != null) {
            saveButtonText = title
        }

        onSave = action
    }

    fun onCancel(title: String? = null, action: SaveEvent.() -> Unit) {
        if (title != null) {
            cancelButtonText = title
        }

        onCancel = action
    }

}

class SaveEvent(val data: Map<InputPanelValueKey<*>, *>) {
    fun <T> get(key: InputPanelValueKey<T>): T {
        return data.get(key) as T
    }
}
