package ru.arty_bikini.crm_frontend.ui.root

import csstype.*
import emotion.react.css
import react.FC
import react.dom.html.ReactHTML.div
import ru.arty_bikini.crm_frontend.ClientProps

external interface PageWrapperProps : ClientProps {
    var page: FC<ClientProps>
}

val PageWrapper = tryFC<PageWrapperProps> { props ->
    div {
        css {
            height = "calc(100vh - 50px)".unsafeCast<Length>()
            minHeight = "calc(100vh - 50px)".unsafeCast<Length>()
            maxHeight = "calc(100vh - 50px)".unsafeCast<Length>()

            overflow = Auto.auto
        }

        props.page {
            this.client = props.client
        }
    }
}