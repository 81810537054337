package ru.arty_bikini.crm_frontend.ui.root

import kotlinx.browser.window
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import react.dom.html.ReactHTML
import react.useState
import ru.arty_bikini.crm_frontend.ClientProps
import ru.arty_bikini.crm_frontend.ui.bootstrap.Bootstrap
import ru.arty_bikini.crm_frontend.ui.bootstrap.colMd
import ru.arty_bikini.crm_frontend.ui.bootstrap.container
import ru.arty_bikini.crm_frontend.ui.bootstrap.row
import ru.arty_bikini.crm_frontend.ui.input.panel.InputPanel
import ru.arty_bikini.crm_frontend.util.plus

external interface LoginComponentProps : ClientProps {
    var onLoggedIn: () -> Unit
}

val LoginComponent = tryFC<LoginComponentProps> { props ->

    var allowTextLogin: Boolean by useState(true)

    container {
        row(Bootstrap.Utils.justifyContentCenter) {
            colMd(4) {
                ReactHTML.div {
                    className = Bootstrap.card + Bootstrap.Utils.m4 + Bootstrap.Utils.shadow

                    ReactHTML.div {
                        className = Bootstrap.cardHeader

                        +"Войдите, пожалуйста"
                    }

                    ReactHTML.div {

                        className = Bootstrap.cardBody

                        InputPanel {
                            val login = when {
                                allowTextLogin -> {
//                                    addPhone("Нолики")
                                    addText("Логин")
                                }
                                else -> addPhone("Логин")
                            }
                            val password = addPassword("Пароль")

                            onSave("Войти") {

                                val login = get(login)?.trim()
                                val password = get(password)?.trim()

                                if (login == "70000000000") {
                                    allowTextLogin = true
                                    return@onSave
                                }

                                if (login == null || password == null) {
                                    return@onSave
                                }

                                GlobalScope.launch {
                                    val result = props.client.auth.tryLogin(login, password)

                                    if (!result) {
                                        window.alert("Error!")
                                    }

                                    if (result) {
                                        props.onLoggedIn()
                                    }
                                }
                            }
                        }
                    }


                }
            }
        }
    }
}
