package ru.arty_bikini.crm_frontend.form.main

import csstype.ClassName
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import react.dom.html.ReactHTML
import ru.arty_bikini.crm.dto.enums.OrderFileCategory
import ru.arty_bikini.crm.dto.orders.OrderDTO
import ru.arty_bikini.crm_frontend.measure.MeasureType
import ru.arty_bikini.crm_frontend.measure.SpecialMeasure
import ru.arty_bikini.crm_frontend.ui.bootstrap.*
import ru.arty_bikini.crm_frontend.ui.input.form.FormInput
import ru.arty_bikini.crm_frontend.ui.input.form.addSpecial
import ru.arty_bikini.crm_frontend.ui.input.form.addSpecialWithInput
import ru.arty_bikini.crm_frontend.ui.input.table.FieldFlag
import ru.arty_bikini.crm_frontend.ui.root.tryFC
import ru.arty_bikini.crm_frontend.util.*

val MeasuresFormPart = tryFC<FormPartProps> { props ->

    val productTypes = useCache(props.client.cache.productTypes)
    val strapsTypes = useCache(props.client.cache.strapsTypes)

    FormInput(props.client, props.order) {

        readOnly = props.readOnly

        onSave {
            props
                .client
                .network
                .order
                .saveOrder(it)
        }

        content { entity ->

            cardGreenCollapse("Мерки", ClassName("vstack gap-2"), defaultVisible = false) {

                colLg(12) {
                    inputGroup {
                        name("Комментарий от клиента")
                        addSpecial(FieldFlag.FromGoogleForms, MeasureType.SPECIAL, SpecialMeasure.COMENT_OF_CLIENT)
                    }
                }

                row {
                    colLg(6) {
                        inputGroup {
                            name("Категория")
                            hideIf(
                                { this.product?.name },
                                { addSpecial(FieldFlag.FromGoogleForms, MeasureType.SPECIAL, SpecialMeasure.CATEGORY_GOOGLE) },
                                { addSelect(FieldFlag.Nullable, productTypes, { it.name }, { this::product }) }
                            )
                        }
                    }
                    colLg(6) {
                        inputGroup {
                            name("Федерация")
                            addSpecialWithInput(FieldFlag.FromGoogleForms, MeasureType.SPECIAL, SpecialMeasure.FEDERATION)
                        }
                    }

                }
                row {
                    colLg(6) {
                        inputGroup {
                            name("Рост")
                            if (entity.dataGoogle == null) {
                                addSpecialWithInput(FieldFlag.FromGoogleForms, MeasureType.SPECIAL, SpecialMeasure.GROWTH)
                            } else {
                                addSpecial(FieldFlag.FromGoogleForms, MeasureType.SPECIAL, SpecialMeasure.GROWTH)
                            }
                        }
                    }
                    colLg(6) {
                        inputGroup {
                            name("Сколько кг сольет")
                            addSpecialWithInput(FieldFlag.FromGoogleForms, MeasureType.SPECIAL, SpecialMeasure.KILO)
                        }
                    }

                }

                row {
                    colSm(6) {
                        inputGroup {
                            name("Лямки:")
                            hideIf(
                                { this.design().glue?.displayName },
                                { addSpecial(FieldFlag.FromGoogleForms, MeasureType.SPECIAL, SpecialMeasure.CONECTOR_WERE)},
                                { addSelect(FieldFlag.Nullable, strapsTypes, { it.name.toString() }) { this.design()::straps }}
                            )
                        }
                    }


                    colSm(6) {
                        inputGroup {
                            name("Конекторы:")
                            addSpecialWithInput(FieldFlag.FromGoogleForms, MeasureType.SPECIAL, SpecialMeasure.CONECTOR)
                        }
                    }
                }

                MeasuresForm {
                    this.client = props.client
                    this.order = props.order
                    this.readOnly = props.readOnly
                }

                addImages(OrderFileCategory.MEASURES)

                row {
                    colSm(4) {
                        inputGroup {
                            name("Подтвердить")
                            with(cb) {
                                ReactHTML.button {

                                    if (entity.statusInfo().isMeasureAll) {
                                        className = BootstrapButton.painted(BootstrapColor.SUCCESS)

                                        disabled = true

                                        +"ОК"
                                    } else {
                                        className = BootstrapButton.painted(BootstrapColor.WARNING)

                                        +"Подтверждаю"

                                        disabled = props.readOnly
                                        if (props.readOnly) return@button

                                        onClick = {

                                            val updated = entity.clone(OrderDTO.serializer())

                                            updated.statusInfo().isMeasureAll = true

                                            GlobalScope.launch {
                                                props.client
                                                    .network
                                                    .order
                                                    .saveOrder(updated)
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    colSm(4) {
                        inputGroup {
                            name("мерки заполнил:")
                            addUser { this.statusInfo().measureBy }
                        }
                    }
                    colSm(4) {
                        inputGroup {
                            name("Таня Мерки")
                            with(cb) {

                                ReactHTML.button {
                                    if (entity.statusInfo().isMeasureAllTanya) {
                                        className = BootstrapButton.painted(BootstrapColor.SUCCESS)

                                        disabled = true

                                        +"ОК"
                                    } else {
                                        className = BootstrapButton.painted(BootstrapColor.WARNING)

                                        +"Подтверждаю"

                                        disabled = props.readOnly
                                        if (props.readOnly) return@button

                                        onClick = {

                                            val updated = entity.clone(OrderDTO.serializer())

                                            updated.statusInfo().isMeasureAllTanya = true

                                            GlobalScope.launch {
                                                props.client
                                                    .network
                                                    .order
                                                    .saveOrder(updated)
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

        }
    }
}
