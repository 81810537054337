package ru.arty_bikini.crm_frontend.ui.input.table.data

import ru.arty_bikini.crm_frontend.ui.input.table.ColumnStateHandler
import ru.arty_bikini.crm_frontend.ui.input.table.TablePanelEntity

data class DataSource<T : Any> constructor(

    val state: DataSourceLoadingState,
    val currentData: List<TablePanelEntity<T>>,

    val setSort: (ColumnStateHandler<T>) -> Unit,
    val clientSideSorting: Boolean
) {

    fun groupIf(rule: (T, T) -> Boolean, merge: (List<T>) -> T?): DataSource<T> {

        val grouped = ArrayList<TablePanelEntity<T>>()

        val currentGroup = ArrayList<TablePanelEntity<T>>()

        currentData.forEach { curr ->
            if (currentGroup.isEmpty()) {
                currentGroup.add(curr)
            } else {
                val prev = currentGroup.last()
                val needMerge = rule(prev.entity, curr.entity)
                if (needMerge) {

                    currentGroup.add(curr)

                } else {
                    val merged = merge(currentGroup.map { it.entity }) ?: return@forEach

                    val removed = currentGroup.any { it.removed }
                    val new = currentGroup.any { it.new }
                    val updated = currentGroup.any { it.updated } || (new && removed)

                    val mergedEntity = TablePanelEntity(
                        entity = merged,
                        new = !updated && new,
                        updated = updated,
                        removed = !updated && removed,
                    )

                    grouped.add(mergedEntity)
                    currentGroup.clear()
                    currentGroup.add(curr)
                }
            }
        }

        if (currentGroup.isNotEmpty()) {

            val merged = merge(currentGroup.map { it.entity })

            if (merged != null) {

                val removed = currentGroup.any { it.removed }
                val new = currentGroup.any { it.new }
                val updated = currentGroup.any { it.updated } || (new && removed)

                val mergedEntity = TablePanelEntity(
                    entity = merged,
                    new = !updated && new,
                    updated = updated,
                    removed = !updated && removed,
                )

                grouped.add(mergedEntity)
            }
        }

        return copy(currentData = grouped)
    }
}

enum class DataSourceLoadingState {
    LOADING,
    LOADED,
    ERROR,
}
