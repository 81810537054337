package ru.arty_bikini.crm.dto.packet.auth

import kotlinx.serialization.Serializable
import ru.arty_bikini.crm.dto.SessionDTO
import ru.arty_bikini.crm.dto.packet.BaseResponse

//ответ для:возвращает всех пользователей
@Serializable
class GetSessionsResponse(
    override val ok: Boolean,
    override val statusCode: String,
    override val displayMessage: String?,

    val users: List<SessionDTO>? = null,  //пользователи без паролей
) : BaseResponse()
