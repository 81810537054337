package ru.arty_bikini.crm_frontend.ui.input.table

import csstype.ClassName
import react.ChildrenBuilder
import react.dom.html.ReactHTML.div
import ru.arty_bikini.crm.dto.orders.OrderDTO
import ru.arty_bikini.crm.dto.orders.google.OrderDataTypeDTO
import ru.arty_bikini.crm_frontend.ClientProps
import ru.arty_bikini.crm_frontend.SimpleCache
import ru.arty_bikini.crm_frontend.measure.MeasureType
import ru.arty_bikini.crm_frontend.ui.input.table.data.DataSource
import ru.arty_bikini.crm_frontend.ui.input.types.*
import ru.arty_bikini.crm_frontend.ui.modal.ModalConfig

external interface TablePanelProps<T : Any> : ClientProps {

    var hintId: String

    var data: DataSource<T>

    var getId: (T) -> String

    var save: suspend (T) -> Unit

    var addNew: (suspend (String) -> Unit)?

    var columns: List<TablePanelColumnProps<T, *>>

    var cache: List<SimpleCache<*>>
}


fun <T : Any> TablePanelProps<T>.addRaw(title: String, options: TableColumnRenderOptions<T> = TableColumnRenderOptions(), raw: ChildrenBuilder.(T) -> Unit, ) {
    addText(FieldFlag.ReadOnlyValue, title, options.copy(extraContent = {
        raw(this, it)
        options.extraContent(this, it)
    })) { "" }
}

fun <T : Any> TablePanelProps<T>.addForm(title: String, form: ModalConfig<T>, options: TableColumnRenderOptions<T> = TableColumnRenderOptions()) {
    addButton(title, options) { client.event.showModal(form, it) }
}

fun <T : Any> TablePanelProps<T>.addButton(title: String, options: TableColumnRenderOptions<T> = TableColumnRenderOptions<T>(), text: (T) -> String = { title }, onClick: (T) -> Unit) {
    columns = columns + TablePanelColumnProps(
        title = title,
        type = TablePanelColumnType.BUTTON,
        allowNull = false,
        readOnly = false,
        options = options,
        prop = PropertyReference.anonymous({ text(it) }, { entity, value -> onClick(entity) }),
        variants = null,
        converter = StringValueConverter
    )

}

fun <T : Any> TablePanelProps<T>.addDropdown(title: String, options: TableColumnRenderOptions<T> = TableColumnRenderOptions<T>(), menu: ChildrenBuilder.(T) -> Unit) {
    columns = columns + TablePanelColumnProps(
        title = title,
        type = TablePanelColumnType.BUTTON_DROPDOWN,
        allowNull = false,
        readOnly = false,
        options = options.copy(extraClasses = { ClassName("dropdown") }, extraContent = {
            div {

                className = ClassName("dropdown-menu")

                menu(this, it)
            }
            options.extraContent(this, it)
        }),
        prop = PropertyReference.readOnlyAnonymous { title },
        variants = null,
        converter = StringValueConverter
    )

}

val <T : Any> TablePanelProps<T>.addText
    get() = InputTableColumnBuilder(TablePanelColumnType.TEXT_LINE, this, StringValueConverter)

val <T : Any> TablePanelProps<T>.addInt
    get() = InputTableColumnBuilder(TablePanelColumnType.INT, this, IntValueConverter)

val <T : Any> TablePanelProps<T>.addCost
    get() = InputTableColumnBuilder(TablePanelColumnType.INT, this, CostValueConverter)

val <T : Any> TablePanelProps<T>.addPhone
    get() = InputTableColumnBuilder(TablePanelColumnType.TEXT_LINE, this, PhoneValueConverter)

val <T : Any> TablePanelProps<T>.addBoolean
    get() = InputTableColumnBuilder(TablePanelColumnType.CHECKBOX, this, BooleanValueConverter)

val <T : Any> TablePanelProps<T>.addDate
    get() = InputTableColumnBuilder(TablePanelColumnType.DATE, this, DateValueConverter)

val <T : Any> TablePanelProps<T>.addDateShort
    get() = InputTableColumnBuilder(TablePanelColumnType.DATE, this, ShortDateValueConverter)

val <T : Any> TablePanelProps<T>.addDateTime
    get() = InputTableColumnBuilder(TablePanelColumnType.DATE_TIME, this, DateTimeValueConverter)

val <T : Any> TablePanelProps<T>.addTextArea
    get() = InputTableColumnBuilder(TablePanelColumnType.TEXT_AREA, this, StringValueConverter)

val <T : Any> TablePanelProps<T>.addSelect
    get() = SelectTableColumnBuilder(this)

val <T : Any> TablePanelProps<T>.addEntity
    get() = SelectTableColumnBuilder(this)

val <T : Any> TablePanelProps<T>.addMultiEntity
    get() = MultiSelectTableColumnBuilder(this)

fun <Extra : Enum<Extra>> TablePanelProps<OrderDTO>.addSpecial(
    flag: FieldFlag.FromGoogleForms,
    title: String,
    type: MeasureType<Extra>,
    special: Extra,
    options: TableColumnRenderOptions<OrderDTO> = TableColumnRenderOptions(),
) {
    val orderDataTypes = client.cache.orderDataTypes

    cache = cache + orderDataTypes

    val column: () -> OrderDataTypeDTO? = {
        orderDataTypes
            .get()
            .find { it.displayCategory == type.category && it.displayPosition == special.name }
    }

    columns = columns + TablePanelColumnProps<OrderDTO, String?>(
        cache = orderDataTypes,
        title = title,
        type = TablePanelColumnType.TEXT_LINE,
        allowNull = true,
        readOnly = true,
        options = options,
        prop = PropertyReference.anonymous(
            getter = { it.dataGoogle?.data?.get(column()?.id) },
            setter = { entity, v ->
                val column = column()
                if (column == null) return@anonymous

                entity.dataGoogle?.let {
                    val data = it.data?.toMutableMap() ?: HashMap()

                    if (v == null) {
                        data.remove(column.id)
                    } else {
                        data[column.id] = v
                    }

                    it.data = data
                }
            },
        ),
        variants = null,
        converter = StringValueConverter
    )

}
