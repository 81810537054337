package ru.arty_bikini.crm_frontend

import kotlinx.browser.localStorage
import kotlinx.serialization.builtins.serializer
import ru.arty_bikini.crm.dto.UserDTO

class AuthModule(val core: ClientCore) {

    private var sessionToken: String? by localStorage(String.serializer())

    var currentUser: UserDTO? = null
        private set

    fun getSessionToken(): String? {
        return sessionToken
    }

    suspend fun tryReconnect(): Boolean {

        if (sessionToken == null) {
            return false
        }

        val response = core.network
            .auth
            .reconnect()

        if (response == null) {
            return false
        }

        if (response.ok) {
            currentUser = response.user
            return true
        }

        currentUser = null
        sessionToken = null

        return false

    }

    suspend fun tryLogin(login: String, password: String): Boolean {

        val result = core.network.auth.login(login, password)

        if (result == null || result.accessCode == null) {
            println("Login error: ${result?.statusCode}")

            currentUser = null
            return false
        }

        sessionToken = result.accessCode
        currentUser = result.user

        return true

    }
}
