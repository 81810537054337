package ru.arty_bikini.crm_frontend.table.admin

import kotlinx.browser.window
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import react.useEffectOnce
import react.useState
import ru.arty_bikini.crm.dto.UserDTO
import ru.arty_bikini.crm.dto.enums.UserGroup
import ru.arty_bikini.crm_frontend.table.common.commonPage
import ru.arty_bikini.crm_frontend.ui.bootstrap.BootstrapColor
import ru.arty_bikini.crm_frontend.ui.bootstrap.badge
import ru.arty_bikini.crm_frontend.ui.input.table.*

val UserListPage = commonPage(
    requireGroups = listOf(UserGroup.ADMIN, UserGroup.TANYA)
) { props ->

    var users by useState { emptyList<UserDTO>() }

    fun update() {
        GlobalScope.launch {
            users = props
                .client
                .network
                .auth
                .getUsers()
                ?.users
                ?.toList()
                ?: emptyList()
        }
    }

    useEffectOnce {

        update()

    }

    fun disabledRules(text: String? = null, forceDisableForAdmin: Boolean = false): TableColumnRenderOptions<UserDTO> {
        return TableColumnRenderOptions<UserDTO>(
            disabledIf = rules@{
                if (it.id == 1 && forceDisableForAdmin) {
                    return@rules EnabledResult(false, text)
                }
                if (it.id == 1 && props.client.auth.currentUser?.id == 1) {
                    return@rules EnabledResult(true)
                }
                if (it.id == 1 || it.id == props.client.auth.currentUser?.id) {
                    return@rules EnabledResult(false, text)
                }
                if (it.group == UserGroup.ADMIN && props.client.auth.currentUser?.group != UserGroup.ADMIN) {
                    return@rules EnabledResult(false, text)
                }
                if (it.group == UserGroup.TANYA && props.client.auth.currentUser?.group != UserGroup.ADMIN) {
                    return@rules EnabledResult(false, text)
                }
                return@rules EnabledResult(true)
            }
        )
    }
    val visibleGroups: List<UserGroup> = when (props.client.auth.currentUser?.group) {
        null -> emptyList()
        UserGroup.ADMIN -> UserGroup.values().toList()
        UserGroup.TANYA -> UserGroup.values().filter { it != UserGroup.ADMIN && it != UserGroup.TANYA }
        else -> emptyList()
    }

    TablePanelEntityAuto(props.client, "t-s-user", users, UserDTO.serializer()) {

        save = {


            console.log("Save handler")
            console.log(it)

            val result = props.client
                .network
                .auth
                .editUser(it)

            if (result != null) {
                users = result.users ?: emptyList()
            }


        }

        addNew = {
            props.client
                .network
                .auth
                .editUser(UserDTO().apply {
                    login = it
                    name = it
                })
        }

        addText(
            FieldFlag.Nullable,
            "ФИО",
            UserDTO::name,
            disabledRules().copy(extraContent = { if (it.id == props.client.auth.currentUser?.id) badge(BootstrapColor.SUCCESS, "Я") })
        )
        addText(FieldFlag.Nullable, "Логин/Телефон", UserDTO::login, disabledRules(forceDisableForAdmin = true))
        addSelect(
            flag = FieldFlag.Nullable,
            title = "Группа",
            values = visibleGroups.toTypedArray(),
            options = disabledRules(null),
            prop = UserDTO::group
        )
        addInt(FieldFlag.NotNull, "Сортировка", UserDTO::priority, options = TableColumnRenderOptions(defaultSortLegacy = true))
        addBoolean(
            FieldFlag.NotNull,
            "Показать",
            UserDTO::visible,
            options = TableColumnRenderOptions(disabledIf = { EnabledResult(it.group != UserGroup.ADMIN) })
        )
        addText(FieldFlag.Nullable, "Специализация (комментарий)", UserDTO::specialisation)
        addText(FieldFlag.Nullable, "Продуктивность (комментарий)", UserDTO::productivityComment)
        addInt(FieldFlag.ReadOnlyProp, "Продуктивность TEST", UserDTO::baseProductivity)
        addText(FieldFlag.ReadOnlyValue, "Продуктивность TEST") { this.productivity?.map { it.key.displayName + " = " + it.value }?.joinToString(",") }

        addButton(
            title = "Сбросить пароль",
            options = disabledRules("Не доступно"),
            onClick = {
                GlobalScope.launch {
                    props.client
                        .network
                        .auth
                        .changePassword(it)
                        ?.let {
                            window.alert(it.password ?: "???")
                        }
                }
            }
        )
    
    
    
    }


}

