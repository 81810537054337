package ru.arty_bikini.crm_frontend.table.production

import csstype.ClassName
import react.dom.html.ReactHTML.div
import ru.arty_bikini.crm.dto.enums.UserGroup
import ru.arty_bikini.crm.dto.enums.WorkProgress
import ru.arty_bikini.crm.dto.enums.personalData.ClientType
import ru.arty_bikini.crm.dto.orders.OrderDTO
import ru.arty_bikini.crm.dto.orders.PersonalDataDTO
import ru.arty_bikini.crm_frontend.ClientProps
import ru.arty_bikini.crm_frontend.form.button.ButtonArchiveForm
import ru.arty_bikini.crm_frontend.form.button.ButtonPrePaymentForm
import ru.arty_bikini.crm_frontend.form.main.MainForm
import ru.arty_bikini.crm_frontend.measure.MeasureType
import ru.arty_bikini.crm_frontend.measure.SpecialMeasure
import ru.arty_bikini.crm_frontend.table.report.trainerDiscount
import ru.arty_bikini.crm_frontend.ui.bootstrap.BootstrapColor
import ru.arty_bikini.crm_frontend.ui.bootstrap.badge
import ru.arty_bikini.crm_frontend.ui.input.form.getMeasure
import ru.arty_bikini.crm_frontend.ui.input.table.*
import ru.arty_bikini.crm_frontend.ui.input.types.or
import ru.arty_bikini.crm_frontend.ui.root.tryFC
import ru.arty_bikini.crm_frontend.util.*
import kotlin.math.min

val ClientTable = tryFC<ClientProps> { props ->

    val workTypes = useCache(props.client.cache.workTypes)
    val orderDataTypes = useCache(props.client.cache.orderDataTypes)

    val orders = useAutoReload<OrderDTO> {
        props
            .client
            .network
            .order
            .getClients()
            ?.orders
    }


    TablePanelOrderAuto(props.client, "t-production-client", orders) {

        addNew = {
            props.client
                .network
                .order
                .addOrder(it, ClientType.LEAD)
                ?.orderr
                ?.let {
                    ButtonPrePaymentForm.openNow(props.client, it)
                }
        }

        addText(
            flag = FieldFlag.ReadOnlyProp,
            title = "ФИО",
            value = OrderDTO::name,
            options = TableColumnRenderOptions(
                editButtonForm = MainForm,
                maxWidthPx = 200,
                extraContent = { entity ->
                    val types = listOf(UserGroup.SEAMSTRESS, UserGroup.GLUER)

                    val hasProduct = entity.product != null
                    val hasGoogle = entity.dataGoogle != null
                    val hasDataDesign = entity.statusInfo().isDesignAll
                    val hasDataMeasure = entity.statusInfo().isMeasureAll
                    val hasData = hasDataDesign && hasDataMeasure
                    val hasConfirmDesign = entity.statusInfo().isDesignAllTanya
                    val hasConfirmMeasure = entity.statusInfo().isMeasureAllTanya
                    val hasConfirm = hasConfirmDesign && hasConfirmMeasure
                    val needWork =
                        types.any { group ->
                            workTypes
                                .filterBy(group, entity)
                                .any { type ->
                                    entity.worksTMP.none { it.user?.group == group && it.works?.contains(type) == true }
                                }
                        }

                    div {
                        this.className = ClassName("hstack gap-1 mt-1 align-items-stretch")

                        div {
                            this.className = ClassName("w-50 vstack gap-1 align-items-stretch")

                            when {
                                needWork -> {
                                    badge(
                                        color = when {
                                            hasData && hasConfirm -> BootstrapColor.DANGER
                                            else -> BootstrapColor.SECONDARY
                                        },
                                        text = "Нет работ",
                                        extraClass = ClassName("text-wrap"),
                                    )
                                }

                                !hasProduct -> {
                                    badge(
                                        color = BootstrapColor.DANGER,
                                        text = "Нет категории",
                                        extraClass = ClassName("text-wrap"),
                                    )
                                }

                                else -> {
                                    badge(
                                        color = BootstrapColor.LIGHT,
                                        text = "Назначена",
                                        extraClass = ClassName("text-wrap"),
                                    )
                                }
                            }


                            when {
                                entity.works.isNullOrEmpty() -> {}
                                needWork && entity.works?.any { it.approved || it.progress != null } == true -> {
                                    badge(
                                        color = BootstrapColor.SECONDARY,
                                        text = "Начаты",
                                        extraClass = ClassName("text-wrap"),)
                                }
                                needWork -> {
                                    badge(
                                        color = BootstrapColor.SECONDARY,
                                        text = "Не начаты",
                                        extraClass = ClassName("text-wrap"),
                                    )
                                }
                                entity.works?.all { it.approved } == true -> {
                                    badge(
                                        color = BootstrapColor.PRIMARY,
                                        text = "Приняты",
                                        extraClass = ClassName("text-wrap"),
                                    )
                                }
                                entity.works?.all { it.approved || it.progress == WorkProgress.FINISHED } == true -> {
                                    badge(
                                        color = BootstrapColor.SUCCESS,
                                        text = "Сделала",
                                        extraClass = ClassName("text-wrap"),
                                    )
                                }
                                entity.works?.any { it.approved || it.progress != null } == true -> {
                                    badge(
                                        color = BootstrapColor.SECONDARY,
                                        text = "Начаты",
                                        extraClass = ClassName("text-wrap"),
                                    )
                                }
                                else -> {
                                    badge(
                                        color = BootstrapColor.SECONDARY,
                                        text = "Не начаты",
                                        extraClass = ClassName("text-wrap"),
                                    )
                                }
                            }
                        }
    
                        when {
                            hasGoogle && !hasProduct -> {
                                badge(
                                    color = BootstrapColor.DANGER,
                                    text = "Нет категории",
                                    extraClass = ClassName("w-50 text-wrap"),
                                )
                            }

                            hasData && hasConfirm -> {
                                badge(
                                    color = BootstrapColor.LIGHT,
                                    text = "Все проверено",
                                    extraClass = ClassName("w-50 text-wrap"),
                                )
                            }

                            hasData && !hasConfirm -> {
                                val extra = when {
                                    !hasConfirmDesign && !hasConfirmMeasure -> "Д + М";
                                    !hasConfirmDesign -> "Д";
                                    !hasConfirmMeasure -> "М";
                                    else -> "?"
                                }
                                badge(
                                    color = BootstrapColor.DANGER,
                                    text = "Подтвердить Тане ($extra)",
                                    extraClass = ClassName("w-50 text-wrap"),
                                )
                            }

                            hasGoogle -> {
                                val extra = when {
                                    !hasDataDesign && !hasDataMeasure -> "Дизайна и Мерок";
                                    !hasDataDesign -> "Дизайна";
                                    !hasDataMeasure -> "Мерок";
                                    else -> "?"
                                }
                                badge(
                                    color = BootstrapColor.DANGER,
                                    text = "Нет $extra",
                                    extraClass = ClassName("w-50 text-wrap"),
                                )
                            }

                            else -> {
                                badge(
                                    color = BootstrapColor.WARNING,
                                    text = "Нет ФЗ",
                                    extraClass = ClassName("w-50 text-wrap"),
                                )
                            }
                        }
                    }
                    
                    
                },
                specialSort = listOf<Pair<String, OrderDTO.() -> Comparable<*>?>>(
                    "по дате отправки" to { personalData().packageTime },
                    "по дате соревнований" to { this.getMeasure(orderDataTypes, MeasureType.SPECIAL, SpecialMeasure.COMPETITION_GOOGLE) },
                ).mapIndexed { idx, (name, column) ->

                    val default = compareBy<OrderDTO> {
                        when {
                            // packageTime // dataGoogle
                            it.personalData().packageTime == null && it.dataGoogle != null -> -5
                            it.personalData().packageTime != null -> 0
                            it.personalData().packageTime == null && it.dataGoogle == null -> 10
                            else -> 0
                        }
                    }
                        .thenBy { column(it) }

                    listOf(
                        "${idx * 3 + 1}. Назначить работу (сортируются $name)" to compareBy<OrderDTO> {
                            when {
                                it.hasAllWorks(workTypes, null) -> 100
                                else -> 0
                            }
                        }
                            .then(default),

                        "${idx * 3 + 2}. Подтвердить мерки (сортируются $name)" to compareBy<OrderDTO> {
                            when {
                                it.allOk -> 50
                                it.allFilled && !it.statusInfo().isMeasureAllTanya -> -200
                                it.allFilled -> -100
                                it.dataGoogle != null -> -50
                                else -> 0
                            }
                        }
                            .then(default),

                        "${idx * 3 + 3}. Подтвердить дизайны (сортируются $name)" to compareBy<OrderDTO> {
                            when {
                                it.allOk -> 50
                                it.allFilled && !it.statusInfo().isDesignAllTanya -> -200
                                it.allFilled -> -100
                                it.dataGoogle != null -> -50
                                else -> 0
                            }
                        }
                            .then(default),
                    )
                }
                    .flatMap { it }
                    .toMap()
            ),
        )

        /*
        * 1 Дата отправки: personalData : packageTime.
        * 2 Клиент: дата когда нужен: из гугла
        * 3 менеджер: дата когда нужен:  neededTime;
        * 4 клиент дата соревнований: из Гугла
        * 5 менеджер: дата соревнований PersonalDate competitionTime
        * 6 время доставки personalData deliveryTime
        * 7 дата заказа: personalData orderTime
        * 8 Дата поступления мерок: из гугла
        * */
        addDateShort(
            flag = FieldFlag.ReadOnlyProp,
            title = "Дата отправки",
            value = OrderDTO::personalData or ::PersonalDataDTO then PersonalDataDTO::packageTime,
            options = TableColumnRenderOptions(
                groupNextColumns = 5,
                disabledIf = {
                    when {
                        it.personalData().packageTime != null -> EnabledResult(true)
                        it.allFilled && it.dataGoogle == null -> EnabledResult(false, "Проставить отправку", overrideAsBadgeColor = BootstrapColor.DANGER, overrideAsBadgeWrap = true)
                        it.dataGoogle == null -> EnabledResult(false, "", overrideAsBadgeColor = BootstrapColor.LIGHT)
                        else -> EnabledResult(false, "Проставить отправку", overrideAsBadgeColor = BootstrapColor.DANGER, overrideAsBadgeWrap = true)
                    }
                }
            ),
        )//автоматически считает система
        addSpecial(FieldFlag.FromGoogleForms, "Дата когда нужен", MeasureType.SPECIAL, SpecialMeasure.NEEDED_GOOGLE)
        addSpecial(FieldFlag.FromGoogleForms, "Дата соревнования", MeasureType.SPECIAL, SpecialMeasure.COMPETITION_GOOGLE)
        addDate(FieldFlag.ReadOnlyProp, "Дата заказа", OrderDTO::personalData or ::PersonalDataDTO then PersonalDataDTO::orderTime)
        addInt(FieldFlag.ReadOnlyProp, "Время доставки", OrderDTO::personalData or ::PersonalDataDTO then PersonalDataDTO::deliveryTime, TableColumnRenderOptions(extraContent = { + " Дней" }))
        addDate(FieldFlag.ReadOnlyValue, "Дата поступления мерок") { this.dataGoogle?.dateGoogle }


        addText(
            FieldFlag.Nullable,
            "Комментарий",
            OrderDTO::personalData or ::PersonalDataDTO then PersonalDataDTO::comment
        )

        addText(FieldFlag.Nullable, "Предоплата", OrderDTO::personalData or ::PersonalDataDTO then PersonalDataDTO::prepayment)
        addCost(
            flag = FieldFlag.NotNull,
            title = "Цена",
            value = OrderDTO::personalData or ::PersonalDataDTO then PersonalDataDTO::payment,
            options = TableColumnRenderOptions(
                extraContent = { entity ->
                    val initialCost = entity.personalData().payment

                    val discount: Int = entity.trainerDiscount

                    if (discount > 0) {
                        val discountPercentReal = 100 * discount / initialCost

                        div {
                            className = ClassName("vstack gap-1 align-items-end mt-1")
                            badge(
                                color = if (discountPercentReal > 20) BootstrapColor.DANGER else BootstrapColor.SECONDARY,
                                text = "Скидка ($discountPercentReal%): ${StringUtils.printNumber(discount)}",
                            )
                            badge(
                                color = if (discountPercentReal > 20) BootstrapColor.DANGER else BootstrapColor.SECONDARY,
                                text = "Сумма со скидкой: ${StringUtils.printNumber(initialCost - discount)}",
                            )
                        }
                    }
                }
            )
        )
        addForm("Отправить в архив", ButtonArchiveForm)


        addText(
            flag = FieldFlag.ReadOnlyValue,
            title = "Статус",
            options = TableColumnRenderOptions(
                groupNextColumns = 3,
                extraContent = { entity ->

                    val clientColor: BootstrapColor = when {
                        entity.dataGoogle == null -> BootstrapColor.WARNING
                        else -> BootstrapColor.DANGER
                    }

                    div {
                        this.className = ClassName("vstack gap-1")

                        if (entity.statusInfo().isDesignAll) {
                            badge(BootstrapColor.LIGHT, "Дизайн: готов")
                        } else {
                            badge(clientColor, "Дизайн: Нет")
                        }

                        if (entity.statusInfo().isMeasureAll) {
                            badge(BootstrapColor.LIGHT, "Мерки: готовы")
                        } else {
                            badge(clientColor, "Мерки: Нет")
                        }

                        if (entity.statusInfo().isMeasureAllTanya && entity.statusInfo().isDesignAllTanya) {
                            badge(BootstrapColor.LIGHT, "Таня: проверила")
                        } else {
                            val extra = when {
                                !entity.statusInfo().isDesignAllTanya && !entity.statusInfo().isMeasureAllTanya -> "Д+М"
                                !entity.statusInfo().isDesignAllTanya -> "Д"
                                !entity.statusInfo().isMeasureAllTanya -> "М"
                                else -> "?"
                            }

                            if (entity.statusInfo().isDesignAll && entity.statusInfo().isMeasureAll) {
                                badge(BootstrapColor.DANGER, "Таня: Нет ($extra)")
                            } else {
                                badge(BootstrapColor.SECONDARY, "Таня: Нет ($extra)")
                            }
                        }
                    }
                },
                editButtonForm = MainForm
            ),
            value = { "" }
        )
        addButton(
            title = "Таня проверила",
            options = TableColumnRenderOptions(
                backgroundColor = { if (it.statusInfo().isMeasureAllTanya && it.statusInfo().isDesignAllTanya) BootstrapColor.SUCCESS else BootstrapColor.DANGER }
            ),
            text = { if (it.statusInfo().isMeasureAllTanya && it.statusInfo().isDesignAllTanya) "+" else "Проверить!" },
            onClick = MainForm.openAction(client)
        )
        addButton(
            title = "Мерки заполнены",
            options = TableColumnRenderOptions(
                backgroundColor = { if (it.statusInfo().isMeasureAll) BootstrapColor.SUCCESS else BootstrapColor.DANGER }
            ),
            text = { if (it.statusInfo().isMeasureAll) "+" else "Нет" },
            onClick = MainForm.openAction(client)
        )
        addButton(
            title = "Дизайн создан",
            options = TableColumnRenderOptions(
                backgroundColor = { if (it.statusInfo().isDesignAll) BootstrapColor.SUCCESS else BootstrapColor.DANGER }
            ),
            text = { if (it.statusInfo().isDesignAll) "+" else "Нет" },
            onClick = MainForm.openAction(client)
        )


    }


}
