package ru.arty_bikini.crm.dto

import kotlinx.serialization.Serializable

@Serializable
class SessionDTO : EntityDTO() {
    override var id: Int = 0

    var user: UserDTO? = null

    var start: Long? = null
    var renew: Long? = null

    var lastIp: String? = null

    var renewVersion: String? = null

}
