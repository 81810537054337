package ru.arty_bikini.crm_frontend.ui.input.table.data.month

import csstype.ClassName
import csstype.Length
import emotion.react.css
import react.Props
import react.dom.html.ReactHTML.div
import react.useEffectOnce
import ru.arty_bikini.crm_frontend.ui.bootstrap.BootstrapColor
import ru.arty_bikini.crm_frontend.ui.bootstrap.inputGroup
import ru.arty_bikini.crm_frontend.ui.root.tryFC
import ru.arty_bikini.crm_frontend.util.DateKt
import ru.arty_bikini.crm_frontend.util.DateUtils
import ru.arty_bikini.crm_frontend.util.StringUtils
import ru.arty_bikini.crm_frontend.util.plus

external interface MonthPagerComponentProps : Props {
    var setMonth: (DateKt) -> Unit

    var currentMonth: DateKt
}

val MonthPagerComponent = tryFC<MonthPagerComponentProps> { props ->

    useEffectOnce { props.setMonth(props.currentMonth) }

    fun goToPage(month: DateKt) {
        props.setMonth(month)
    }

    val rendered = ArrayList<DateKt>()

    rendered.add(props.currentMonth)

    div {
        className = className + ClassName("ms-3 mb-1 sticky-bottom")

        div {
            css {
                width = Length.fitContent
            }

            className = className + ClassName("card p-2")

            inputGroup {
                fun render(value: DateKt) {
//                    if (value < 0 || value > props.lastPage) return
                    if (rendered.any { it.sameMonth(value) }) return

                    rendered.add(value)
                    btn(BootstrapColor.SECONDARY, StringUtils.printLocalDate(value)) { goToPage(value) }
                }

                render(props.currentMonth.previousMonth.monthStart)
                value(StringUtils.printLocalDate(props.currentMonth.monthStart))
                render(props.currentMonth.nextMonth.monthStart)
                render(DateUtils.loaded.monthStart)
            }
        }
    }
}