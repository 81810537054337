package ru.arty_bikini.crm_frontend.form.main

import csstype.ClassName
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import react.dom.html.ReactHTML.button
import react.dom.html.ReactHTML.h4
import ru.arty_bikini.crm.dto.enums.UserGroup
import ru.arty_bikini.crm.dto.enums.design.GlueDesign
import ru.arty_bikini.crm.dto.enums.design.TextileOrder
import ru.arty_bikini.crm.dto.enums.personalData.ClientType
import ru.arty_bikini.crm.dto.orders.OrderDTO
import ru.arty_bikini.crm_frontend.measure.MeasureType
import ru.arty_bikini.crm_frontend.measure.SpecialMeasure
import ru.arty_bikini.crm_frontend.ui.bootstrap.*
import ru.arty_bikini.crm_frontend.ui.input.form.FormInput
import ru.arty_bikini.crm_frontend.ui.input.form.addSpecial
import ru.arty_bikini.crm_frontend.ui.input.table.FieldFlag
import ru.arty_bikini.crm_frontend.ui.modal.EntityTrackRule
import ru.arty_bikini.crm_frontend.ui.modal.createModal
import ru.arty_bikini.crm_frontend.util.*

val WorkerMainForm = createModal<OrderDTO>(
    title = { "Большая форма: $name (Сотрудник)" },
    trackEntity = EntityTrackRule.order()
) { props ->

    DesignFormPart {
        this.client = props.client
        this.order = props.entity
        this.readOnly = true
    }

    MeasuresFormPart {
        this.client = props.client
        this.order = props.entity
        this.readOnly = true
    }

    WorksFormPart {
        this.client = props.client
        this.order = props.entity
        this.readOnly = true
    }

    FilesFormPart {
        this.client = props.client
        this.order = props.entity
        this.readOnly = true
    }


}

