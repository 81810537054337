package ru.arty_bikini.crm_frontend.table.production.worker

import csstype.ClassName
import react.dom.html.ReactHTML.div
import ru.arty_bikini.crm.dto.enums.UserGroup
import ru.arty_bikini.crm_frontend.table.common.commonPage
import ru.arty_bikini.crm_frontend.table.production.ClientTable
import ru.arty_bikini.crm_frontend.table.production.ProductionTable

//Страница производства и клиентов (вариант сотрудников)
val WorkerProductionTablePage = commonPage(
    requireGroups = listOf(UserGroup.ADMIN, UserGroup.SEAMSTRESS, UserGroup.GLUER)
) { props ->

    div {

        className = ClassName("hstack gap-1 align-items-start")

        ProductionTable {
            this.client = props.client
        }

        WorkerClientTable {
            this.client = props.client
        }

    }

}
