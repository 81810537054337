package ru.arty_bikini.crm_frontend.ui.input.form

import csstype.ClassName
import csstype.px
import emotion.react.css
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import org.w3c.dom.HTMLInputElement
import org.w3c.files.get
import react.createRef
import react.dom.html.InputType
import react.dom.html.ReactHTML.button
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.input
import react.useState
import ru.arty_bikini.crm.dto.file.FileDTO
import ru.arty_bikini.crm_frontend.ClientProps
import ru.arty_bikini.crm_frontend.network.ServerResponse
import ru.arty_bikini.crm_frontend.network.ServerResponseStatus
import ru.arty_bikini.crm_frontend.ui.bootstrap.BootstrapButton
import ru.arty_bikini.crm_frontend.ui.bootstrap.BootstrapColor
import ru.arty_bikini.crm_frontend.ui.bootstrap.alert
import ru.arty_bikini.crm_frontend.ui.root.tryFC
import ru.arty_bikini.crm_frontend.util.plus

external interface UploadImageCardProps : ClientProps {
    var link: suspend (FileDTO) -> ServerResponse<*>
}

val UploadImagePanel = tryFC<UploadImageCardProps> { props ->

    var uploadState by useState(UploadState.NONE)

    val uploadRef = createRef<HTMLInputElement>()


    div {
        className = ClassName("vstack gap-3")

        if (uploadState != UploadState.NONE) {
            alert(uploadState.color) {
                +uploadState.message
            }
        }

        div {
            input {
                className = ClassName("form-control form-control-sm")
                type = InputType.file

                this.ref = uploadRef
            }
        }

        div {
            button {
                className = BootstrapButton.outline(BootstrapColor.PRIMARY)

                +"Загрузить"

                onClick = onClick@{

                    if (uploadState.inProgress) {
                        return@onClick
                    }

                    val file = uploadRef.current
                        ?.files
                        ?.get(0)

                    if (file == null) {
                        uploadState = UploadState.NOT_A_FILE
                        return@onClick
                    }

                    GlobalScope.launch {

                        try {
                            uploadState = UploadState.STARTED

                            val dto = props.client
                                .network
                                .file
                                .upload(file)
                                ?.fileDTO

                            if (dto == null) {
                                uploadState = UploadState.ERROR
                                return@launch
                            }

                            uploadState = UploadState.LINKING

                            val result = props.link(dto)

                            if (result.status == ServerResponseStatus.SUCCESS) {
                                uploadState = UploadState.COMPLETE
                            } else {
                                uploadState = UploadState.ERROR
                            }

                        } catch (e: Throwable) {
                            e.printStackTrace()
                        }
                    }

                }
            }
        }
    }

}
