package ru.arty_bikini.crm_frontend.ui.input.element

import react.ChildrenBuilder
import react.Props
import ru.arty_bikini.crm_frontend.ui.bootstrap.*
import ru.arty_bikini.crm_frontend.ui.root.tryFC

external interface MultiSelectElementProps<T : Any, V : Any> : Props {

    var item: T

    var variants: List<V>

    var getAll: (T) -> List<V>
    var setAll: (T, List<V>) -> Unit

    var asKey: (V) -> String
    var toText: (V) -> String?

}

val MultiSelectElement = tryFC<MultiSelectElementProps<*, *>> {

    bounded(it)

}

private fun <T : Any, V : Any> ChildrenBuilder.bounded(props: MultiSelectElementProps<T, V>) {

    val current = props.getAll(props.item)
    val currentKey = current.map { props.asKey(it) }

    val title: String = when {
        current.isEmpty() -> "<Нет>"
        props.variants.size == current.size -> "<Все>"
        else -> current.joinToString(", ") { props.toText(it) ?: "<error>" }
    }

    dropdown(title) {
        dropdownItem("Ни одного") {
            props.setAll(props.item, emptyList())
        }
        dropdownItem("Все") {
            props.setAll(props.item, props.variants)
        }

        dropdownDivider()

        props.variants.forEach { variant ->
            val enabled = props.asKey(variant) in currentKey

            dropdownItem(
                content = {

                    if (enabled) {
                        badge(BootstrapColor.SUCCESS, "+")
                    } else {
                        badge(BootstrapColor.LIGHT, "-")
                    }

                    +props.toText(variant).orEmpty()

                }
            ) {
                val newValue = current.toMutableList()
                if (enabled) {
                    newValue.removeAll { props.asKey(it) == props.asKey(variant) }
                } else {
                    newValue.add(variant)
                }
                props.setAll(props.item, newValue)
            }
        }
    }
}

fun <T : Any, V : Any> ChildrenBuilder.MultiSelectElement(

    item: T,

    variants: List<V>,

    getAll: (T) -> List<V>,
    setAll: (T, List<V>) -> Unit,

    asKey: (V) -> String,
    toText: (V) -> String?,

) {
    MultiSelectElement {
        val props = this.unsafeCast<MultiSelectElementProps<T, V>>()

        props.item = item
        props.variants = variants

        props.getAll = getAll
        props.setAll = setAll

        props.asKey = asKey
        props.toText = toText

    }
}