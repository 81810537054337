package ru.arty_bikini.crm_frontend.ui.root

import kotlinx.browser.window
import react.ChildrenBuilder
import react.FC
import react.Props
import ru.arty_bikini.crm_frontend.ui.bootstrap.alertDanger

fun <P : Props> tryFC(block: ChildrenBuilder.(P) -> Unit) = FC<P> {

    try {
        block(this, it)
    } catch (e: Throwable) {
        e.printStackTrace()
        alertDanger { +"Rendering error" }

        window.setTimeout({
            throw e
        })
    }

}