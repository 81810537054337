package ru.arty_bikini.crm.dto.packet.work

import kotlinx.serialization.Serializable

@Serializable
class GetIntervalWorkRequest (
    var dateTime  : Long? = null, //получаем дату начала
    var dateStart : Long? = null,
    var dateEnd : Long? = null,

)
