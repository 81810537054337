package ru.arty_bikini.crm_frontend.form.calc

import csstype.ClassName
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import react.ChildrenBuilder
import react.dom.html.InputType
import react.dom.html.ReactHTML
import react.dom.html.ReactHTML.hr
import ru.arty_bikini.crm.dto.orders.OrderDTO
import ru.arty_bikini.crm_frontend.ClientCore
import ru.arty_bikini.crm_frontend.ClientProps
import ru.arty_bikini.crm_frontend.event.ShowAlertEvent
import ru.arty_bikini.crm_frontend.table.config.AdditionalItemsSpecial
import ru.arty_bikini.crm_frontend.ui.bootstrap.*
import ru.arty_bikini.crm_frontend.ui.root.tryFC
import ru.arty_bikini.crm_frontend.util.StringUtils
import ru.arty_bikini.crm_frontend.util.useCache
import ru.arty_bikini.crm_frontend.util.useIncrementor

external interface AdditionalItemsInputProps : ClientProps {
    var order: OrderDTO
    var readOnly: Boolean

    var showPrices: Boolean
    var totalStones: Int?
    var columns: Int

    var filterSpecial: AdditionalItemsSpecial?

}

private val AdditionalItemsInput = tryFC<AdditionalItemsInputProps> { props ->

    val inc = useIncrementor()

    val additionalItems = useCache(props.client.cache.additionalItems)
        .asSequence()
        .filter { it.visible }
        .filter { props.filterSpecial == null || it.group?.contains(props.filterSpecial?.hashTag ?: "#null") == true }
        .sortedBy { it.id }
        .sortedBy { it.name }
        .sortedBy { it.priority }
        .groupBy { it.group?.split("#")?.firstOrNull()?.trim() ?: "" }
        .entries
        .sortedWith(StringUtils.stringOrIntComparator { it.key })
        .toList()

    val colSize = when (props.columns) {
        1 -> 12
        2 -> 6
        3 -> 4
        4 -> 3
        6 -> 1
        else -> 6
    }

    additionalItems.forEachIndexed { idx, (group, items) ->
        if (idx > 0) {
            hr { className = ClassName("m-0") }
        }
        row(title = group) {

            items.forEach { item ->
                val has = props.order.price?.contains(item) == true

                colLg(colSize) {
                    ReactHTML.div {
                        className = ClassName("form-check form-switch")

                        ReactHTML.input {
                            className = ClassName("form-check-input")
                            type = InputType.checkbox
                            checked = has

                            readOnly = props.readOnly
                        }

                        ReactHTML.label {
                            className = ClassName("form-check-label")
                            +item.name.orEmpty()
                        }

                        if (props.showPrices) {
                            val totalStones = props.totalStones
                            val value = when {
                                item.percent == 0 -> StringUtils.printNumber(item.count)
                                totalStones == null -> "(р+%) " + StringUtils.printNumber(item.count) + " + x%"
                                else -> "(р+%) " + StringUtils.printNumber(item.count + totalStones * item.percent / 1000)
                            }
                            badge(
                                color = if (has) BootstrapColor.SECONDARY else BootstrapColor.LIGHT,
                                text = "Надбавка: $value",
                                extraClass = ClassName("float-end mt-1")
                            )
                        }

                        if (props.readOnly) return@div

                        onClick = {
                            val data = props.order.price?.toMutableList() ?: ArrayList()

                            data.removeAll { it.id == item.id }
                            if (!has) {
                                data.add(item)
                            }
                            props.order.price = data

                            inc.increment()

                            GlobalScope.launch {
                                props.client
                                    .network
                                    .order
                                    .saveOrder(props.order)

                                inc.increment()
                            }

                            if (!props.order.stones.isNullOrEmpty()) {
                                props.client.event.fireEvent(ShowAlertEvent(BootstrapColor.WARNING, "Изменилась цена заказа. Необходимо пересчитать стразы."))
                            }
                        }
                    }
                }
            }

        }
    }
}

fun ChildrenBuilder.AdditionalItemsInput(client: ClientCore, order: OrderDTO, readOnly: Boolean, showPrices: Boolean = false, totalStones: Int? = null, columns: Int = 2, filterSpecial: AdditionalItemsSpecial? = null) {
    AdditionalItemsInput {
        this.client = client
        this.order = order
        this.readOnly = readOnly
        this.showPrices = showPrices
        this.totalStones = totalStones
        this.columns = columns
        this.filterSpecial = filterSpecial
    }
}
