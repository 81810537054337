package ru.arty_bikini.crm_frontend

import kotlinx.browser.localStorage
import kotlinx.serialization.KSerializer
import kotlinx.serialization.builtins.serializer
import kotlinx.serialization.json.Json
import kotlin.properties.ReadWriteProperty
import kotlin.reflect.KProperty

class SettingsModule(val core: ClientCore) {

    var showDisabledHints by localStorage(Boolean.serializer()) { false }

    var viewAsWorker by localStorage(Boolean.serializer()) { false }

    var extendedMenu by localStorage(Boolean.serializer()) { false }

    var debugLogs by localStorage(Boolean.serializer()) { false }

}

private val json = Json

fun <T : Any> localStorage(type: KSerializer<T>, default: () -> T): ReadWriteProperty<Any, T> {
    return object : ReadWriteProperty<Any, T> {
        override fun getValue(thisRef: Any, property: KProperty<*>): T {
            try {
                val str = localStorage.getItem(property.name) ?: return default()
                return json.decodeFromString(type, str)
            } catch (e: Throwable) {
                e.printStackTrace()
                return default()
            }
        }

        override fun setValue(thisRef: Any, property: KProperty<*>, value: T) {
            try {
                localStorage.setItem(property.name, json.encodeToString(type, value))
            } catch (e: Throwable) {
                e.printStackTrace()
            }
        }

    }
}

fun <T : Any> localStorage(type: KSerializer<T>): ReadWriteProperty<Any, T?> {
    return object : ReadWriteProperty<Any, T?> {
        override fun getValue(thisRef: Any, property: KProperty<*>): T? {
            try {
                val str = localStorage.getItem(property.name) ?: return null
                return json.decodeFromString(type, str)
            } catch (e: Throwable) {
                e.printStackTrace()
                return null
            }
        }

        override fun setValue(thisRef: Any, property: KProperty<*>, value: T?) {
            try {
                if (value == null) {
                    localStorage.removeItem(property.name)
                } else {
                    localStorage.setItem(property.name, json.encodeToString(type, value))
                }

            } catch (e: Throwable) {
                e.printStackTrace()
            }
        }

    }
}
