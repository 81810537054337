package ru.arty_bikini.crm.dto.packet.order

import kotlinx.serialization.Serializable
import ru.arty_bikini.crm.dto.orders.OrderDTO
import ru.arty_bikini.crm.dto.packet.BaseResponse

//ответ для: изменить клиента
@Serializable
class EditClientResponse(//статус
    override val ok: Boolean,
    override val statusCode: String,
    override val displayMessage: String?,
    val order: OrderDTO? = null
) : BaseResponse()
