package ru.arty_bikini.crm_frontend.table.common

import react.ChildrenBuilder
import react.FC
import react.Props
import ru.arty_bikini.crm.dto.enums.UserGroup
import ru.arty_bikini.crm_frontend.ClientProps
import ru.arty_bikini.crm_frontend.ui.root.tryFC

fun commonPage(requireGroups: List<UserGroup>, init: ChildrenBuilder.(ClientProps) -> Unit) = PageConfig(requireGroups, tryFC(init))

class PageConfig(val requireGroups: List<UserGroup>, val fc: FC<ClientProps>)