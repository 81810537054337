package ru.arty_bikini.crm_frontend.ui.input.panel

import csstype.ClassName
import react.ChildrenBuilder
import react.Props
import react.dom.html.ReactHTML.button
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.span
import react.useState
import ru.arty_bikini.crm_frontend.ui.bootstrap.BootstrapButton
import ru.arty_bikini.crm_frontend.ui.bootstrap.BootstrapColor
import ru.arty_bikini.crm_frontend.ui.root.tryFC

external interface InputPanelProps : Props {


    var items: List<InputPanelItem<*>>

    var onSave: (SaveEvent) -> Unit
    var saveButtonText: String

    var onCancel: ((SaveEvent) -> Unit)?
    var cancelButtonText: String

}



val InputPanelInt = tryFC<InputPanelProps> { props ->

    var updatedValues: Map<InputPanelValueKey<*>, Any?> by useState { emptyMap() }

    div {
        className = ClassName("vstack gap-2")

        props.items.forEach { item ->

            div {
                className = ClassName("input-group input-group-sm")

                span {
                    className = ClassName("input-group-text")

                    + item.name
                }

                InputPanelRow {
                    this.item = item
                    this.update = { id, value ->

                        updatedValues = updatedValues + (id to value)

                    }
                }

                if (item.postfix != null) {

                    span {
                        className = ClassName("input-group-text")

                        + item.postfix
                    }
                }

            }
        }

        div {
            className = ClassName("btn-group btn-group-sm")


            button {
                className = BootstrapButton.painted(BootstrapColor.PRIMARY)

                +props.saveButtonText

                onClick = {

                    val data: Map<InputPanelValueKey<*>, Any?> = props
                        .items
                        .map { it.key to (updatedValues.get(it.key) ?: it.defaultValue) }
                        .toMap()

                    val event = SaveEvent(data)

                    props.onSave(event)
                }


            }

            if (props.onCancel != null) {

                button {
                    className = BootstrapButton.outline(BootstrapColor.SECONDARY)

                    +props.cancelButtonText

                    onClick = {

                        val data: Map<InputPanelValueKey<*>, Any?> = props
                            .items
                            .map { it.key to (updatedValues.get(it.key) ?: it.defaultValue) }
                            .toMap()

                        val event = SaveEvent(data)

                        props.onCancel?.invoke(event)
                    }


                }
            }
        }
    }


}


fun ChildrenBuilder.InputPanel(init: InputPanelDSL.() -> Unit) {
    val dsl = InputPanelDSL()
        .apply(init)

    InputPanelInt {
        items = dsl.items
        onSave = dsl.onSave
        saveButtonText = dsl.saveButtonText
        onCancel = dsl.onCancel
        cancelButtonText = dsl.cancelButtonText
    }
}
