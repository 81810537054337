@file:Suppress("UNUSED_PARAMETER")

package ru.arty_bikini.crm_frontend.ui.input.table

import ru.arty_bikini.crm.dto.EntityDTO
import ru.arty_bikini.crm.dto.enums.HasDisplayValue
import ru.arty_bikini.crm_frontend.SimpleCache
import ru.arty_bikini.crm_frontend.ui.input.types.*
import kotlin.reflect.KMutableProperty0
import kotlin.reflect.KMutableProperty1
import kotlin.reflect.KProperty0
import kotlin.reflect.KProperty1

class SelectTableColumnBuilder<T : Any> constructor(
    val props: TablePanelProps<T>,
) {

    private fun <V : Any, P : Any> create(
        type: TablePanelColumnType,
        provider: ValueProvider<V>,
        converter: ValueConverter<V, P>,

        flag: FieldFlag.NotNull,
        title: String,
        value: KMutableProperty1<T, P>,
        options: TableColumnRenderOptions<T> = TableColumnRenderOptions(),
    ) {
        val cache = provider.cache
        if (cache != null) {
            props.cache += cache
        }

        props.columns = props.columns + TablePanelColumnProps<T, V>(
            cache = provider.cache,
            title = title,
            type = type,
            allowNull = false,
            readOnly = false,
            options = options,
            prop = value adapt converter,
            variants = provider,
            converter = converter,
        )
    }

    private fun <V : Any, P : Any> create(
        type: TablePanelColumnType,
        provider: ValueProvider<V>,
        converter: ValueConverter<V, P>,

        flag: FieldFlag.Nullable,
        title: String,
        prop: PropertyReference<T, P?>,
        options: TableColumnRenderOptions<T> = TableColumnRenderOptions(),
        sortName: T.() -> String?,
        get: T.() -> P?,
        set: T.(P?) -> Unit,
    ) {
        val cache = provider.cache
        if (cache != null) {
            props.cache += cache
        }

        props.columns = props.columns + TablePanelColumnProps<T, V?>(
            cache = provider.cache,
            title = title,
            type = type,
            allowNull = true,
            readOnly = false,
            options = options,
            prop = prop adapt converter,
            variants = provider,
            converter = converter,
        )
    }

    private fun <V : Any, P : Any> create(
        type: TablePanelColumnType,
        provider: ValueProvider<V>,
        converter: ValueConverter<V, P>,

        flag: FieldFlag.ReadOnlyProp,
        title: String,
        prop: PropertyReference<T, P?>,
        options: TableColumnRenderOptions<T> = TableColumnRenderOptions(),
        sortName: T.() -> String?
    ) {
        val cache = provider.cache
        if (cache != null) {
            props.cache += cache
        }

        props.columns = props.columns + TablePanelColumnProps<T, V?>(
            cache = provider.cache,
            title = title,
            type = type,
            allowNull = true,
            readOnly = true,
            options = options,
            prop = prop adaptReadonly converter,
            variants = provider,
            converter = converter,
        )
    }

    operator fun <V> invoke(
        flag: FieldFlag.NotNull,
        title: String,
        values: Array<V>,
        prop: KMutableProperty1<T, V>,
        options: TableColumnRenderOptions<T> = TableColumnRenderOptions(),
    ) where V : Enum<V>,
            V : HasDisplayValue
    {
        val provider = EnumValueProvider(values.toList())
        val converter = EnumValueConverter<V>(provider)
        create(
            type = TablePanelColumnType.SELECT,
            provider = provider,
            converter = converter,
            flag = flag,
            title = title,
            options = options,
            value = prop
        )

    }

    operator fun <V> invoke(
        flag: FieldFlag.Nullable,
        title: String,
        values: Array<V>,
        prop: PropertyReference<T, V?>,
        options: TableColumnRenderOptions<T> = TableColumnRenderOptions(),
    ) where V : Enum<V>,
            V : HasDisplayValue
    {
        val provider = EnumValueProvider(values.toList())
        val converter = EnumValueConverter<V>(provider)

        create(
            type = TablePanelColumnType.SELECT,
            provider = provider,
            converter = converter,
            flag = flag,
            title = title,
            options = options,
            prop = prop,
            sortName = { prop.native?.name },
            get = { prop.get(this, ) },
            set = { prop.set(this, it) }
        )

    }

    operator fun <V> invoke(
        flag: FieldFlag.Nullable,
        title: String,
        values: Array<V>,
        prop: KMutableProperty1<T, V?>,
        options: TableColumnRenderOptions<T> = TableColumnRenderOptions(),
    ) where V : Enum<V>,
            V : HasDisplayValue
    {
        invoke(flag, title, values, PropertyReference(prop), options)
    }

    operator fun <V> invoke(
        flag: FieldFlag.ReadOnlyProp,
        title: String,
        values: Array<V>,
        prop: KProperty1<T, V?>,
        options: TableColumnRenderOptions<T> = TableColumnRenderOptions(),
    ) where V : Enum<V>,
            V : HasDisplayValue
    {
        invoke(flag, title, values, PropertyReference.readOnly(prop), options)
    }

    operator fun <V> invoke(
        flag: FieldFlag.ReadOnlyProp,
        title: String,
        values: Array<V>,
        prop: PropertyReference<T, V?>,
        options: TableColumnRenderOptions<T> = TableColumnRenderOptions(),
    ) where V : Enum<V>,
            V : HasDisplayValue
    {
        val provider = EnumValueProvider(values.toList())
        val converter = EnumValueConverter<V>(provider)

        create(
            type = TablePanelColumnType.SELECT,
            provider = provider,
            converter = converter,
            flag = flag,
            title = title,
            options = options,
            prop = prop,
            sortName = { prop.native?.name },
        )

    }

    operator fun <V> invoke(
        flag: FieldFlag.Nullable,
        title: String,
        values: Array<V>,
        get: T.() -> V?,
        set: T.(V?) -> Unit,
        options: TableColumnRenderOptions<T> = TableColumnRenderOptions(),
    ) where V : Enum<V>,
            V : HasDisplayValue
    {
        val provider = EnumValueProvider(values.toList())
        val converter = EnumValueConverter<V>(provider)
        create(
            type = TablePanelColumnType.SELECT,
            provider = provider,
            converter = converter,
            flag = flag,
            title = title,
            options = options,
            prop = PropertyReference.anonymous(get, set),
            sortName = { null },
            get = { get() },
            set = { set(it) }
        )

    }

    operator fun <V : EntityDTO> invoke(
        flag: FieldFlag.Nullable,
        title: String,
        values: SimpleCache<List<V>>,
        prop: PropertyReference<T, V?>,
        name: V.() -> String,
        options: TableColumnRenderOptions<T> = TableColumnRenderOptions(),
    ) {
        val provider = EntityValueProvider(values)
        val converter = EntityValueConverter(provider, name)

        create(
            type = TablePanelColumnType.SELECT,
            provider = provider,
            converter = converter,
            flag = flag,
            title = title,
            options = options,
            prop = prop,
            sortName = { prop.native?.name },
            get = { prop.get(this) },
            set = { prop.set(this, it) }
        )
    }

    operator fun <V : EntityDTO> invoke(
        flag: FieldFlag.Nullable,
        title: String,
        values: SimpleCache<List<V>>,
        prop: KMutableProperty1<T, V?>,
        name: V.() -> String,
        options: TableColumnRenderOptions<T> = TableColumnRenderOptions(),
    ) {
        invoke(flag, title, values, PropertyReference(prop), name, options)
    }

    operator fun <V : EntityDTO> invoke(
        flag: FieldFlag.ReadOnlyProp,
        title: String,
        values: SimpleCache<List<V>>,
        prop: PropertyReference<T, V?>,
        name: V.() -> String,
        options: TableColumnRenderOptions<T> = TableColumnRenderOptions(),
    ) {
        val provider = EntityValueProvider(values)
        val converter = EntityValueConverter(provider, name)

        create(
            type = TablePanelColumnType.SELECT,
            provider = provider,
            converter = converter,
            flag = flag,
            title = title,
            options = options,
            prop = prop,
            sortName = { prop.native?.name },
        )
    }

    operator fun <V : EntityDTO> invoke(
        flag: FieldFlag.ReadOnlyProp,
        title: String,
        values: SimpleCache<List<V>>,
        prop: KProperty1<T, V?>,
        name: V.() -> String,
        options: TableColumnRenderOptions<T> = TableColumnRenderOptions(),
    ) {
        invoke(flag, title, values, PropertyReference.readOnly(prop), name, options)
    }



}
