package ru.arty_bikini.crm.dto.packet.work

import kotlinx.serialization.Serializable
import ru.arty_bikini.crm.dto.packet.BaseResponse
import ru.arty_bikini.crm.dto.work.IntervalDTO
import ru.arty_bikini.crm.dto.work.WorkDTO
import kotlin.jvm.JvmOverloads

//ответ для:получить список работ...
@Serializable
class GetIntervalWorkResponse

@JvmOverloads
constructor(
    override val ok: Boolean,
    override val statusCode: String,
    override val displayMessage: String? = null,

    val workDTOList: List<WorkDTO>? = null,
    val intervalDTOList: List<IntervalDTO>? = null,

    val dateStart : Long? = null,
    val dateEnd : Long? = null,

    val previousIncompleteWorksCount: Long = 0
) : BaseResponse()
