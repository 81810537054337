package ru.arty_bikini.crm_frontend.table

import csstype.ClassName
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import react.dom.html.ReactHTML
import react.dom.html.ReactHTML.button
import react.useState
import ru.arty_bikini.crm.dto.enums.UserGroup
import ru.arty_bikini.crm.dto.orders.OrderDTO
import ru.arty_bikini.crm.dto.orders.PersonalDataDTO
import ru.arty_bikini.crm.dto.orders.google.DataGoogleDTO
import ru.arty_bikini.crm_frontend.ClientProps
import ru.arty_bikini.crm_frontend.form.google.LinkGoogleDataToOrderForm
import ru.arty_bikini.crm_frontend.table.common.commonPage
import ru.arty_bikini.crm_frontend.ui.bootstrap.BootstrapButton
import ru.arty_bikini.crm_frontend.ui.bootstrap.BootstrapColor
import ru.arty_bikini.crm_frontend.ui.bootstrap.cardLightShadow
import ru.arty_bikini.crm_frontend.ui.bootstrap.inputGroup
import ru.arty_bikini.crm_frontend.ui.input.table.*
import ru.arty_bikini.crm_frontend.ui.input.types.or
import ru.arty_bikini.crm_frontend.ui.root.tryFC
import ru.arty_bikini.crm_frontend.util.useAutoReload

val GoogleTablePage = commonPage(listOf(UserGroup.ADMIN, UserGroup.TANYA, UserGroup.MANAGER)) { props ->

    val dataGoogle = useAutoReload<DataGoogleDTO> {
        props
            .client
            .network
            .orderData
            .importResult()
            ?.dataGoogleDTOList
    }

    val clients = useAutoReload<OrderDTO> {
        props
            .client
            .network
            .order
            .getClients()
            ?.orders
            ?.filter { it.dataGoogle != null }
    }

    ReactHTML.div {
        className = ClassName("w-100")

        LoadButtonPanel { client = props.client }
    }

    ReactHTML.h2 {
        + "Ответы на форму"
    }

    TablePanelEntityAuto(props.client, "t-google-google", dataGoogle, DataGoogleDTO.serializer()) {

        addDate(FieldFlag.ReadOnlyProp, "Дата добавления", DataGoogleDTO::dateGoogle)
        addText(FieldFlag.ReadOnlyProp, "ФИО", DataGoogleDTO::name)
        addText(FieldFlag.ReadOnlyProp, "Телефон", DataGoogleDTO::telephon)
        addForm("Привязать", LinkGoogleDataToOrderForm)

    }

    ReactHTML.h2 {
        + "Клиенты с привязанными ответами"
    }

    TablePanelEntityAuto(props.client, "t-google-order", clients, OrderDTO.serializer()) {

        addText(FieldFlag.ReadOnlyProp, "ФИО", OrderDTO::name)
        addText(FieldFlag.ReadOnlyProp, "Телефон", OrderDTO::personalData or ::PersonalDataDTO then PersonalDataDTO::telephon)
        addText(FieldFlag.ReadOnlyValue, "ФИО (Гугл)") { this.dataGoogle?.name }
        addText(FieldFlag.ReadOnlyValue, "Телефон  (Гугл)") { this.dataGoogle?.telephon }
        addDate(FieldFlag.ReadOnlyValue, "Дата заполнения формы") { this.dataGoogle?.dateGoogle }
        addButton("Отвязать") {
            GlobalScope.launch {
                props.client
                    .network
                    .orderData
                    .unlinkFromGoogle(it)
            }
        }

    }
}

val LoadButtonPanel = tryFC<ClientProps> { props ->

    var importing by useState(false)

    cardLightShadow {

        inputGroup {
            with(cb) {
                button {
                    className = BootstrapButton.outline(BootstrapColor.PRIMARY)

                    if (importing) {
                        disabled = true
                    }

                    +"Загрузить новые данные"

                    onClick = onClick@{

                        if (importing) return@onClick

                        importing = true

                        GlobalScope.launch {

                            props.client
                                .network
                                .orderData
                                .import()

                            importing = false
                        }
                    }
                }

            }
            if (importing) {
                warn(text = "Идет загузка")
            }
        }

    }
}