package ru.arty_bikini.crm_frontend.table.config

import react.dom.html.InputType
import ru.arty_bikini.crm.dto.dict.PriceDTO
import ru.arty_bikini.crm.dto.enums.HasDisplayValue
import ru.arty_bikini.crm_frontend.table.common.dictTablePage
import ru.arty_bikini.crm_frontend.ui.input.table.*
import ru.arty_bikini.crm_frontend.ui.input.types.ValueConverter
import ru.arty_bikini.crm_frontend.ui.input.types.adapt

val AdditionalItemsTablePage = dictTablePage(
    serializer = PriceDTO.serializer(),
    load = { dict.getPrice()?.priceDTOS },
    save = { dict.editPrice(it) },
    addNew = { dict.addPrice(PriceDTO().apply {
        this.name = it
    }) },
) {

    addText(FieldFlag.Nullable, "Название", PriceDTO::name)
    addCost(FieldFlag.NotNull, "Цена", PriceDTO::count)
    addCost(FieldFlag.NotNull, "Надбавка за 1000 страз (руб)", PriceDTO::percent)
    addInt(FieldFlag.NotNull, "Сортировка", PriceDTO::priority, options = TableColumnRenderOptions(defaultSortLegacy = true))
    addBoolean(FieldFlag.NotNull, "Показать", PriceDTO::visible)
    addText(FieldFlag.Nullable, "Группа", PriceDTO::group)
    addSelect(
        flag = FieldFlag.Nullable,
        title = "Специальная",
        values = AdditionalItemsSpecial.values(),
        get = { AdditionalItemsSpecial.values().lastOrNull { group?.contains(it.hashTag) == true } ?: AdditionalItemsSpecial.NONE },
        set = {
            this.group = ((group ?: "")
                .removeSuffix(AdditionalItemsSpecial.NONE.hashTag)
                .removeSuffix(AdditionalItemsSpecial.GLUE.hashTag)
                .removeSuffix(AdditionalItemsSpecial.FABRIC.hashTag)
                .removeSuffix(AdditionalItemsSpecial.DESIGN.hashTag)
                + (it?.hashTag ?: ""))
        }
    )
    addCost(FieldFlag.NotNull, "Вознаграждение расклейщице (руб)", PriceDTO::payGluerCount)
    addInt(FieldFlag.NotNull, "Вознаграждение расклейщице (%)", PriceDTO::payGluerPercent)

}

enum class AdditionalItemsSpecial(override val displayName: String, val hashTag: String) : HasDisplayValue {
    NONE("Нет", ""),
    GLUE("Клей", " #glue"),
    FABRIC("Ткань", " #fabric"),
    DESIGN("Дизайн", " #decign"),
    SKIRT("Юбочка", "#skirt")

}
