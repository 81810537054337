package ru.arty_bikini.crm_frontend.measure

import ru.arty_bikini.crm.dto.enums.HasDisplayValue
import ru.arty_bikini.crm.dto.enums.measure.DataDisplayCategory

class MeasureType<T : Any> private constructor(val category: DataDisplayCategory){
    companion object {
        val SPECIAL = MeasureType<SpecialMeasure>(DataDisplayCategory.SPECIAL)
        val MEASURES_UP = MeasureType<Nothing>(DataDisplayCategory.MEASURES_UP)
        val MEASURES_DOWN = MeasureType<Nothing>(DataDisplayCategory.MEASURES_DOWN)
        val MEASURES_OTHER = MeasureType<Nothing>(DataDisplayCategory.MEASURES_OTHER)
    }
}

enum class SpecialMeasure : HasDisplayValue {

    FIO,
    PHONE,
    FEDERATION,//Федерация
    CITY,//ГОРОД
    COMPETITION_GOOGLE,//дата соревнований
    NEEDED_GOOGLE,//дата когда нужен
    GROWTH,//рост
    KILO,//сколько кг убрать
    CATEGORY_GOOGLE,//категория
    COLOR_GOOGLE,//цвет купальника
    TEXTILE_GOOGLE,// материал купальника
    DESIGN_GOOGLE,//дизайн
    GLUE_GOOGLE,//клей
    CONECTOR,//какие конекторы
    CONECTOR_WERE,//где конекторы
    COMENT_OF_CLIENT,//комментарий от клиента
    ;

    override val displayName: String = name
}
