package ru.arty_bikini.crm_frontend.ui.input.table.data

import react.ChildrenBuilder
import react.useEffect
import react.useState
import ru.arty_bikini.crm_frontend.ClientCore
import ru.arty_bikini.crm_frontend.ClientProps
import ru.arty_bikini.crm_frontend.ui.input.table.TablePanelEntity
import ru.arty_bikini.crm_frontend.ui.root.tryFC

external interface AutoReloadDataProviderProps<T : Any> : ClientProps {
    var dataToSync: List<T>?

    var getId: (T) -> Int
    var getHash: (T) -> String

    var content: (ChildrenBuilder, DataSource<T>) -> Unit
}

private val AutoReloadDataProvider = tryFC<AutoReloadDataProviderProps<*>> { props ->
    bounded(props)
}

private fun <T : Any> ChildrenBuilder.bounded(props: AutoReloadDataProviderProps<T>) {

    val (dataCache, setDataCache) = useState<List<TablePanelEntity<T>>> { emptyList() }

    var comparator by useState<Comparator<TablePanelEntity<T>>>(compareBy { props.getId(it.entity) })
    var loading by useState(DataSourceLoadingState.LOADING)

    useEffect(props.dataToSync) {
        if (props.dataToSync == null) {
            if (loading == DataSourceLoadingState.LOADED) {
                loading = DataSourceLoadingState.ERROR
            }
            return@useEffect
        }

        loading = DataSourceLoadingState.LOADED

        if (dataCache.isEmpty()) {
            setDataCache { curr ->
                curr + (props.dataToSync ?: emptyList())
                    .map { TablePanelEntity(it) }
                    .sortedWith(comparator)

            }
        } else {
            setDataCache { curr ->
                val old = curr
                    .map {
                        val id = props.getId(it.entity)
                        val new = (props.dataToSync ?: emptyList())
                            .find { props.getId(it) == id }

                        if (new != null) {

                            val oldHash = props.getHash(it.entity)
                            val hewHash = props.getHash(new)

//                            console.log(arrayOf(oldHash, hewHash))

                            val updated = oldHash != hewHash

                            return@map it.copy(entity = new, updated = it.updated || updated)
                        } else {
                            return@map it.copy(removed = true)
                        }
                    }

                val new = (props.dataToSync ?: emptyList())
                    .filter {
                        val id = props.getId(it)
                        return@filter old.none { props.getId(it.entity) == id }
                    }
                    .map { TablePanelEntity(entity = it, new = true) }
                    .sortedWith(comparator)

                old + new
            }
        }
    }

    useEffect(comparator) {
        setDataCache { curr ->
            curr.sortedWith(comparator)
        }
    }

    val dataSource = DataSource<T>(
        state = loading,
        currentData = dataCache,
        setSort = { comparator = it.comparator },
        clientSideSorting = true,
    )

    props.content(this, dataSource)
}

fun <T : Any> ChildrenBuilder.AutoReloadDataProvider(
    client: ClientCore,
    sync: List<T>?,
    getId: (T) -> Int,
    getHash: (T) -> String,
    content: ChildrenBuilder.(DataSource<T>,
) -> Unit) {
    AutoReloadDataProvider {

        val props = this.unsafeCast<AutoReloadDataProviderProps<T>>()

        props.client = client
        props.dataToSync = sync

        props.getId = getId
        props.getHash = getHash

        props.content = content
    }
}