package ru.arty_bikini.crm_frontend.network

import kotlinx.serialization.json.Json
import org.w3c.files.File
import org.w3c.xhr.FormData
import ru.arty_bikini.crm.dto.enums.OrderFileCategory
import ru.arty_bikini.crm.dto.file.FileDTO
import ru.arty_bikini.crm.dto.file.OrderFileDTO
import ru.arty_bikini.crm.dto.orders.OrderDTO
import ru.arty_bikini.crm.dto.packet.file.*
import ru.arty_bikini.crm_frontend.ClientCore

class FileService(clientCore: ClientCore) : BaseService(clientCore) {

    suspend fun upload(file: File): UploadFileResponse? {
        val formData = FormData()

        formData.append("file", file)

        return callFile<UploadFileResponse>("/api/file/upload-file", formData).body

    }

    suspend fun getReport(): GetReportResponse? {
        return call<GetReportResponse>("/api/file/get-report").body

    }

    suspend fun getPage(page: Int): GetFileResponse? {
        return call<GetFileResponse>("/api/file/get-file", query = mapOf("page" to page.toString())).body
    }

    suspend fun addFileToOrder(file: FileDTO, order: OrderDTO, priority: Int, category: OrderFileCategory): ServerResponse<AddOrderFileResponse> {
        return call<AddOrderFileResponse, AddOrderFileRequest>("/api/file/add-order-file", body = AddOrderFileRequest(OrderFileDTO().apply {
            this.file = file
            this.order = order
            this.priority = priority
            this.category = category
        }))

    }
    suspend fun editFileOfOrder(file: OrderFileDTO, comment: String): EditOrderFileResponse? {

        val file = Json.decodeFromJsonElement(OrderFileDTO.serializer(), Json.encodeToJsonElement(OrderFileDTO.serializer(), file))

        file.comment = comment

        return call<EditOrderFileResponse, EditOrderFileRequest>("/api/file/edit-order-file", body = EditOrderFileRequest(file)).body

    }
    suspend fun unlinkFileFromOrder(file: OrderFileDTO): DeleteOrderFileResponse? {
        return call<DeleteOrderFileResponse, DeleteOrderFileRequest>("/api/file/delete-order-file", body = DeleteOrderFileRequest(file)).body

    }

}