package ru.arty_bikini.crm_frontend.table.admin

import kotlinx.browser.window
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import react.useEffectOnce
import react.useState
import ru.arty_bikini.crm.dto.SessionDTO
import ru.arty_bikini.crm.dto.UserDTO
import ru.arty_bikini.crm.dto.enums.UserGroup
import ru.arty_bikini.crm_frontend.event.ShowAlertEvent
import ru.arty_bikini.crm_frontend.table.common.commonPage
import ru.arty_bikini.crm_frontend.ui.bootstrap.BootstrapColor
import ru.arty_bikini.crm_frontend.ui.input.table.*
import ru.arty_bikini.crm_frontend.ui.input.types.or

val SessionListPage = commonPage(
    requireGroups = listOf(UserGroup.ADMIN)
) { props ->

    var sessions by useState { emptyList<SessionDTO>() }

    fun update() {
        GlobalScope.launch {
            sessions = props
                .client
                .network
                .auth
                .getSessions()
                .body
                ?: emptyList()
        }
    }

    useEffectOnce {

        update()

    }

    TablePanelEntityAuto(props.client, "t-s-session", sessions, SessionDTO.serializer()) {

        addInt(FieldFlag.ReadOnlyProp, "ID", SessionDTO::id)
        addDate(FieldFlag.ReadOnlyProp, "Начата", SessionDTO::start)
        addDateTime(FieldFlag.ReadOnlyProp, "Дата последнего входа", SessionDTO::renew)
        addText(FieldFlag.ReadOnlyProp, "IP последнего входа", SessionDTO::lastIp)
        addText(FieldFlag.ReadOnlyProp, "Версия клиента", SessionDTO::renewVersion)

        addText(FieldFlag.ReadOnlyProp, "ФИО", SessionDTO::user or ::UserDTO then UserDTO::name)
        addText(FieldFlag.ReadOnlyProp, "Логин/Телефон", SessionDTO::user or ::UserDTO then UserDTO::login)
        addSelect(
            flag = FieldFlag.ReadOnlyProp,
            title = "Группа",
            values = UserGroup.values(),
            prop = SessionDTO::user or ::UserDTO then UserDTO::group
        )
        addBoolean(FieldFlag.ReadOnlyProp, "Показать", SessionDTO::user or ::UserDTO then UserDTO::visible)

        addButton(
            title = "Удалить",
            onClick = {
                GlobalScope.launch {
                    client.event.fireEvent(ShowAlertEvent(BootstrapColor.DANGER, "Error!"))
                }
            }
        )
    
    
    
    }


}

