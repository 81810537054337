package ru.arty_bikini.crm_frontend.util

import react.StateInstance
import kotlin.reflect.KProperty

inline operator fun <T> StateInstance<T>.getValue(
    thisRef: Any,
    property: KProperty<*>,
): T = asDynamic()[0]

inline operator fun <T> StateInstance<T>.setValue(
    thisRef: Any,
    property: KProperty<*>,
    value: T,
) {
    asDynamic()[1](value)
}

fun <T> StateInstance<T>.update(action: (T) -> T) {
    return component2()(action)
}

fun <T> StateInstance<T>.set(action: (T)) {
    return component2()(action)
}

fun <T> StateInstance<T>.get(): T {
    return component1()
}