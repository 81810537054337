package ru.arty_bikini.crm_frontend.ui.input.table

import ru.arty_bikini.crm.dto.enums.SortDirection

data class TableColumnState(
    val id: String,
    val filterQuery: String? = null,
    val hidden: HiddenState = HiddenState.DEFAULT,
)

class SortRule(
    val id: String,
    val direction: SortDirection,
    val specialName: String? = null
)

enum class HiddenState {
    DEFAULT,
    HIDDEN,
    SHOWN
}