package ru.arty_bikini.crm_frontend.ui.root

import csstype.ClassName
import csstype.integer
import csstype.px
import emotion.react.css
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.button
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.li
import react.dom.html.ReactHTML.nav
import react.dom.html.ReactHTML.span
import react.dom.html.ReactHTML.ul
import react.router.Route
import react.router.Routes
import react.router.dom.BrowserRouter
import react.router.dom.HashRouter
import react.router.dom.Link
import react.useState
import ru.arty_bikini.crm_frontend.ClientProps
import ru.arty_bikini.crm_frontend.ui.bootstrap.containerFluid
import ru.arty_bikini.crm_frontend.ui.bootstrap.dropdownDivider
import ru.arty_bikini.crm_frontend.ui.bootstrap.dropdownHeader
import ru.arty_bikini.crm_frontend.util.Debug
import ru.arty_bikini.crm_frontend.util.plus

val AppRouter = tryFC<ClientProps> {

    var currentUser by useState { it.client.auth.currentUser }

    val routes = RouteList.all

    val router = when {
        Debug.debugEnabled -> HashRouter
        else -> BrowserRouter
    }

    div {
        css {
            marginTop = 50.px
        }
    }

    router {
        nav {
            css {
                height = 50.px

                zIndex = integer(1_054)
            }

            className = className + ClassName("navbar navbar-expand-sm navbar-light bg-light fixed-top")

            containerFluid {
//                Link {
//                    className = ClassName("navbar-brand")
//
//                    to = "/home"
//
//                    +"Домой"
//                }
                button {
                    className = ClassName("navbar-toggler")

                    this.asDynamic()["data-bs-toggle"] = "collapse"
                    this.asDynamic()["data-bs-target"] = "#primaryNavbarContent"

                    span {
                        className = ClassName("navbar-toggler-icon")
                    }
                }
                div {
                    className = ClassName("collapse navbar-collapse bg-light")
                    id = "primaryNavbarContent"

                    ul {
                        className = ClassName("navbar-nav me-auto")

                        routes.groups.forEach { group ->
                            val groupRoutes = group.visibleFor(currentUser)
                            if (groupRoutes.count { it.path != "---" } == 0) {
                                // Nothing to show
                            } else if (groupRoutes.count { it.path != "---" }  == 1) {
                                val firstRoute = groupRoutes.first()

                                li {
                                    className = ClassName("nav-item")

                                    Link {
                                        className = ClassName("nav-link")

                                        to = firstRoute.path

                                        +group.groupName
                                    }
                                }
                            } else {

                                li {
                                    className = ClassName("nav-item dropdown")

                                    a {
                                        className = ClassName("nav-link dropdown-toggle")

                                        asDynamic()["data-bs-toggle"] = "dropdown"
//                                        asDynamic()["data-bs-auto-close"] = "outside"

                                        + group.groupName
                                    }
                                    ul {
                                        className = ClassName("dropdown-menu")

                                        groupRoutes.forEachIndexed { idx, route ->
                                            if (route.path == "---") {
                                                if (idx != 0) {
                                                    li {
                                                        dropdownDivider()
                                                    }
                                                }
                                                li {
                                                    dropdownHeader(route.title)
                                                }
                                            } else {
                                                li {

                                                    Link {
                                                        className = ClassName("dropdown-item")

                                                        to = route.path

                                                        + route.title
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                    }
                }
            }
        }

        Routes {
            val visibleRoutes = routes.visibleFor(currentUser)

            Route {
                path = "/home"
                element = visibleRoutes.first().create(it)
            }
            for (route in visibleRoutes) {
                Route {
                    path = route.path
                    element = route.create(it)
                }
            }

            Route {
                path = "*"
                element = visibleRoutes.first().create(it)
            }

        }
    }
}
