package ru.arty_bikini.crm_frontend.table

import ru.arty_bikini.crm.dto.enums.UserGroup
import ru.arty_bikini.crm.dto.enums.lead.ColdHotLeadInfo
import ru.arty_bikini.crm.dto.enums.lead.HasManyLeadInfo
import ru.arty_bikini.crm.dto.enums.personalData.ClientType
import ru.arty_bikini.crm.dto.filter.OrderFilter
import ru.arty_bikini.crm.dto.orders.LeadInfoDTO
import ru.arty_bikini.crm.dto.orders.OrderDTO
import ru.arty_bikini.crm.dto.orders.PersonalDataDTO
import ru.arty_bikini.crm_frontend.form.main.ArchiveMainForm
import ru.arty_bikini.crm_frontend.table.common.commonPage
import ru.arty_bikini.crm_frontend.ui.input.table.*
import ru.arty_bikini.crm_frontend.ui.input.table.data.PagerDataProviderOrder
import ru.arty_bikini.crm_frontend.ui.input.types.or

val ArchiveTablePage = commonPage(
    requireGroups = listOf(UserGroup.ADMIN, UserGroup.TANYA, UserGroup.MANAGER)
) { props ->


    PagerDataProviderOrder(props.client, { OrderFilter().apply { this.archive = true } }) { data ->
        TablePanelOrderSource(
            client = props.client,
            source = data,
            hintId = "t-archive",
        ) {

            addText(FieldFlag.ReadOnlyProp, "ФИО", OrderDTO::name, TableColumnRenderOptions(editButtonForm = ArchiveMainForm))
            addBoolean(FieldFlag.NotNull, "Архив", OrderDTO::archive)
            addSelect(FieldFlag.ReadOnlyProp, "Тип", ClientType.values(), OrderDTO::type)
            addDateTime(FieldFlag.ReadOnlyProp, "Дата появления лида", OrderDTO::personalData or ::PersonalDataDTO then PersonalDataDTO::createdTime)
            addDateTime(FieldFlag.ReadOnlyProp, "Дата предоплаты", OrderDTO::personalData or ::PersonalDataDTO then PersonalDataDTO::orderTime)
            addDateTime(FieldFlag.ReadOnlyProp, "Дата попадания в архив", OrderDTO::personalData or ::PersonalDataDTO then PersonalDataDTO::archiveTime)
            addText(FieldFlag.ReadOnlyProp, "Комментарий", OrderDTO::personalData or ::PersonalDataDTO then PersonalDataDTO::comment)
            addText(FieldFlag.ReadOnlyProp, "Комментарий слива", OrderDTO::leadInfo or ::LeadInfoDTO then LeadInfoDTO::commentArchive)
            addSelect(FieldFlag.ReadOnlyProp, "А,В,С", HasManyLeadInfo.values(), OrderDTO::leadInfo or ::LeadInfoDTO then LeadInfoDTO::hasMany)
            addSelect(FieldFlag.ReadOnlyProp, "Г,Т,Х", ColdHotLeadInfo.values(), OrderDTO::leadInfo or ::LeadInfoDTO then LeadInfoDTO::coldHot)

        }
    }

}
