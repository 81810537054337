import kotlinx.browser.document
import react.ReactElement
import react.create
import react.dom.client.createRoot
import ru.arty_bikini.crm_frontend.ui.root.AppComponent
import ru.arty_bikini.crm_frontend.ClientCore

fun main() {
    val container = document.createElement("div")
    document.body!!.appendChild(container)

    val welcome: ReactElement<*> = AppComponent.create {
        client = ClientCore()
    }

    createRoot(container).render(welcome)
}
