package ru.arty_bikini.crm_frontend.ui.root

import react.create
import ru.arty_bikini.crm.dto.UserDTO
import ru.arty_bikini.crm_frontend.ClientProps
import ru.arty_bikini.crm_frontend.table.common.PageConfig
import ru.arty_bikini.crm_frontend.ui.bootstrap.alertDanger

class RouteInfo(
    val path: String,
    val title: String,
    val component: PageConfig?
) {
    fun create(props: ClientProps) = PageWrapper.create() {
        client = props.client
        page = component?.fc ?: EMPTY
    }

    companion object {
        private val EMPTY = tryFC<ClientProps> { alertDanger { +"Empty" } }
    }
}

@DslMarker
annotation class RouteDSL

@RouteDSL
class RouteMenu {

    private val groupsInternal = ArrayList<RouteGroup>()
    val groups: List<RouteGroup> = groupsInternal

    fun visibleFor(user: UserDTO?): List<RouteInfo> {
        return groups
            .flatMap { it.routes }
            .filter { it.component?.requireGroups?.contains(user?.group) == true }
    }

    fun single(path: String, name: String, page: PageConfig) {
        group(name) {
            route(path, name, page)
        }
    }

    fun group(name: String, init: RouteGroup.() -> Unit) {
        groupsInternal.add(RouteGroup(name).apply(init))
    }
}

@RouteDSL
class RouteGroup(val groupName: String) {

    private val routesInternal = ArrayList<RouteInfo>()
    val routes: List<RouteInfo> = routesInternal

    fun visibleFor(user: UserDTO?): List<RouteInfo> {
        return routesInternal
            .filter { it.path == "---" || it.component?.requireGroups?.contains(user?.group) == true }
    }

    fun route(path: String, name: String, page: PageConfig) {
        routesInternal.add(RouteInfo(path, name, page))
    }

    fun divider(name: String) {
        routesInternal.add(RouteInfo("---", name, null))
    }
}

fun buildRoutes(init: RouteMenu.() -> Unit): RouteMenu {
    return RouteMenu().apply(init)
}
