package ru.arty_bikini.crm_frontend.table.report

import ru.arty_bikini.crm.dto.UserDTO
import ru.arty_bikini.crm.dto.enums.UserGroup
import ru.arty_bikini.crm.dto.orders.OrderDTO
import ru.arty_bikini.crm_frontend.table.production.worksTMP
import ru.arty_bikini.crm_frontend.util.personalData

val OrderDTO.trainerDiscount: Int get() =
    min(
        personalData().payment,
        personalData().trainer?.discountCount ?: 0,
        personalData().trainer?.discountPercent ?: 0
    ).coerceIn(0, personalData().payment)

val OrderDTO.trainerPay: Int get() =
    min(
        personalData().payment,
        personalData().trainer?.payCount ?: 0,
        personalData().trainer?.payPercent ?: 0
    ).coerceIn(0, personalData().payment)

fun OrderDTO.getWorkMainPay(forUser: UserDTO? = null): Int {
    return worksTMP
        .asSequence()
        .filter { forUser == null || it.user == forUser && forUser.group == UserGroup.SEAMSTRESS }
        .sumOf { it.works?.sumOf { it.paySeamstress } ?: 0 }
}

fun OrderDTO.getWorkExtraPay(forUser: UserDTO? = null): Int {
    if (forUser == null || forUser.group == UserGroup.GLUER && worksTMP.any { it.user == forUser }) {
        return price?.sumOf { it.payGluerCount + getWorkStonePay(forUser) * it.payGluerPercent / 100 } ?: 0
    }
    return 0
}

fun OrderDTO.getWorkStonePay(forUser: UserDTO? = null): Int {
    if (forUser == null || forUser.group == UserGroup.GLUER && worksTMP.any { it.user == forUser }) {
        return stones?.sumOf { it.count * (it.rhinestoneType?.payGluerCount ?: 0) } ?: 0
    }
    return 0
}

fun OrderDTO.getResourceStoneCostRub(): Int {
    return (stones?.sumOf { it.count * (it.rhinestoneType?.purchasePrice ?: 0) } ?: 0) / 100
}
