package ru.arty_bikini.crm_frontend.event

import ru.arty_bikini.crm_frontend.ui.bootstrap.BootstrapColor
import kotlin.js.Date

class ShowAlertEvent(val color: BootstrapColor = BootstrapColor.INFO, val text: String) : Event<ShowAlertEvent>(ID) {

    val showFrom: Date = Date(Date.now())
    val showTo: Date = Date(Date.now() + 30 * 1_000)

    companion object {
        val ID = EventTypeId<ShowAlertEvent>()
    }
}