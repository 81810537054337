package ru.arty_bikini.crm_frontend.form.calc

import csstype.ClassName
import react.FC
import react.Props
import react.StateInstance
import react.dom.html.ReactHTML
import ru.arty_bikini.crm.dto.dict.RhinestoneTypeDTO
import ru.arty_bikini.crm_frontend.ui.bootstrap.*
import ru.arty_bikini.crm_frontend.ui.root.tryFC

external interface CalculatorOutputProps : Props {
    var type: RhinestoneTypeDTO

    var amount: Int
    var stored: Int?

}

val CalculatorOutput = tryFC<CalculatorOutputProps> { props ->

    row {
        colLg(6) {
            inputGroup {
                name("Кол-во", ClassName("d-lg-none"))
                value(props.amount.toString(), ClassName("flex-grow-1"))
            }

            if (props.stored != null) {
                badge(BootstrapColor.SUCCESS, "Сохранено ${props.stored}")
            }

        }

        colLg(6) {
            inputGroup {
                name("Стоимость", ClassName("d-lg-none"))
                value((props.amount * props.type.price / 100).toString() + " руб", ClassName("flex-grow-1"))
            }

            if (props.stored != null) {
                badge(BootstrapColor.SUCCESS, "Сохранено ${(props.stored ?: 0) * props.type.price / 100} руб")
            }

        }
    }
}
