package ru.arty_bikini.crm_frontend.table

import csstype.ClassName
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import react.dom.html.ReactHTML.div
import react.useEffectOnce
import react.useState
import ru.arty_bikini.crm.dto.enums.UserGroup
import ru.arty_bikini.crm.dto.enums.lead.ColdHotLeadInfo
import ru.arty_bikini.crm.dto.enums.lead.HasManyLeadInfo
import ru.arty_bikini.crm.dto.enums.personalData.ClientType
import ru.arty_bikini.crm.dto.orders.LeadInfoDTO
import ru.arty_bikini.crm.dto.orders.OrderDTO
import ru.arty_bikini.crm.dto.orders.PersonalDataDTO
import ru.arty_bikini.crm_frontend.form.button.ButtonArchiveForm
import ru.arty_bikini.crm_frontend.form.button.ButtonPrePaymentForm
import ru.arty_bikini.crm_frontend.form.google.LinkOrderToGoogleDataForm
import ru.arty_bikini.crm_frontend.form.main.MainForm
import ru.arty_bikini.crm_frontend.table.common.commonPage
import ru.arty_bikini.crm_frontend.ui.bootstrap.BootstrapColor
import ru.arty_bikini.crm_frontend.ui.input.table.*
import ru.arty_bikini.crm_frontend.ui.input.types.or
import ru.arty_bikini.crm_frontend.util.leadInfo
import ru.arty_bikini.crm_frontend.util.personalData
import ru.arty_bikini.crm_frontend.util.statusInfo

val LeadTablePage = commonPage(
    requireGroups = listOf(UserGroup.ADMIN, UserGroup.TANYA, UserGroup.MANAGER)
) { props ->

    var ordersWithoutPrepay by useState { emptyList<OrderDTO>() }
    var ordersWithPrepay by useState { emptyList<OrderDTO>() }

    useEffectOnce {

        var working = true

        GlobalScope.launch {
            while (working) {
                props
                    .client
                    .network
                    .order
                    .getLeads()
                    ?.let { packet ->

                        ordersWithoutPrepay = packet.orders ?: emptyList()
                        ordersWithPrepay = packet.ordersByMeasureAndDesign ?: emptyList()

                    }

                delay(1_000)
            }
        }

        cleanup {
            working = false
        }
    }

    div {

        className = ClassName("hstack gap-1 align-items-start")


        TablePanelOrderAuto(props.client, hintId = "t-lead-lead", ordersWithoutPrepay) {

            addNew = {
                props.client
                    .network
                    .order
                    .addOrder(it, ClientType.LEAD)
            }

            addText(
                flag = FieldFlag.ReadOnlyProp,
                title = "ФИО",
                value = OrderDTO::name,
                options = TableColumnRenderOptions(
                    editButtonForm = MainForm,
                    minWidthPx = 170,
                    maxWidthPx = 170,
                ),
            )
            addCost(
                flag = FieldFlag.NotNull,
                title = "Цена",
                value = OrderDTO::personalData or ::PersonalDataDTO then PersonalDataDTO::payment,
                options = TableColumnRenderOptions(
                    minWidthPx = 65,
                    maxWidthPx = 65,
                ),
            )
            addSelect(
                flag = FieldFlag.Nullable,
                title = "А,В,С",
                values = HasManyLeadInfo.values(),
                prop = OrderDTO::leadInfo or ::LeadInfoDTO then LeadInfoDTO::hasMany,
                options = TableColumnRenderOptions(
                    minWidthPx = 65,
                    maxWidthPx = 65,
                ),
            )
            addSelect(
                flag = FieldFlag.Nullable,
                title = "Г,Т,Х",
                values = ColdHotLeadInfo.values(),
                prop = OrderDTO::leadInfo or ::LeadInfoDTO then LeadInfoDTO::coldHot,
                options = TableColumnRenderOptions(
                    minWidthPx = 65,
                    maxWidthPx = 65,
                ),
            )
            addEntity(
                flag = FieldFlag.Nullable,
                title = "Тренер",
                values = client.cache.trainers,
                prop = OrderDTO::personalData or ::PersonalDataDTO then PersonalDataDTO::trainer,
                name = { name ?: "" }
            )

            addText(
                flag = FieldFlag.Nullable,
                title = "Комментарий",
                value = OrderDTO::personalData or ::PersonalDataDTO then PersonalDataDTO::comment
            )
            addForm("Предоплата", ButtonPrePaymentForm)
            addForm("В архив", ButtonArchiveForm)

        }

        TablePanelOrderAuto(props.client, hintId = "t-lead-client", ordersWithPrepay) {

            addText(
                flag = FieldFlag.ReadOnlyProp,
                title = "ФИО",
                options = TableColumnRenderOptions(
                    editButtonForm = MainForm,
                    minWidthPx = 170,
                    maxWidthPx = 170,
                ),
                value = OrderDTO::name
            )

            addButton(
                "Импорт мерок",
                options = TableColumnRenderOptions(
                    backgroundColor = { if (it.dataGoogle != null) BootstrapColor.SUCCESS else BootstrapColor.SECONDARY }
                ),
                text = { if (it.dataGoogle != null) "+" else "Импорт" },
                LinkOrderToGoogleDataForm.openAction(client)
            )
            addButton(
                title = "заполнила мерки",
                options = TableColumnRenderOptions(
                    backgroundColor = { if (it.statusInfo().isMeasureAll) BootstrapColor.SUCCESS else BootstrapColor.DANGER }
                ),
                text = { if (it.statusInfo().isMeasureAll) "+" else "Заполнить!" },
                onClick = MainForm.openAction(client)
            )
            addButton(
                title = "создала дизайн",
                options = TableColumnRenderOptions(
                    backgroundColor = { if (it.statusInfo().isDesignAll) BootstrapColor.SUCCESS else BootstrapColor.DANGER }
                ),
                text = { if (it.statusInfo().isDesignAll) "+" else "Заполнить!" },
                onClick = MainForm.openAction(client)
            )
            addText(
                flag = FieldFlag.Nullable,
                title = "Комментарий",
                value = OrderDTO::personalData or ::PersonalDataDTO then PersonalDataDTO::comment
            )
            addText(
                flag = FieldFlag.Nullable,
                title = "Предоплата",
                value = OrderDTO::personalData or ::PersonalDataDTO then PersonalDataDTO::prepayment,
                options = TableColumnRenderOptions(
                    minWidthPx = 65,
                    maxWidthPx = 65,
                ),
            )


        }
    }

}
