package ru.arty_bikini.crm_frontend.form.main

import csstype.ClassName
import ru.arty_bikini.crm.dto.enums.OrderFileCategory
import ru.arty_bikini.crm_frontend.ui.bootstrap.*
import ru.arty_bikini.crm_frontend.ui.input.form.FormInput
import ru.arty_bikini.crm_frontend.ui.root.tryFC

val FilesFormPart = tryFC<FormPartProps> { props ->

    FormInput(props.client, props.order) {

        readOnly = props.readOnly

        onSave {
            props
                .client
                .network
                .order
                .saveOrder(it)
        }

        content { entity ->

            cardGreenCollapse("Файлы", ClassName("vstack gap-2"), defaultVisible = false) {

                addImages(OrderFileCategory.TEXTILE)
                addImages(OrderFileCategory.DESIGN)
                addImages(OrderFileCategory.MEASURES)
                addImages(OrderFileCategory.OTHER)
                addImages(null)

            }

        }
    }
}