package ru.arty_bikini.crm_frontend.table.config

import ru.arty_bikini.crm.dto.dict.ProductTypeDTO
import ru.arty_bikini.crm.dto.enums.UserGroup
import ru.arty_bikini.crm.dto.work.WorkTypeDTO
import ru.arty_bikini.crm_frontend.table.common.dictTablePage
import ru.arty_bikini.crm_frontend.util.prefix
import ru.arty_bikini.crm_frontend.ui.input.table.*
import ru.arty_bikini.crm_frontend.ui.input.types.ListToListAdapter

val WorkTypeTablePage = dictTablePage<WorkTypeDTO>(
    requireGroups = listOf(UserGroup.ADMIN),
    serializer = WorkTypeDTO.serializer(),
    load = { dict.getWorkTypes()?.workTypeList },
    save = { dict.editWorkType(it) },
    addNew = { dict.addWorkType(WorkTypeDTO().apply { name = it }) },
) { props ->

    addText(FieldFlag.Nullable, "Название", WorkTypeDTO::name)
    addText(FieldFlag.ReadOnlyValue, "Н") { prefix() }
    addInt(FieldFlag.NotNull, "Сортировка", WorkTypeDTO::priority, options = TableColumnRenderOptions(defaultSortLegacy = true))
    addBoolean(FieldFlag.NotNull, "Показать", WorkTypeDTO::visible)

    addBoolean(FieldFlag.NotNull, "Применимо для Швеи", WorkTypeDTO::seamstress)
    addBoolean(FieldFlag.NotNull, "Применимо для Расклейщицы", WorkTypeDTO::gluer)
    addBoolean(FieldFlag.ReadOnlyProp, "Независимый пошив/расклейка", WorkTypeDTO::gluerAndSeamstress)

    addInt(FieldFlag.NotNull, "Вознаграждение Швеи", WorkTypeDTO::paySeamstress)

    addMultiEntity.invoke<ProductTypeDTO, List<ProductTypeDTO>>(
        flag = FieldFlag.Nullable,
        title = "Примеримо к продуктам",
        values = props.client.cache.productTypes,
        adapter = { ListToListAdapter(it) },
        prop = { this::productList },
        name = { this.name ?: "<null>" }
    )
    addEntity(FieldFlag.Nullable, "Необходим доп для работы", props.client.cache.additionalItems, WorkTypeDTO::price, { this.name ?: "<null>" })

}
