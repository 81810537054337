package ru.arty_bikini.crm_frontend.ui.input.types

import kotlin.reflect.KMutableProperty1
import kotlin.reflect.KProperty1

class PropertyReference<T : Any, P> constructor(
    val native: KProperty1<*, P>?,
    val previous: PropertyReference<T, *>?,
    val get: (T) -> P,
    val set: (T, P) -> Unit,
) {

    companion object {
        fun <T : Any, P> readOnly(native: KProperty1<T, P>) = PropertyReference<T, P>(native, null, { native.get(it) }, { _, _ -> /* no op */ })
        fun <T : Any, P> readOnlyAnonymous(getter: (T) -> P) = PropertyReference<T, P>(null, null, getter) { _, _ -> /* no op */ }
        fun <T : Any, P> anonymous(getter: (T) -> P, setter: (T, P) -> Unit) = PropertyReference<T, P>(null, null, getter, setter)
    }

    constructor(native: KMutableProperty1<T, P>) : this(
        native = native,
        previous = null,
        get = { entity -> native.get(entity) },
        set = { entity, value -> native.set(entity, value) }
    )
    constructor(name: String?, get: (T) -> P, set: (T, P) -> Unit) : this(null, null, get, set)

    val sortName: String? by lazy {
        sequence<String?> {
            var curr: PropertyReference<T, *>? = this@PropertyReference
            while (curr != null) {
                this.yield(curr.native?.name)

                curr = curr.previous
            }
        }
            .filterNotNull()
//            .mapIndexed { idx: Int, name: String -> idx to if (idx == 0) name else name.take(1) }
//            .sortedByDescending { it.first }
//            .map { it.second }
//            .filter { it.isNotEmpty() }
//            .map { it.replace(Regex("([a-z])([A-Z])"), "$1_$2").lowercase() }
            .filter { it.isNotEmpty() }
            .mapIndexed { index: Int, s: String -> index to s }
            .sortedByDescending { it.first }
            .joinToString(".") { it.second}
            .ifEmpty { null }
    }

    infix fun <P2> then(prop: KMutableProperty1<P, P2>) : PropertyReference<T, P2> {
        return PropertyReference<T, P2>(
            prop,
            this,
            { entity ->
                val part: P = this.get(entity)
                prop.get(part)
            },
            { entity, value ->
                val part = this.get(entity)
                prop.set(part, value)
            },
        )
    }


    infix fun or(default: () -> P & Any) : PropertyReference<T, P & Any> {
        return PropertyReference<T, P & Any>(
            null,
            this,
            { entity ->
                var value: P = this.get(entity)
                if (value == null) {
                    value = default()
                    this.set(entity, value)
                }
                value
            },
            { entity, value -> this.set(entity, value) },
        )
    }

}

infix fun <T : Any, P : Any, V : Any> PropertyReference<T, P>.adapt(converter: ValueConverter<V, P>): PropertyReference<T, V> {
    return PropertyReference<T, V>(
        null,
        this,
        { entity ->
            val value = this.get(entity)
            converter.fromProtocol(value)
        },
        { entity, value ->
            val protocol = converter.toProtocol(value)
            this.set(entity, protocol)
        },
    )
}

infix fun <T : Any, P : Any?, V : Any?> PropertyReference<T, P?>.adapt(converter: ValueConverter<V & Any, P & Any>): PropertyReference<T, V?> {
    return PropertyReference<T, V?>(
        null,
        this,
        { entity ->
            val value = this.get(entity)
            value?.let { converter.fromProtocol(it) }
        },
        { entity, value ->
            val protocol = value?.let { converter.toProtocol(it) }
            this.set(entity, protocol)
        },
    )
}

infix fun <T : Any, P : Any?, V : Any?> PropertyReference<T, out P?>.adaptReadonly(converter: ValueConverter<V & Any, P & Any>): PropertyReference<T, V?> {
    return PropertyReference<T, V?>(
        null,
        this,
        { entity -> this.get(entity)?.let { converter.fromProtocol(it) } },
        { entity, value -> /* no op (readonly) */ },
    )
}

infix fun <T : Any, P : Any?> PropertyReference<T, P?>.mapReadonly(converter: ((P & Any) -> P & Any)?): PropertyReference<T, P?> {
    if (converter == null) {
        return this
    }
    return PropertyReference<T, P?>(
        null,
        this,
        { entity -> this.get(entity)?.let { converter(it) } },
        { entity, value -> /* no op (readonly) */ },
    )
}


infix fun <T : Any, P1 : Any, P> KMutableProperty1<T, P1>.then(next: KMutableProperty1<P1, P>) : PropertyReference<T, P> = PropertyReference(this) then next
infix fun <T : Any, P1 : Any> KMutableProperty1<T, P1?>.or(default: () -> P1) : PropertyReference<T, P1> = PropertyReference(this) or default

infix fun <T : Any, P : Any, V : Any> KMutableProperty1<T, P>.adapt(converter: ValueConverter<V, P>): PropertyReference<T, V> = PropertyReference(this) adapt converter
infix fun <T : Any, P : Any?, V : Any?> KMutableProperty1<T, P?>.adapt(converter: ValueConverter<V & Any, P & Any>): PropertyReference<T, V?> = PropertyReference(this) adapt converter

