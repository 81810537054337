package ru.arty_bikini.crm_frontend.event

import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import react.useEffectOnce
import ru.arty_bikini.crm.dto.EntityDTO
import ru.arty_bikini.crm.dto.file.OrderFileDTO
import ru.arty_bikini.crm_frontend.ClientCore
import ru.arty_bikini.crm_frontend.network.Entity
import ru.arty_bikini.crm_frontend.ui.modal.EntityTrackRule
import ru.arty_bikini.crm_frontend.ui.modal.ModalConfig

class EventModule(val core: ClientCore) {

    init {
        GlobalScope.launch {
            while (true) {
                checkUpdate(activeModal)
                delay(1_000)
            }
        }
    }

    private var activeModal: ShowModalEvent<*>? = null

    private var onModalShowRequest: (ShowModalEvent<*>?) -> Unit = { }
    private var onImageShowRequest: (OrderFileDTO?) -> Unit = { }
    private var handlers: MutableMap<EventTypeId<*>, EventHandler<*>> = HashMap()

    fun <T> showModal(form: ModalConfig<T>, item: T) {

        val event = ShowModalEvent(form, item, activeModal)
        if (core.settings.debugLogs) {
            console.log("show ${event.title} ${event.previous?.title}")
        }

        activeModal = event
        onModalShowRequest(event)

        if (event.config.trackEntity?.get != null) {
            GlobalScope.launch {
                checkUpdate(event, force = true)
            }
        }

    }

    fun closeModal() {
        if (core.settings.debugLogs) {
            console.log("close ${activeModal?.title} ${activeModal?.previous?.title}")
        }
        val event = activeModal?.previous

        activeModal = event
        onModalShowRequest(event)
    }

    fun showImage(file: OrderFileDTO) {
        onImageShowRequest(file)
    }

    fun closeImage() {
        onImageShowRequest(null)
    }

    fun <E : Event<E>> fireEvent(event: E) {
        handlers[event.type]?.unsafeCast<EventHandler<E>>()?.handle(event)
    }

    fun <E : Event<E>> registerHandler(typeId: EventTypeId<E>, handler: EventHandler<E>) {
        handlers[typeId] = handler
    }

    fun registerModalHandlers(
        onModalShowRequest: (ShowModalEvent<*>?) -> Unit,
        onImageShowRequest: (OrderFileDTO?) -> Unit
    ) {

        this.onModalShowRequest = onModalShowRequest
        this.onImageShowRequest = onImageShowRequest

    }

    suspend fun <T> checkUpdate(activeModal: ShowModalEvent<T>?, force: Boolean = false) {
        val trackEntity = activeModal?.config?.trackEntity ?: return

        val getEntity = trackEntity.get ?: return
        val getVersion = trackEntity.version ?: return

        val old = activeModal.item
        val oldVersion = getVersion(old)

        if (old is EntityDTO && old.id == -1) {
            return // skip for history entities
        }

        val new = getEntity(core.network, old) ?: return
        val newVersion = getVersion(new)

        if ((newVersion > oldVersion || newVersion == oldVersion && force) && activeModal == this.activeModal) {

            val event = ShowModalEvent(activeModal.config, new, activeModal.previous)
            if (core.settings.debugLogs) {
                console.log("re-show ${event.title} ${event.previous?.title}")
            }

            this.activeModal = event
            onModalShowRequest(event)

        }

    }

    fun refreshData(type: String, data: List<Entity>) {

        val activeModal = activeModal ?: return
        val entity = activeModal.item ?: return

        val trackEntity: EntityTrackRule<Any> = activeModal.config.trackEntity as EntityTrackRule<Any>? ?: return

        if (trackEntity.type == type) {
            val currentId = trackEntity.id(entity)

            data.forEach {
                if (currentId == trackEntity.id(it)) {

                    val event = ShowModalEvent(activeModal.config as ModalConfig<Entity>, it, activeModal.previous)
                    if (core.settings.debugLogs) {
                        console.log("re-show ${event.title} ${event.previous?.title}")
                    }

                    this.activeModal = event
                    onModalShowRequest(event)

                    return
                }
            }
        }

    }

}

data class ShowModalEvent<T>(val config: ModalConfig<T>, val item: T, val previous: ShowModalEvent<*>?) {

    val title get() = config.title(item)

}
