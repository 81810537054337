package ru.arty_bikini.crm_frontend.ui.root

import kotlinx.browser.window
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import react.dom.html.ReactHTML.div
import react.useEffectOnce
import react.useState
import ru.arty_bikini.crm_frontend.ClientProps
import ru.arty_bikini.crm_frontend.ui.alert.AlertContainer
import ru.arty_bikini.crm_frontend.ui.bootstrap.*
import ru.arty_bikini.crm_frontend.ui.input.panel.InputPanel
import ru.arty_bikini.crm_frontend.ui.modal.ModalContainer
import ru.arty_bikini.crm_frontend.util.plus

val AppComponent = tryFC<ClientProps> { props ->

    var loaded: Boolean by useState(false)
    var loggedIn: Boolean by useState(false)

    useEffectOnce {

        GlobalScope.launch {
            val result = props.client.auth.tryReconnect()

            loaded = true
            loggedIn = result

        }

    }

    if (!loaded) {

        container {
            row(Bootstrap.Utils.justifyContentCenter) {
                colMd(4) {
                    alertInfo(Bootstrap.Utils.m4) {
                        +"Загрузка..."
                    }
                }
            }
        }

    } else if (!loggedIn) {

        LoginComponent {
            client = props.client
            onLoggedIn = {
                loggedIn = true
            }
        }
    } else {
        AlertContainer {
            client = props.client
        }
        ModalContainer {
            client = props.client
        }
        AppRouter {
            client = props.client
        }
    }

}
