package ru.arty_bikini.crm_frontend.event

class EventTypeId<E : Event<E>> {
    val id: Int = nextId++

    companion object {
        private var nextId = 0;
    }

    override fun equals(other: Any?): Boolean {
        if (this === other) return true
        if (other == null || this::class.js != other::class.js) return false

        other as EventTypeId<*>

        if (id != other.id) return false

        return true
    }

    override fun hashCode(): Int {
        return id
    }


}