package ru.arty_bikini.crm_frontend.util

import ru.arty_bikini.crm.dto.UserDTO
import ru.arty_bikini.crm.dto.enums.UserGroup
import ru.arty_bikini.crm.dto.orders.OrderDTO
import ru.arty_bikini.crm.dto.work.WorkDTO
import ru.arty_bikini.crm.dto.work.WorkTypeDTO
import ru.arty_bikini.crm_frontend.table.production.worksTMP

fun List<WorkTypeDTO>.filterBy(user: UserGroup, order: OrderDTO): Sequence<WorkTypeDTO> {
    return asSequence().filterBy(user, order)
}
fun List<WorkTypeDTO>.filterBy(user: UserDTO?, order: OrderDTO): Sequence<WorkTypeDTO> {
    return asSequence().filterBy(user?.group, order)
}

fun Sequence<WorkTypeDTO>.filterBy(user: UserGroup?, order: OrderDTO): Sequence<WorkTypeDTO> {

    return this
        .filter {
            when (user) {
                null -> true
                UserGroup.SEAMSTRESS -> it.seamstress
                UserGroup.GLUER -> it.gluer
                else -> false
            }
        }
        .filter {
//            console.log(arrayOf("${it.name}:${order.name}", it.price, order.price, it.price in order.price.orEmpty()))
            it.price == null || it.price in order.price.orEmpty()
        }
        .filter { it.productList.isNullOrEmpty() || it.productList?.contains(order.product) == true }

}

fun Sequence<WorkTypeDTO>.excludeDuplicateDefaults(user: UserDTO): Sequence<WorkTypeDTO> {
    if (user.group != UserGroup.GLUER) {
        return this
    }
    return this
        .filterNot { it.gluer && it.seamstress }

}

fun WorkTypeDTO.prefix(): String {
    return this.name
        ?.split(" ")
        ?.joinToString("") { it.substring(0, 1) }
        ?: "<null>"
}

fun WorkDTO.prefix(): String {
    return this.works?.joinToString("+") { it.prefix() } ?: "<null>"
}
