package ru.arty_bikini.crm_frontend.form.google

import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import react.useEffectOnce
import react.useState
import ru.arty_bikini.crm.dto.orders.OrderDTO
import ru.arty_bikini.crm.dto.orders.PersonalDataDTO
import ru.arty_bikini.crm.dto.orders.google.DataGoogleDTO
import ru.arty_bikini.crm_frontend.ui.bootstrap.inputGroup
import ru.arty_bikini.crm_frontend.ui.input.table.*
import ru.arty_bikini.crm_frontend.ui.input.types.or
import ru.arty_bikini.crm_frontend.ui.modal.createModal

val LinkGoogleDataToOrderForm = createModal<DataGoogleDTO>() { props ->

    var orders by useState<List<OrderDTO>> { listOf() }

    useEffectOnce {
        GlobalScope.launch {
            orders = props.client
                .network
                .order
                .getClients()
                ?.orders
                ?: emptyList()
        }
    }

    inputGroup {
        name("ФИО")
        value(props.entity.name)
    }

    inputGroup {
        name("Телефон")
        value(props.entity.telephon)
    }

    TablePanelEntityAuto(props.client, "f-t-google-link-to-order", orders, OrderDTO.serializer()) {

        addText(FieldFlag.ReadOnlyProp, "ФИО", OrderDTO::name)
        addText(FieldFlag.ReadOnlyProp, "Телефон", OrderDTO::personalData or ::PersonalDataDTO then PersonalDataDTO::telephon)
        addBoolean(FieldFlag.ReadOnlyValue, "Привязан") { dataGoogle != null }
        addButton(
            title = "Привязать",
            options = TableColumnRenderOptions(
                disabledIf = {
                    if (it.dataGoogle != null) {
                        EnabledResult(false, overrideAsBadge = "Уже привязан")
                    } else {
                        EnabledResult(true)
                    }
                }
            ),
            onClick = {
                GlobalScope.launch {
                    client.network
                        .orderData
                        .linkToGoogle(it, props.entity)
                }

                props.client.event.closeModal()
            }
        )

    }

}
