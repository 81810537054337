package ru.arty_bikini.crm_frontend.ui.input.types

interface CollectionAdapter<V : Any, P : Any, C : Collection<P>> {
    fun toCollection(data: List<V>): C
    fun fromCollection(data: C?): List<V>
}

class ListToSetAdapter<V : Any, P : Any>(val converter: ValueConverter<V, P>) : CollectionAdapter<V, P, Set<P>> {
    override fun toCollection(data: List<V>): Set<P> = data.asSequence().map { converter.toProtocol(it) }.toSet()

    override fun fromCollection(data: Set<P>?): List<V> = data?.map { converter.fromProtocol(it) } ?: emptyList()

}

class ListToListAdapter<V : Any, P : Any>(val converter: ValueConverter<V, P>) : CollectionAdapter<V, P, List<P>> {
    override fun toCollection(data: List<V>): List<P> = data.map { converter.toProtocol(it) }

    override fun fromCollection(data: List<P>?): List<V> = data?.map { converter.fromProtocol(it) } ?: emptyList()
}