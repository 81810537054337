package ru.arty_bikini.crm_frontend.table.production.worker

import csstype.ClassName
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import react.dom.html.ReactHTML.div
import ru.arty_bikini.crm.dto.enums.UserGroup
import ru.arty_bikini.crm.dto.enums.WorkProgress
import ru.arty_bikini.crm.dto.orders.OrderDTO
import ru.arty_bikini.crm.dto.orders.PersonalDataDTO
import ru.arty_bikini.crm.dto.work.IntervalDTO
import ru.arty_bikini.crm.dto.work.WorkDTO
import ru.arty_bikini.crm_frontend.form.main.WorkerMainForm
import ru.arty_bikini.crm_frontend.table.common.commonPage
import ru.arty_bikini.crm_frontend.table.production.WorkCell
import ru.arty_bikini.crm_frontend.ui.bootstrap.BootstrapColor
import ru.arty_bikini.crm_frontend.ui.bootstrap.alertDanger
import ru.arty_bikini.crm_frontend.ui.bootstrap.badge
import ru.arty_bikini.crm_frontend.ui.input.table.*
import ru.arty_bikini.crm_frontend.ui.input.types.or
import ru.arty_bikini.crm_frontend.util.clone
import ru.arty_bikini.crm_frontend.util.prefix
import ru.arty_bikini.crm_frontend.util.useAutoReload
import kotlin.js.Date

val OwnWorksTablePage = commonPage(
    requireGroups = listOf(UserGroup.ADMIN, UserGroup.SEAMSTRESS, UserGroup.GLUER)
) { props ->

    val user = props.client.auth.currentUser

    val works = useAutoReload {
        props.client
            .network
            .order
            .getClients()
            ?.orders
            ?.flatMap { it.works ?: emptyList() }
            ?.filter { it.user == user }
    }

    if (user == null || user.group !in listOf(UserGroup.SEAMSTRESS, UserGroup.GLUER)) {
        alertDanger {
            + "У данного пользователя не бывает работ"
        }

        return@commonPage
    }

    fun WorkDTO.nextProgress(): WorkProgress? = when (progress) {
        WorkProgress.FINISHED -> null
        WorkProgress.STARTED -> WorkProgress.FINISHED
        else -> WorkProgress.STARTED
    }

    TablePanelEntityAuto(props.client, "t-own-work", works, WorkDTO.serializer()) {

        addDate(FieldFlag.ReadOnlyProp, "Срок", WorkDTO::interval or ::IntervalDTO then IntervalDTO::dateFinish)
        addInt(FieldFlag.ReadOnlyProp, "Приоритет", WorkDTO::priority)
        addText(FieldFlag.ReadOnlyProp, "ФИО", WorkDTO::order or ::OrderDTO then OrderDTO::name)
        addText(
            flag = FieldFlag.ReadOnlyValue,
            title = "Работы",
            options = TableColumnRenderOptions(
                extraContent = { entity ->
                    div {
                        className = ClassName("vstack gap-1")

                        entity.works?.forEach {
                            badge(BootstrapColor.INFO, it.name ?: "<null>")
                        }
                    }
                }
            ),
            value = { "" }
        )
        addRaw("Статус") { badge(
            color = when (it.progress) {
                WorkProgress.FINISHED -> BootstrapColor.SUCCESS
                WorkProgress.STARTED -> BootstrapColor.WARNING
                else -> BootstrapColor.LIGHT
            },
            text = it.progress?.displayName ?: "Не начата"
        ) }
        addButton("Изменить", text = { "Изменить на: ${it.nextProgress()?.displayName ?: "Не начата"}" }) {
            val new = it.clone(WorkDTO.serializer())
            new.progress = it.nextProgress()

            GlobalScope.launch {
                props.client.network
                    .work
                    .editWorkProgress(new)
            }
        }
        addButton("Мерки") { it.order?.let { WorkerMainForm.openAction(props.client)(it) } }
        addDate(FieldFlag.ReadOnlyProp, "Дата отправки/примерки", WorkDTO::order or ::OrderDTO then OrderDTO::personalData or ::PersonalDataDTO then PersonalDataDTO::packageTime)

        addRaw(title = "Еще") { entity ->
            WorkCell {
                this.client = props.client
                this.work = entity
                this.reloadAll = {}
                this.changePriority = {}
            }
        }
    }

}
