package ru.arty_bikini.crm_frontend.table.production

import csstype.ClassName
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import react.dom.html.ReactHTML
import react.useEffect
import react.useEffectOnce
import react.useState
import ru.arty_bikini.crm.dto.enums.UserGroup
import ru.arty_bikini.crm.dto.orders.OrderDTO
import ru.arty_bikini.crm_frontend.ClientProps
import ru.arty_bikini.crm_frontend.ui.bootstrap.dropdownDivider
import ru.arty_bikini.crm_frontend.ui.bootstrap.dropdownHeader
import ru.arty_bikini.crm_frontend.ui.hint.note
import ru.arty_bikini.crm_frontend.ui.root.tryFC
import ru.arty_bikini.crm_frontend.util.StringUtils
import ru.arty_bikini.crm_frontend.util.filterBy
import ru.arty_bikini.crm_frontend.util.prefix
import ru.arty_bikini.crm_frontend.util.useCache
import kotlin.js.Date

external interface OrderWorkListProps : ClientProps {
    var order: OrderDTO
}

val OrderWorkList = tryFC<OrderWorkListProps> { props ->

    var order by useState(props.order)
    
    val workTypes = useCache(props.client.cache.workTypes)
    val workTypesGluer = workTypes.filterBy(UserGroup.GLUER, order).toList()
    val workTypesSeamstress = workTypes.filterBy(UserGroup.SEAMSTRESS, order).toList()
    
    useEffectOnce {
        GlobalScope.launch {
            props.client
                .network
                .order
                .getOrder(order)
                ?.orderDTO
                ?.let { order = it }
        }
    }

    dropdownHeader {
        +"Другие работы:"

        ReactHTML.div {
            className = ClassName("float-end")
            note(props.client, "s-work-cell-other", asBadge = true)
        }
    }
    dropdownHeader("Швеи:")

    order.worksTMP
        ?.asSequence()
        ?.filter { it.user?.group == UserGroup.SEAMSTRESS }
        ?.sortedBy { it.interval?.dateFinish }
        ?.forEach { work ->
            val date = StringUtils.printLocalDate(work.interval?.dateFinish)
            val date2 = StringUtils.printLocalDate(work.interval?.dateStart)
            val works: String?
            if (work.works?.containsAll(workTypesSeamstress) == true) {
                works = "Все"
            } else {
                works = work.prefix()
            }

            dropdownHeader(work.user?.name + "   "+ works + "    от: " + date2 + "  до: " + date)
        }

    dropdownDivider()
    dropdownHeader("Расклейка:")

    order.worksTMP
        ?.asSequence()
        ?.filter { it.user?.group == UserGroup.GLUER }
        ?.sortedBy { it.interval?.dateFinish }
        ?.forEach { work ->

            val dateFinish = StringUtils.printLocalDate(work.interval?.dateFinish)
            val dateStart = StringUtils.printLocalDate(work.interval?.dateStart)
            val works: String?
            if (work.works?.containsAll(workTypesGluer) == true) {
                works = "Все"
            } else {
                works = work.prefix()
            }

            dropdownHeader(work.user?.name + "   "+ works + "    от: " + dateStart + "  до: " + dateFinish)

        }

}
