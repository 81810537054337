package ru.arty_bikini.crm_frontend.ui.bootstrap

import csstype.BorderBlockColor
import csstype.ClassName
import csstype.Color
import emotion.react.css
import react.ChildrenBuilder
import react.dom.html.ReactHTML
import react.dom.html.ReactHTML.div
import ru.arty_bikini.crm_frontend.util.plus

inline fun ChildrenBuilder.cardLightShadow(
    noinline body: ChildrenBuilder.() -> Unit
) {

    div {
        className = ClassName("card card-light shadow m-3")

        div {
            className = ClassName("card-body")

            body()
        }
    }
}

fun ChildrenBuilder.cardGreenCollapse(
    header: String,
    classes: ClassName? = null,
    defaultVisible: Boolean = true,
    body: ChildrenBuilder.() -> Unit
) {
    cardCollapse(header, classes, defaultVisible, null, Color("#dadada"), Color("#d0e5db85"), body)
}

fun ChildrenBuilder.cardCollapse(
    header: String,
    classes: ClassName? = null,
    defaultVisible: Boolean = true,
    alertColor: BootstrapColor? = null,
    headerColor: Color? = null,
    bodyColor: Color? = null,
    body: ChildrenBuilder.() -> Unit
) {

    val collapseId = Collapse.nextId()

    div {
        className = ClassName("card") + alertColor?.let { ClassName("alert-${it.suffix}") }


        div {


            css {
                backgroundColor = headerColor
            }

            className = className + ClassName("card-header pb-1")

            + header

            this.asDynamic()["data-bs-toggle"]="collapse"
            this.asDynamic()["data-bs-target"]="#$collapseId"

            ReactHTML.span {
                className = ClassName("badge bg-secondary float-end")

                + "Скрыть"
            }
        }

        div {

            css {
                backgroundColor = bodyColor
            }

            className = className + ClassName("card-body mb-0 collapse " + if (defaultVisible) "show" else "") + classes

            id = collapseId

            body(this)
        }
    }

}
