package ru.arty_bikini.crm.dto.packet.dict

import kotlinx.serialization.Serializable
import ru.arty_bikini.crm.dto.EntityDTO
import ru.arty_bikini.crm.dto.packet.BaseResponse

@Serializable
class GetDictEntityListResponse<T : EntityDTO>(
    override val ok: Boolean,
    override val statusCode: String,
    override val displayMessage: String? = null,
    val data: List<T>? = null
) : BaseResponse()


@Serializable
class AddDictEntityRequest<T : EntityDTO>(
    val data: T
)

@Serializable
class EditDictEntityRequest<T : EntityDTO>(
    val data: T
)

@Serializable
class AddDictEntityResponse(
    override val ok: Boolean,
    override val statusCode: String,
    override val displayMessage: String? = null
) : BaseResponse()
