package ru.arty_bikini.crm_frontend.ui.md

import csstype.ClassName
import kotlinx.browser.window
import org.w3c.dom.HTMLDivElement
import react.ChildrenBuilder
import react.Props
import react.createRef
import react.dom.html.ReactHTML.div
import react.useEffect
import ru.arty_bikini.crm.dto.file.FileDTO
import ru.arty_bikini.crm_frontend.ClientCore
import ru.arty_bikini.crm_frontend.ClientProps
import ru.arty_bikini.crm_frontend.ui.bootstrap.Bootstrap
import ru.arty_bikini.crm_frontend.ui.root.tryFC

external interface MarkdownComponentProps : ClientProps {
    var data: String
    var files: List<FileDTO>
}

private val MarkdownComponent = tryFC<MarkdownComponentProps> { props ->

    val ref = createRef<HTMLDivElement>()

    useEffect(props.data) {
        try {
            ref.current?.apply {
                var rawHTML: String = window.asDynamic().marked.parse(props.data)

//                console.log(rawHTML)

                rawHTML = rawHTML.replace("<table>\n<thead>\n<tr>\n<th>!nw", "<table class=\"${Bootstrap.tableDefault} table-bordered position-relative text-nowrap\">\n<thead>\n<tr>\n<th>")
                rawHTML = rawHTML.replace("<table>", "<table class=\"${Bootstrap.tableDefault} table-bordered position-relative\">")
                rawHTML = rawHTML.replace("<th>!nw", "<th class=\"text-nowrap\">")
                rawHTML = rawHTML.replace("<td>!nw", "<td class=\"text-nowrap\">")
                rawHTML = rawHTML.replace(Regex("!img(\\d{1,10})")) { result ->
                    val imgId = result.groupValues.getOrNull(1)?.toIntOrNull()
                    val img = props.files.firstOrNull { it.id == imgId } ?: props.files.firstOrNull()
                    val location = img?.location ?: "\\img\\$imgId.png"
                    return@replace "<img src=\"$location\" alt=\"Файл не найден\">"
                }
                rawHTML = rawHTML.replace(
                    Regex("<th>!dc([^<]+)-([^<]+)</th>"),
                    """
                        <th class="diagonal-cell">
                            <div class="dc-main">
                                <span class="dc-bottom">$1</span>
                                <span class="dc-top">$2</span>
                                <div class="dc-line"></div>
                            </div>
                        </th>
                    """.trimIndent()
                )

                rawHTML = rawHTML.replace("<thead>", "<thead style=\"top: 0px; left: 0px;\" class=\"position-sticky\">")
                rawHTML = rawHTML.replace("<tr>\n<th>", "<tr><th style=\"top: 0px; left: 0px;\" class=\"position-sticky bg-white\">")
                rawHTML = rawHTML.replace("<tr>\n<td>", "<tr><td style=\"top: 0px; left: 0px;\" class=\"position-sticky bg-white\">")
                rawHTML = rawHTML.replace("<tr>\n<th class=\"", "<tr><th style=\"top: 0px; left: 0px;\" class=\"position-sticky bg-white ")
                rawHTML = rawHTML.replace("<tr>\n<td class=\"", "<tr><td style=\"top: 0px; left: 0px;\" class=\"position-sticky bg-white ")

                innerHTML = rawHTML
            }
        } catch (e: Throwable) {
            e.printStackTrace()
        }
    }

    div {
        className = ClassName("w-100 h-100")

        this.ref = ref


    }
}

fun ChildrenBuilder.MarkdownComponent(clientCore: ClientCore, data: String, files: List<FileDTO>) {
    MarkdownComponent {
        this.client = clientCore
        this.data = data
        this.files = files
    }
}
