package ru.arty_bikini.crm_frontend.ui.modal

import csstype.ClassName
import react.*
import react.dom.html.ReactHTML.button
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h5
import react.dom.html.ReactHTML.img
import ru.arty_bikini.crm.dto.file.OrderFileDTO
import ru.arty_bikini.crm_frontend.ClientCore
import ru.arty_bikini.crm_frontend.ClientProps
import ru.arty_bikini.crm_frontend.event.ShowModalEvent
import ru.arty_bikini.crm_frontend.ui.bootstrap.modal
import ru.arty_bikini.crm_frontend.ui.root.tryFC

val ModalContainer  = tryFC<ClientProps> { props ->

    var modal by useState<ShowModalEvent<*>?> { null }
    var image by useState<OrderFileDTO?> { null }

    useEffectOnce {

        props.client.event.registerModalHandlers(
            onModalShowRequest = { event -> modal = event },
            onImageShowRequest = { event -> image = event },
        )

    }


    val modalNow = modal
    modal("main", show = modal != null, onClose = { props.client.event.closeModal() }) {

        if (modalNow != null) {

            div {
                className = ClassName("modal-header")


                h5 {
                    className = ClassName("modal-title")

                    + modalNow.title
                }
                button {
                    className = ClassName("btn-close")

                    onClick = { props.client.event.closeModal() }

                }
            }

            div {
                className = ClassName("modal-body vstack gap-2")

                this.renderModal(props.client, modalNow)

            }
        }
    }

    val imageNow = image
    modal("image", show = image != null, onClose = { props.client.event.closeImage() }) {

        if (imageNow != null) {

            div {
                className = ClassName("card bg-dark p-3")

                img {
                    src = imageNow.file?.location
                }

                onClick = {
                    props.client.event.closeImage()
                }
            }

        }
    }

}

fun <T> ChildrenBuilder.renderModal(client: ClientCore, event: ShowModalEvent<T>?) = event?.let {

    event.config.form {
        this.client = client
        this.entity = event.item
    }

}
