package ru.arty_bikini.crm_frontend.util

import kotlinx.serialization.KSerializer
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.json.Json

private val json = Json

fun <T> T.clone(serializer: KSerializer<T>): T {
    val string = json.encodeToString(serializer, this)
    return json.decodeFromString(serializer, string)
}