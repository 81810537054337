package ru.arty_bikini.crm_frontend

import ru.arty_bikini.crm.dto.UserDTO
import ru.arty_bikini.crm_frontend.network.NetworkModule

class CacheModule(network: NetworkModule) {

    private val dict = network.dict
    private val stone = network.stone
    private val orderData = network.orderData
    private val auth = network.auth

    private val all: MutableList<SimpleCache<*>> = ArrayList()

    //dict
    val trainers = SimpleCache(this, emptyList()) { dict.getTrainers()?.trainerList?.sortedBy { it.priority }?.filter { it.visible } }
    val productTypes = SimpleCache(this, emptyList()) { dict.getProductTypes()?.productTypeDTOList?.sortedBy { it.priority }?.filter { it.visible } }
    val rhinestoneTypes = SimpleCache(this, emptyList()) { dict.getRhinestoneType()?.rhinestoneTypeDTOList?.sortedBy { it.priority }?.filter { it.visible } }
    val calcPresets = SimpleCache(this, emptyList()) { stone.getPresets()?.calcPresetDTOList?.sortedBy { it.priority } }
    val expressTypes = SimpleCache(this, emptyList()) { dict.getExpress()?.expressDTOList?.sortedBy { it.minDays } }
    val strapsTypes = SimpleCache(this, emptyList()) { dict.getStraps()?.strapsDTOList?.sortedBy { it.priority }?.filter { it.visible } }
    val additionalItems = SimpleCache(this, emptyList()) { dict.getPrice()?.priceDTOS?.sortedBy { it.priority }?.filter { it.visible } }
    val workTypes = SimpleCache(this, emptyList()) { dict.getWorkTypes()?.workTypeList?.sortedBy { it.priority }?.filter { it.visible } }
    val notes = SimpleCache(this, emptyList()) { dict.getNotes().body }

    val orderDataTypes = SimpleCache(this, emptyList()) { orderData.getDataTypes()?.types }
    val measureVariants = SimpleCache(this, emptyList()) { orderData.getMeasureVariants()?.measureVariantsDTOList?.sortedBy { it.priority } }

    val users = SimpleCache(this, emptyList<UserDTO>()) { auth.getUsers()?.users?.sortedBy { it.priority } }

    fun register(cache: SimpleCache<*>) {
        all.add(cache)
    }

    fun invalidateAll() {
        all.forEach { it.invalidate() }
    }
}
