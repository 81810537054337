package ru.arty_bikini.crm_frontend.table.config

import ru.arty_bikini.crm.dto.dict.ProductTypeDTO
import ru.arty_bikini.crm.dto.enums.UserGroup
import ru.arty_bikini.crm_frontend.table.common.commonPage
import ru.arty_bikini.crm_frontend.ui.input.table.*
import ru.arty_bikini.crm_frontend.util.useAutoReload

val ProductTypeTablePage = commonPage(
    requireGroups = listOf(UserGroup.ADMIN, UserGroup.TANYA)
) { props ->


    val all = useAutoReload<ProductTypeDTO> {
        props
            .client
            .network
            .dict
            .getProductTypes()
            ?.productTypeDTOList
    }

    TablePanelEntityAuto(props.client, "t-dict-product", all, ProductTypeDTO.serializer()) {
    
        save = {
            props.client
                .network
                .dict
                .editProductType(it)

            props.client.cache.invalidateAll()
        }
    
        addNew = { name ->
            props.client
                .network
                .dict
                .editProductType(ProductTypeDTO().apply {
                    this.name = name
                })

            props.client.cache.invalidateAll()
        }


        addText(FieldFlag.Nullable, "Название", ProductTypeDTO::name)
        addCost(FieldFlag.NotNull, "Цена", ProductTypeDTO::paymentNonStone)
        addInt(FieldFlag.NotNull, "Сортировка", ProductTypeDTO::priority, options = TableColumnRenderOptions(defaultSortLegacy = true))
        addBoolean(FieldFlag.NotNull, "Показать", ProductTypeDTO::visible)
    }
}
