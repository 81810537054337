package ru.arty_bikini.crm_frontend.network

import csstype.Page
import ru.arty_bikini.crm.dto.PageDTO
import ru.arty_bikini.crm.dto.other.HistoryDTO
import ru.arty_bikini.crm.dto.other.NoteDTO
import ru.arty_bikini.crm.dto.packet.auth.GetHistoryRequest
import ru.arty_bikini.crm.dto.packet.auth.GetHistoryResponse
import ru.arty_bikini.crm.dto.packet.auth.HistoryFilter
import ru.arty_bikini.crm.dto.packet.dict.GetNoteResponse
import ru.arty_bikini.crm_frontend.ClientCore

class HistoryService(clientCore: ClientCore) : BaseService(clientCore) {

    suspend fun get(filter: HistoryFilter): ServerResponse<PageDTO<HistoryDTO>> {
        return call<GetHistoryResponse, GetHistoryRequest>(url = "/api/history/get-history", body = GetHistoryRequest(filter)).map { historyDTOList }
    }
}
