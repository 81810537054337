@file:Suppress("UNUSED_PARAMETER")

package ru.arty_bikini.crm_frontend.ui.input.table

import ru.arty_bikini.crm_frontend.ui.input.types.*
import kotlin.reflect.KMutableProperty0
import kotlin.reflect.KMutableProperty1
import kotlin.reflect.KProperty0
import kotlin.reflect.KProperty1

class InputTableColumnBuilder<T : Any, V : Any, P : Any> constructor(val type: TablePanelColumnType, val props: TablePanelProps<T>, val converter: ValueConverter<V, P>) {
    operator fun invoke(
        flag: FieldFlag.NotNull,
        title: String,
        value: PropertyReference<T, P>,
        options: TableColumnRenderOptions<T> = TableColumnRenderOptions(),
    ) {
        props.columns = props.columns + TablePanelColumnProps<T, V>(
            title = title,
            type = type,
            allowNull = true,
            readOnly = false,
            options = options,
            prop = value adapt converter,
            variants = null,
            converter = converter,
        )
    }
    operator fun invoke(
        flag: FieldFlag.NotNull,
        title: String,
        value: KMutableProperty1<T, P>,
        options: TableColumnRenderOptions<T> = TableColumnRenderOptions(),
    ) {
        invoke(flag, title, PropertyReference(value), options)
    }

    operator fun invoke(
        flag: FieldFlag.Nullable,
        title: String,
        value: PropertyReference<T, P?>,
        options: TableColumnRenderOptions<T> = TableColumnRenderOptions(),
    ) {
        props.columns = props.columns + TablePanelColumnProps<T, V?>(
            title = title,
            type = type,
            allowNull = true,
            readOnly = false,
            options = options,
            prop = value adapt converter,
            variants = null,
            converter = converter,
        )
    }

    operator fun invoke(
        flag: FieldFlag.Nullable,
        title: String,
        value: KMutableProperty1<T, P?>,
        options: TableColumnRenderOptions<T> = TableColumnRenderOptions(),
    ) {
        invoke(flag, title, PropertyReference(value), options)
    }

    operator fun invoke(
        flag: FieldFlag.ReadOnlyProp,
        title: String,
        value: PropertyReference<T, out P?>,
        options: TableColumnRenderOptions<T> = TableColumnRenderOptions(),
        thenMap: ((V) -> V)? = null,
    ) {
        props.columns = props.columns + TablePanelColumnProps<T, V?>(
            title = title,
            type = type,
            allowNull = true,
            readOnly = true,
            options = options,
            prop = value adaptReadonly converter mapReadonly thenMap,
            variants = null,
            converter = converter,
        )
    }

    operator fun invoke(
        flag: FieldFlag.ReadOnlyProp,
        title: String,
        value: KProperty1<T, P?>,
        options: TableColumnRenderOptions<T> = TableColumnRenderOptions(),
        thenMap: ((V) -> V)? = null,
    ) {
        invoke(flag, title, PropertyReference.readOnly(value), options, thenMap)
    }

    operator fun invoke(
        flag: FieldFlag.ReadOnlyValue,
        title: String,
        options: TableColumnRenderOptions<T> = TableColumnRenderOptions(),
        value: T.() -> P?
    ) {
        props.columns = props.columns + TablePanelColumnProps<T, V?>(
            title = title,
            type = type,
            allowNull = true,
            readOnly = true,
            options = options,
            prop = PropertyReference.readOnlyAnonymous(value) adapt converter,
            variants = null,
            converter = converter,
        )
    }

}
