package ru.arty_bikini.crm_frontend.ui.select2

import kotlinx.js.jso
import org.w3c.dom.HTMLElement

external fun jQuery(element: HTMLElement): jQueryItem

external interface jQueryItem {

    fun select2(options: Select2Options = definedExternally)

    fun select2(action: String): dynamic

    fun on(event: String, action: (event: dynamic) -> Unit)

}

fun jQueryItem.select2(init: Select2Options.() -> Unit) {
    select2(jso(init))
}

