package ru.arty_bikini.crm_frontend.form.calc

import ru.arty_bikini.crm.dto.dict.RhinestoneTypeDTO
import ru.arty_bikini.crm.dto.enums.CalcPresetRuleType
import ru.arty_bikini.crm.dto.orders.stone.CalcPresetRuleDTO

class CalculatorState(
    val amount: Map<Int, Int> = emptyMap(),
    val rules: Map<Int, CalcPresetRuleDTO> = emptyMap(),

    val remaining: Int = 0,
)

fun recalculate(totalMoney: Int, rules: Map<Int, CalcPresetRuleDTO>): CalculatorState {

    val amount = HashMap<Int, Int>()

    var remaining = totalMoney

    fun updateAmountByPrice(rule: CalcPresetRuleDTO, totalPrice: Int) {
        val stoneId = rule.stone?.id ?: return

        if (totalPrice > 0) {
            var unit100Price = rule.stone?.price ?: 1
            if (unit100Price < 1) {
                unit100Price = 1
            }

            val count = totalPrice * 100 / unit100Price
            val realPart = count * unit100Price / 100

            remaining -= realPart
            amount[stoneId] = count + (amount[stoneId] ?: 0)
        }
    }

    fun updateAmountByCount(rule: CalcPresetRuleDTO, count: Int) {
        val stoneId = rule.stone?.id ?: return

        var unit100Price = rule.stone?.price ?: 1
        if (unit100Price < 1) {
            unit100Price = 1
        }

        if (count > 0) {
            val realPart = count * unit100Price / 100

            remaining -= realPart
            amount[stoneId] = count + (amount[stoneId] ?: 0)
        }
    }

    rules.values
        .filter { it.type == CalcPresetRuleType.COST_PERCENT }
        .forEach {
            val part = (it.value ?: 0) * totalMoney / 100
            updateAmountByPrice(it, part)
        }

    rules.values
        .filter { it.type == CalcPresetRuleType.COST_AMOUNT }
        .forEach { updateAmountByPrice(it, it.value ?: 0) }


    rules.values
        .filter { it.type == CalcPresetRuleType.COUNT }
        .forEach { updateAmountByCount(it, it.value ?: 0) }

    val auto = rules.values
        .filter { it.auto }

    if (auto.size > 0 && remaining > 0) {
        val part = remaining / auto.size

        auto.forEach { updateAmountByPrice(it, part) }
    }

    return CalculatorState(amount, rules, remaining)
}

fun CalculatorState.getAmount(type: RhinestoneTypeDTO): Int {
    return amount[type.id] ?: 0
}

fun CalculatorState.totalStones() = amount.values.sum()

fun CalcPresetRuleDTO.copy(
    auto: Boolean = this.auto,
    type: CalcPresetRuleType = this.type,
    value: Int = this.value,
): CalcPresetRuleDTO {
    return CalcPresetRuleDTO().apply {
        this.stone = this@copy.stone
        this.auto = auto
        this.type = type
        this.value = value
    }
}
