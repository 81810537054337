package ru.arty_bikini.crm_frontend.table.config

import ru.arty_bikini.crm.dto.dict.StrapsDTO
import ru.arty_bikini.crm_frontend.table.common.dictTablePage
import ru.arty_bikini.crm_frontend.ui.input.table.*

val StrapsTablePage = dictTablePage(
    serializer = StrapsDTO.serializer(),
    load = { dict.getStraps()?.strapsDTOList },
    save = { dict.editStraps(it) },
    addNew = { dict.addStraps(StrapsDTO().apply {
        this.name = it
    }) },
) {

    addText(FieldFlag.Nullable, "Название", StrapsDTO::name)
    addCost(FieldFlag.NotNull, "Цена", StrapsDTO::count)
    addInt(FieldFlag.NotNull, "Сортировка", StrapsDTO::priority, options = TableColumnRenderOptions(defaultSortLegacy = true))
    addBoolean(FieldFlag.NotNull, "Показать", StrapsDTO::visible)

}
