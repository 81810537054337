package ru.arty_bikini.crm_frontend.table.report

import ru.arty_bikini.crm.dto.enums.UserGroup
import ru.arty_bikini.crm.dto.enums.personalData.ClientType
import ru.arty_bikini.crm.dto.filter.OrderFilter
import ru.arty_bikini.crm.dto.orders.OrderDTO
import ru.arty_bikini.crm.dto.orders.PersonalDataDTO
import ru.arty_bikini.crm_frontend.form.main.ArchiveMainForm
import ru.arty_bikini.crm_frontend.table.common.commonPage
import ru.arty_bikini.crm_frontend.ui.bootstrap.BootstrapColor
import ru.arty_bikini.crm_frontend.ui.bootstrap.alert
import ru.arty_bikini.crm_frontend.ui.bootstrap.cardLightShadow
import ru.arty_bikini.crm_frontend.ui.input.table.*
import ru.arty_bikini.crm_frontend.ui.input.table.data.DataSourceLoadingState
import ru.arty_bikini.crm_frontend.ui.input.table.data.month.MonthPagerDataProviderOrder
import ru.arty_bikini.crm_frontend.ui.input.types.or
import ru.arty_bikini.crm_frontend.util.personalData
import ru.arty_bikini.crm_frontend.util.useCache

val MoneyTablePage = commonPage(
    requireGroups = listOf(UserGroup.ADMIN, UserGroup.TANYA)
) { props ->

    MonthPagerDataProviderOrder(
        client = props.client,
        defaultFilter = ::OrderFilter
    ) { data ->

        cardLightShadow {
            if (data.state != DataSourceLoadingState.LOADED) {
                alert(BootstrapColor.WARNING) { +"Идет загрузка данных" }
            } else {
                alert(BootstrapColor.SUCCESS) {}
            }

            if (data.state == DataSourceLoadingState.LOADED) {

                MoneyReport {
                    this.client = props.client
                    this.data = data.currentData
                }

            }

        }

        cardLightShadow {
            TablePanelOrderSource(props.client, data, "t-r-money") {
                addText(FieldFlag.ReadOnlyProp, "ФИО", OrderDTO::name, TableColumnRenderOptions(editButtonForm = ArchiveMainForm))
                addBoolean(FieldFlag.ReadOnlyProp, "Архив", OrderDTO::archive)
                addSelect(FieldFlag.ReadOnlyProp, "Тип", ClientType.values(), OrderDTO::type)
                addEntity(FieldFlag.ReadOnlyProp, "Товар", props.client.cache.productTypes, OrderDTO::product, { name ?: "<Нет>" })
                addDateTime(FieldFlag.ReadOnlyProp, "Дата появления лида", OrderDTO::personalData or ::PersonalDataDTO then PersonalDataDTO::createdTime, TableColumnRenderOptions(defaultSortLegacy = true))
                addText(FieldFlag.ReadOnlyProp, "Предоплата", OrderDTO::personalData or ::PersonalDataDTO then PersonalDataDTO::prepayment)
                addInt(FieldFlag.ReadOnlyProp, "Сумма заказа", OrderDTO::personalData or ::PersonalDataDTO then PersonalDataDTO::payment)
                addInt(FieldFlag.ReadOnlyValue, "Скидка клиенту") { trainerDiscount }
                addInt(FieldFlag.ReadOnlyValue, "Вознаграждение тренеру") { trainerPay }
                addInt(FieldFlag.ReadOnlyValue, "ЗП за пошив") { getWorkMainPay() }
                addInt(FieldFlag.ReadOnlyValue, "ЗП за допы") { getWorkExtraPay() }
                addInt(FieldFlag.ReadOnlyValue, "ЗП за расклейку") { getWorkStonePay() }
                addInt(FieldFlag.ReadOnlyValue, "Материалы: стразы") { getResourceStoneCostRub() }
                addInt(FieldFlag.ReadOnlyValue, "Цена - Зарплаты") {
                    personalData().payment - trainerDiscount - trainerPay - getWorkMainPay() - getWorkExtraPay() - getWorkStonePay() - getResourceStoneCostRub()
                }
            }
        }
    }
}
