package ru.arty_bikini.crm_frontend.ui.input.table

import csstype.ClassName
import react.ChildrenBuilder
import ru.arty_bikini.crm.dto.enums.SortDirection
import ru.arty_bikini.crm_frontend.ui.bootstrap.BootstrapColor
import ru.arty_bikini.crm_frontend.ui.modal.ModalConfig

data class TableColumnRenderOptions<T : Any>(

    // Sizing
    val minWidthPx: Int? = 100,
    val maxWidthPx: Int? = 500,

    val widthPercent: Int? = null,

    // Content modifiers
    val editButtonForm: ModalConfig<T>? = null,
    val backgroundColor: (T) -> BootstrapColor? = { null },
    val disabledIf: (T) -> EnabledResult = { EnabledResult(true) },
    val extraContent: ChildrenBuilder.(T) -> Unit = {},
    val extraClasses: (T) -> ClassName? = { null },

    // grouping & sorting
    val groupNextColumns: Int = 0,
    @Deprecated("Use defaultSortDirection")
    val defaultSortLegacy: Boolean = false,
    val defaultSortDirection: SortDirection? = null,
    val specialSort: Map<String, Comparator<T>> = emptyMap(),

)

data class EnabledResult(
    val enabled: Boolean,
    val overrideAsBadge: String? = null,
    val overrideAsBadgeColor: BootstrapColor = BootstrapColor.SECONDARY,
    val overrideAsBadgeWrap: Boolean = false
)
