package ru.arty_bikini.crm_frontend.form.google

import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import react.useEffectOnce
import react.useState
import ru.arty_bikini.crm.dto.orders.OrderDTO
import ru.arty_bikini.crm.dto.orders.google.DataGoogleDTO
import ru.arty_bikini.crm_frontend.ui.bootstrap.inputGroup
import ru.arty_bikini.crm_frontend.ui.input.table.*
import ru.arty_bikini.crm_frontend.ui.modal.createModal
import ru.arty_bikini.crm_frontend.util.personalData

val LinkOrderToGoogleDataForm = createModal<OrderDTO>() { props ->

    var googleData by useState<List<DataGoogleDTO>> { listOf() }

    useEffectOnce {
        GlobalScope.launch {
            googleData = props.client
                .network
                .orderData
                .importResult()
                ?.dataGoogleDTOList
                ?: emptyList()
        }
    }

    inputGroup {
        name("ФИО")
        value(props.entity.name)
    }

    inputGroup {
        name("Телефон")
        value(props.entity.personalData().telephon)
    }

    TablePanelEntityAuto(props.client, "f-t-order-link-to-google", googleData, DataGoogleDTO.serializer()) {

        addText(FieldFlag.ReadOnlyProp, "ФИО", DataGoogleDTO::name, TableColumnRenderOptions())
        addText(FieldFlag.ReadOnlyProp, "Телефон", DataGoogleDTO::telephon, TableColumnRenderOptions())
        addDateTime(FieldFlag.ReadOnlyProp, "Дата добавления", DataGoogleDTO::dateGoogle, TableColumnRenderOptions(defaultSortLegacy = true))

        addButton("Привязать") {
            GlobalScope.launch {
                client.network
                    .orderData
                    .linkToGoogle(props.entity, it)
            }

            props.client.event.closeModal()
        }

    }

}
