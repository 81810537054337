package ru.arty_bikini.crm_frontend.table.config

import ru.arty_bikini.crm.dto.dict.RhinestoneTypeDTO
import ru.arty_bikini.crm.dto.enums.UserGroup
import ru.arty_bikini.crm_frontend.table.common.commonPage
import ru.arty_bikini.crm_frontend.ui.input.table.*
import ru.arty_bikini.crm_frontend.util.useAutoReload

val RhinestoneTypesTablePage = commonPage(
    requireGroups = listOf(UserGroup.ADMIN, UserGroup.TANYA)
) { props ->

    val all = useAutoReload<RhinestoneTypeDTO> {
        props
            .client
            .network
            .dict
            .getRhinestoneType()
            ?.rhinestoneTypeDTOList
    }

    TablePanelEntityAuto(props.client, "t-dict-stone", all, RhinestoneTypeDTO.serializer()) {

        save = {
            props.client
                .network
                .dict
                .editRhinestoneType(it)

            props.client.cache.invalidateAll()
        }
        
        addNew = { name ->
            props.client
                .network
                .dict
                .editRhinestoneType(RhinestoneTypeDTO().apply {
                    this.manufacturer = name
                })

            props.client.cache.invalidateAll()
        }
        
        addText(FieldFlag.Nullable, "Производитель", RhinestoneTypeDTO::manufacturer)
        addText(FieldFlag.Nullable, "Размер страз", RhinestoneTypeDTO::sizeType)
        addInt(FieldFlag.NotNull, "Сортировка", RhinestoneTypeDTO::priority, options = TableColumnRenderOptions(defaultSortLegacy = true))
        addBoolean(FieldFlag.NotNull, "Показывать", RhinestoneTypeDTO::visible)
        addCost(FieldFlag.NotNull, "Вознаграждение расклейщице", RhinestoneTypeDTO::payGluerCount)
        addCost(FieldFlag.NotNull,"Цена (в копейках)", RhinestoneTypeDTO::price)
        addCost(FieldFlag.NotNull,"Закупочная цена (в копейках)", RhinestoneTypeDTO::purchasePrice)

    }
    
    
}
