package ru.arty_bikini.crm_frontend.table.production.worker

import ru.arty_bikini.crm.dto.orders.OrderDTO
import ru.arty_bikini.crm.dto.orders.PersonalDataDTO
import ru.arty_bikini.crm.dto.work.WorkDTO
import ru.arty_bikini.crm_frontend.ClientProps
import ru.arty_bikini.crm_frontend.form.main.WorkerMainForm
import ru.arty_bikini.crm_frontend.ui.input.table.*
import ru.arty_bikini.crm_frontend.ui.input.types.or
import ru.arty_bikini.crm_frontend.ui.root.tryFC
import ru.arty_bikini.crm_frontend.util.useAutoReload

val WorkerClientTable = tryFC<ClientProps> { props ->

    val orders = useAutoReload<OrderDTO> {
        props
            .client
            .network
            .order
            .getClients()
            ?.orders
    }


    TablePanelOrderAuto(props.client, "t-production-client-short", orders) {

        addText(
            flag = FieldFlag.ReadOnlyProp,
            title = "ФИО",
            OrderDTO::name,
            options = TableColumnRenderOptions(
                editButtonForm = WorkerMainForm,
            ),
        )
        addDate(FieldFlag.ReadOnlyProp, "Дата отправки/примерки", OrderDTO::personalData or ::PersonalDataDTO then PersonalDataDTO::packageTime)


    }
}