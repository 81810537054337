package ru.arty_bikini.crm_frontend.table.production

import csstype.ClassName
import react.dom.html.ReactHTML.div
import ru.arty_bikini.crm.dto.enums.UserGroup
import ru.arty_bikini.crm_frontend.table.common.commonPage

//Страница производства и клиентов
val ProductionTablePage = commonPage(
    requireGroups = listOf(UserGroup.ADMIN, UserGroup.TANYA, UserGroup.MANAGER)
) { props ->

    div {

        className = ClassName("hstack gap-1 align-items-start")

        ProductionTable {
            this.client = props.client
        }

       ClientTable {
           this.client = props.client
       }

    }

}
