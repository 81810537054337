package ru.arty_bikini.crm_frontend.util

import kotlin.js.Date

object DateUtils {

    val loaded = now()

    fun now() = DateKt(Date.now())

    fun fromNullable(timestamp: Long?) = timestamp?.let { DateKt(it) }

}

class DateKt(date: Date) {

    constructor(timestamp: Double) : this(Date(timestamp))
    constructor(timestamp: Long) : this(Date(timestamp))

    private val date = Date(date.getTime())

    val monthStart by lazy { DateKt(Date.UTC(date.getFullYear(), date.getMonth(), 1, 0, 0, 10)) }
    val monthEnd by lazy { DateKt(Date.UTC(date.getFullYear(), date.getMonth() + 1, 1, 0, 0, -10)) }

    val previousMonth by lazy { DateKt(Date.UTC(date.getFullYear(), date.getMonth() - 1, 1, 1, 0, 0)) }
    val nextMonth by lazy { DateKt(Date.UTC(date.getFullYear(), date.getMonth() + 1, 1, 1, 0, 0)) }

    fun toTimestamp(): Long = date.getTime().toLong()

    fun sameDay(other: DateKt): Boolean {
        return this.date.getFullYear() == other.date.getFullYear() && this.date.getMonth() == other.date.getMonth() && this.date.getDate() == other.date.getDate()
    }

    fun sameMonth(other: DateKt): Boolean {
        return this.date.getFullYear() == other.date.getFullYear() && this.date.getMonth() == other.date.getMonth()
    }

    operator fun plus(intervalKt: IntervalKt): DateKt {
        return DateKt(toTimestamp() + intervalKt.toMilisecondsLong())
    }

    operator fun minus(intervalKt: IntervalKt): DateKt {
        return DateKt(toTimestamp() - intervalKt.toMilisecondsLong())
    }

    override fun equals(other: Any?): Boolean {
        if (other !is DateKt) return false

        return toTimestamp() == other.toTimestamp()
    }

    override fun hashCode(): Int {
        var result = toTimestamp().toInt()
        result = 31 * result + (toTimestamp() shr 32).toInt()
        return result
    }


}

enum class DateUnitKt {
    DAY,
    HOUR,
    MINUTE,
    SECOND
}

class IntervalKt(val value: Int, val unit: DateUnitKt) {
    fun toMilisecondsLong(): Long {
        return value.toLong() * when (unit) {
            DateUnitKt.DAY -> 24 * 60 * 60 * 1000
            DateUnitKt.HOUR -> 60 * 60 * 1000
            DateUnitKt.MINUTE -> 60 * 1000
            DateUnitKt.SECOND -> 1000

            else -> 0L
        }
    }
}
