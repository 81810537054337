package ru.arty_bikini.crm_frontend.ui.alert

import csstype.ClassName
import react.dom.html.ReactHTML.button
import react.dom.html.ReactHTML.div
import ru.arty_bikini.crm_frontend.ClientProps
import ru.arty_bikini.crm_frontend.event.ShowAlertEvent
import ru.arty_bikini.crm_frontend.ui.bootstrap.BootstrapColor
import ru.arty_bikini.crm_frontend.ui.bootstrap.alert
import ru.arty_bikini.crm_frontend.ui.bootstrap.badge
import ru.arty_bikini.crm_frontend.ui.root.tryFC
import ru.arty_bikini.crm_frontend.util.StringUtils

external interface AlertItemProps : ClientProps {
    var alert: ShowAlertEvent
    var remove: () -> Unit
}

val AlertItem = tryFC<AlertItemProps> { props ->
    alert(props.alert.color, extraClasses = ClassName("m-0 p-2")) {
        div {
            className = ClassName("vstack gap-1")
            div {
                className = ClassName("hstack gap-1")

                div { className = ClassName("flex-grow-1") }

                div {
                    className = ClassName("flex-shrink-1")

                    badge(BootstrapColor.LIGHT, StringUtils.printLocalDateTime(props.alert.showFrom))
                }

                button {
                    className = ClassName("btn-close flex-shrink-0")

                    onClick = { props.remove() }
                }
            }
            div {
                + props.alert.text

            }
        }
    }
}