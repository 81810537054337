package ru.arty_bikini.crm_frontend.network.dict

import kotlinx.serialization.KSerializer
import ru.arty_bikini.crm.dto.EntityDTO
import ru.arty_bikini.crm.dto.packet.dict.AddDictEntityRequest
import ru.arty_bikini.crm.dto.packet.dict.AddDictEntityResponse
import ru.arty_bikini.crm.dto.packet.dict.EditDictEntityRequest
import ru.arty_bikini.crm.dto.packet.dict.GetDictEntityListResponse
import ru.arty_bikini.crm_frontend.ClientCore
import ru.arty_bikini.crm_frontend.network.BaseService
import ru.arty_bikini.crm_frontend.network.ServerResponse

class BaseDictService<T : EntityDTO>(clientCore: ClientCore, val prefix: String, val type: KSerializer<T>) : BaseService(clientCore) {


    suspend fun getAll(): ServerResponse<GetDictEntityListResponse<T>> {
        return callGeneric("/api/dict/$prefix/get-all", GetDictEntityListResponse.serializer(type))
    }

    suspend fun addEntity(entity: T): ServerResponse<AddDictEntityResponse> {
        return callGeneric(
            "/api/dict/$prefix/add-entity",
            AddDictEntityRequest.serializer(type),
            AddDictEntityResponse.serializer(),
            AddDictEntityRequest(entity)
        )
    }

    suspend fun editEntity(entity: T): ServerResponse<AddDictEntityResponse> {
        return callGeneric(
            "/api/dict/$prefix/edit-entity",
            EditDictEntityRequest.serializer(type),
            AddDictEntityResponse.serializer(),
            EditDictEntityRequest(entity)
        )
    }
}
