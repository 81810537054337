package ru.arty_bikini.crm_frontend.form.main

import csstype.ClassName
import csstype.Overflow
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import react.ChildrenBuilder
import react.dom.html.ReactHTML
import react.dom.html.ReactHTML.h3
import react.dom.html.ReactHTML.hr
import ru.arty_bikini.crm.dto.orders.OrderDTO
import ru.arty_bikini.crm.dto.orders.google.MeasureVariantsDTO
import ru.arty_bikini.crm.dto.orders.google.OrderDataTypeDTO
import ru.arty_bikini.crm_frontend.form.calc.AdditionalItemsInput
import ru.arty_bikini.crm_frontend.measure.MeasureType
import ru.arty_bikini.crm_frontend.table.config.AdditionalItemsSpecial
import ru.arty_bikini.crm_frontend.ui.bootstrap.colLg
import ru.arty_bikini.crm_frontend.ui.bootstrap.inputGroup
import ru.arty_bikini.crm_frontend.ui.bootstrap.row
import ru.arty_bikini.crm_frontend.ui.hint.note
import ru.arty_bikini.crm_frontend.ui.input.form.FormInput
import ru.arty_bikini.crm_frontend.ui.input.table.FieldFlag
import ru.arty_bikini.crm_frontend.ui.root.tryFC
import ru.arty_bikini.crm_frontend.util.StringUtils
import ru.arty_bikini.crm_frontend.util.percent
import ru.arty_bikini.crm_frontend.util.useCache

val MeasuresForm = tryFC<FormPartProps> { props ->

    val orderDataTypes = useCache(props.client.cache.orderDataTypes)
    val measureVariants = useCache(props.client.cache.measureVariants)

    FormInput<OrderDTO>(props.client, props.order) {

        readOnly = props.readOnly

        // Не форма! Нужно для addText и addSelect
        // Сохранение прописано отдельно в каждом поле

        content { entity ->

            val product = entity.product

            fun ChildrenBuilder.allOfGroup(measureType: MeasureType<Nothing>) {

                ReactHTML.div {
                    className = ClassName("vstack gap-1")

                    val all = getMeasures(entity, orderDataTypes, measureType)

                    all
                        .asSequence()
                        .sortedWith(StringUtils.stringOrIntComparator { it.key.displayPosition })
                        .filter { it.key.products.isNullOrEmpty() || product == null || it.key.products?.any { it.id == product.id } == true }
                        .forEach { (key, value) ->

                            val editable = entity.measures?.get(key.id)

                            val variants = measureVariants
                                .filter { it.orderDataType?.id == key.id }
                                .sortedWith(
                                    compareBy<MeasureVariantsDTO> { it.priority }
                                        .thenBy { it.name }
                                )

                            inputGroup {
                                name(key.name ?: "<null>")
                                cb.note(props.client, "s-m-google-${key.id}", noWrap = true)
                                if (props.readOnly && props.client.auth.currentUser?.group?.canEditOrder == false) {
                                    value(editable ?: value, style = { maxWidth = 50.percent; overflow = Overflow.hidden })
                                    return@inputGroup
                                }
                                value(value, color = ClassName("bg-transparent"), style = { maxWidth = 30.percent; overflow = Overflow.hidden })

                                if (variants.isEmpty()) {
                                    addText(
                                        flag = FieldFlag.Nullable,
                                        get = { editable },
                                        set = { value ->

                                            if (value == null) {
                                                return@addText
                                            }

                                            GlobalScope.launch {
                                                props.client
                                                    .network
                                                    .orderData
                                                    .editValue(entity, key, value)
                                            }
                                        }
                                    )
                                } else {
                                    addSelect<MeasureVariantsDTO>(
                                        flag = FieldFlag.Nullable,
                                        variants = variants,
                                        toKey = { it?.id?.toString() ?: "<null>" },
                                        toText = { it.name },
                                        get = {
                                            if (editable == null) {
                                                return@addSelect null
                                            }
                                            val existing = variants.firstOrNull { it.name == editable }
                                            if (existing != null) {
                                                return@addSelect existing
                                            }
                                            return@addSelect MeasureVariantsDTO().apply {
                                                this.id = -1
                                                this.name = editable
                                            }
                                        },
                                        set = { value ->

                                            if (value == null) {
                                                return@addSelect
                                            }

                                            GlobalScope.launch {
                                                props.client
                                                    .network
                                                    .orderData
                                                    .editValue(entity, key, value.name ?: "")
                                            }
                                        }
                                    )
                                }
                            }

                        }

                }
            }

            hr {}

            row {
                colLg(6) {

                    h3 {
                        + "Лиф"
                    }

                    allOfGroup(MeasureType.MEASURES_UP)
                }

                colLg(6) {

                    h3 {
                        +"Трусы"
                    }
                    allOfGroup(MeasureType.MEASURES_DOWN)

                }

                colLg(12) {
                    AdditionalItemsInput(props.client, entity, props.readOnly, showPrices = false, filterSpecial = AdditionalItemsSpecial.SKIRT)
                }

                colLg(12) {

                    h3 {
                        +"Прочее"
                    }
                    allOfGroup(MeasureType.MEASURES_OTHER)

                }
            }

        }

    }

}


fun getMeasures(order: OrderDTO, types: List<OrderDataTypeDTO>, type: MeasureType<Nothing>): Map<OrderDataTypeDTO, String?> {
    val key =
        types
            .filter { it.displayCategory == type.category }
            .sortedBy { it.displayPosition }

    if (key.isEmpty()) {
        return emptyMap()
    }

    return key
        .map { it to order.dataGoogle?.data?.get(it.id) }
        .toMap()
}
