package ru.arty_bikini.crm_frontend.table.config

import ru.arty_bikini.crm.dto.orders.stone.CalcPresetDTO
import ru.arty_bikini.crm_frontend.table.common.dictTablePage
import ru.arty_bikini.crm_frontend.ui.input.table.*

val CalcPresetTablePage = dictTablePage(
    serializer = CalcPresetDTO.serializer(),
    load = { stone.getPresets()?.calcPresetDTOList },
    save = { stone.editPreset(it) },
    addNew = { stone.addPreset(CalcPresetDTO().apply {
        this.name = it
    }) },
) {

    addText(FieldFlag.Nullable, "Название", CalcPresetDTO::name)
    addInt(FieldFlag.NotNull, "Сортировка", CalcPresetDTO::priority, options = TableColumnRenderOptions(defaultSortLegacy = true))
//    addBoolean(FieldFlag.NotNull, "Показать") { this::visible }
    addText(FieldFlag.ReadOnlyValue, "Правила") {
        this.rules?.joinToString(", ") {
            sequenceOf(
                "'${it.stone?.manufacturer} - ${it.stone?.sizeType}'",
                if (it.auto) "[auto]" else null,
                if (it.value > 0) "[${it.value} ${it.type.displayName}]" else null,
            )
                .filterNotNull()
                .joinToString(" ")
        }
    }

}
