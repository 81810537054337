package ru.arty_bikini.crm_frontend.table.report

import react.useState
import ru.arty_bikini.crm.dto.UserDTO
import ru.arty_bikini.crm.dto.enums.UserGroup
import ru.arty_bikini.crm.dto.enums.personalData.ClientType
import ru.arty_bikini.crm.dto.filter.OrderFilter
import ru.arty_bikini.crm.dto.orders.OrderDTO
import ru.arty_bikini.crm.dto.orders.PersonalDataDTO
import ru.arty_bikini.crm_frontend.form.main.ArchiveMainForm
import ru.arty_bikini.crm_frontend.table.common.commonPage
import ru.arty_bikini.crm_frontend.ui.bootstrap.BootstrapColor
import ru.arty_bikini.crm_frontend.ui.bootstrap.alert
import ru.arty_bikini.crm_frontend.ui.bootstrap.cardLightShadow
import ru.arty_bikini.crm_frontend.ui.bootstrap.inputGroup
import ru.arty_bikini.crm_frontend.ui.input.form.FormInput
import ru.arty_bikini.crm_frontend.ui.input.table.*
import ru.arty_bikini.crm_frontend.ui.input.table.data.DataSourceLoadingState
import ru.arty_bikini.crm_frontend.ui.input.table.data.month.MonthPagerDataProviderOrder
import ru.arty_bikini.crm_frontend.ui.input.types.or
import ru.arty_bikini.crm_frontend.util.useCache

val WorkerPaymentTablePage = commonPage(
    requireGroups = listOf(UserGroup.ADMIN, UserGroup.TANYA)
) { props ->

    val workers: List<UserDTO> = useCache(props.client.cache.users)
        .filter { it.group == UserGroup.SEAMSTRESS || it.group == UserGroup.GLUER }

    var current by useState<UserDTO>()

    cardLightShadow {
        FormInput(props.client, null) {
            inputGroup {
                name("Сотрудник")
                addSelect(FieldFlag.Nullable, workers, { it.id.toString() }, { it.name }, { current }, { current = it })
            }
        }
    }

    if (current == null) {
        alert(BootstrapColor.DANGER) { + "Выберите сотрудника!" }
        return@commonPage
    }


    MonthPagerDataProviderOrder(
        client = props.client,
        defaultFilter = { OrderFilter().apply {  }}
    ) { data ->

        cardLightShadow {
            if (data.state != DataSourceLoadingState.LOADED) {
                alert(BootstrapColor.WARNING) { +"Идет загрузка данных" }
            }

            if (data.state == DataSourceLoadingState.LOADED) {

//                ConversionReport {
//                    this.client = props.client
//                    this.data = data.currentData
//                    this.defaultVisible = false
//                }
//
//                MoneyReport {
//                    this.client = props.client
//                    this.data = data.currentData
//                    this.defaultVisible = false
//                }

            }

        }

        cardLightShadow {

            TablePanelOrderSource(props.client, data, "t-r-trainer") {
                addText(FieldFlag.ReadOnlyProp, "ФИО", OrderDTO::name, TableColumnRenderOptions(editButtonForm = ArchiveMainForm))
                addBoolean(FieldFlag.ReadOnlyProp, "Архив", OrderDTO::archive)
                addSelect(FieldFlag.ReadOnlyProp, "Тип", ClientType.values(), OrderDTO::type)
                addDateTime(FieldFlag.ReadOnlyProp, "Дата появления лида", OrderDTO::personalData or ::PersonalDataDTO then PersonalDataDTO::createdTime, TableColumnRenderOptions(defaultSortLegacy = true))
                addText(FieldFlag.ReadOnlyProp, "Предоплата", OrderDTO::personalData or ::PersonalDataDTO then PersonalDataDTO::prepayment)
                addInt(FieldFlag.ReadOnlyProp, "Сумма заказа", OrderDTO::personalData or ::PersonalDataDTO then PersonalDataDTO::payment)
                addInt(FieldFlag.ReadOnlyValue, "Вознаграждение сотруднику за работу") { getWorkMainPay(current) }
                addInt(FieldFlag.ReadOnlyValue, "Вознаграждение сотруднику за допы") { getWorkExtraPay(current) }
                addInt(FieldFlag.ReadOnlyValue, "Вознаграждение сотруднику за расклейку") { getWorkStonePay(current) }

            }
        }
    }
}
