package ru.arty_bikini.crm_frontend.table.report

import csstype.ClassName
import react.dom.html.ReactHTML.hr
import react.useState
import ru.arty_bikini.crm.dto.enums.personalData.ClientType
import ru.arty_bikini.crm_frontend.ui.bootstrap.BootstrapColor
import ru.arty_bikini.crm_frontend.ui.bootstrap.badge
import ru.arty_bikini.crm_frontend.ui.bootstrap.cardCollapse
import ru.arty_bikini.crm_frontend.ui.bootstrap.inputGroup
import ru.arty_bikini.crm_frontend.ui.input.form.FormInput
import ru.arty_bikini.crm_frontend.ui.input.table.FieldFlag
import ru.arty_bikini.crm_frontend.ui.root.tryFC
import ru.arty_bikini.crm_frontend.util.StringUtils
import ru.arty_bikini.crm_frontend.util.personalData

val MoneyReport = tryFC<OrderReportProps> { props ->

    var stored: TempCalculator by useState(TempCalculator())

    val total = props.data.size
    val withPrepay = props.data.asSequence().filter { it.entity.type == ClientType.CLIENT }.map { it.entity }.toList()
    val incomplete = props.data.count { it.entity.archive == false }

    cardCollapse("Деньги",  ClassName("vstack gap-1"), alertColor = BootstrapColor.INFO, defaultVisible = props.defaultVisible ?: true) {

        if (incomplete == 0) {
            badge(BootstrapColor.SUCCESS, "Отчет готов")
        } else {
            badge(BootstrapColor.WARNING, "Не вызрело $incomplete/$total заявок (${100 * incomplete / total}%)")
        }

        inputGroup {
            name("Заявок с предоплатой")
            value(withPrepay.size.toString())
        }

        hr {}

        inputGroup {
            name("Общая сумма зявок")
            value(StringUtils.printNumber(withPrepay.sumOf { it.personalData().payment - it.trainerDiscount }))
        }
        inputGroup {
            name("Сумма скидок тренерам")
            value(StringUtils.printNumber(withPrepay.sumOf { it.trainerDiscount }))
        }
        inputGroup {
            name("Сумма вознаграждения тренерам")
            value(StringUtils.printNumber(withPrepay.sumOf { it.trainerPay }))
        }
        inputGroup {
            name("Сумма вознаграждения сотрудникам")
            value(StringUtils.printNumber(withPrepay.sumOf { it.getWorkMainPay() + it.getWorkStonePay() + it.getWorkExtraPay() }))
        }
        inputGroup {
            name("Расходы на материалы (стразы)")
            value(StringUtils.printNumber(withPrepay.sumOf { it.getResourceStoneCostRub() }))
        }

        val remaining = withPrepay.sumOf { it.personalData().payment - (
                it.trainerDiscount + it.trainerPay + it.getWorkMainPay() + it.getWorkStonePay() + it.getWorkExtraPay() + it.getResourceStoneCostRub()
                ) }
        inputGroup {
            name("Остаток (расходы на ткани/стразы + прочие расходы + прибыль)")
            value(StringUtils.printNumber(remaining))
        }

        hr {}

        FormInput(props.client, stored) {

            onSave { stored = it.copy() }

            inputGroup {
                name("Ежемесячные расходы (аренда и тп)")
                addCost(FieldFlag.NotNull) { this::monthly }
            }
            inputGroup {
                name("Расходы на ткани")
                addCost(FieldFlag.NotNull) { this::resources }
            }
            inputGroup {
                name("Остаток")
                addCost(FieldFlag.ReadOnlyProp) { remaining - monthly - resources }
            }
        }

    }
}

private data class TempCalculator(var monthly: Int = 0, var resources: Int = 0)

