package ru.arty_bikini.crm_frontend.network

import ru.arty_bikini.crm.dto.orders.OrderDTO
import ru.arty_bikini.crm.dto.orders.google.DataGoogleDTO
import ru.arty_bikini.crm.dto.orders.google.MeasureVariantsDTO
import ru.arty_bikini.crm.dto.orders.google.OrderDataTypeDTO
import ru.arty_bikini.crm.dto.packet.ordet_data.*
import ru.arty_bikini.crm_frontend.ClientCore


class OrderDataService(clientCore: ClientCore) : BaseService(clientCore) {

    suspend fun getDataTypes(): GetTypesResponse? {
        return call<GetTypesResponse>("/api/order-data/get-types").body
    }

    suspend fun saveDataType(entity: OrderDataTypeDTO): EditTypeResponse? {
        return call<EditTypeResponse, EditTypeRequest>("/api/order-data/edit-type", body = EditTypeRequest(entity)).body
    }

    suspend fun import() {
        call<Unit>("/api/order-data/import-google").body
    }

    suspend fun importResult(): ImportResultResponse? {
        return call<ImportResultResponse>("/api/order-data/import-result").body
    }

    suspend fun linkToGoogle(order: OrderDTO, google: DataGoogleDTO): LinkOrderToImportResponse? {
        return call<LinkOrderToImportResponse, LinkOrderToImportRequest>(
            "/api/order-data/link-order-to-import",
            body = LinkOrderToImportRequest(idOrder = order.id, idDataGoogle = google.id)
        ).body
    }

    suspend fun unlinkFromGoogle(order: OrderDTO): LinkOrderToImportResponse? {
        return call<LinkOrderToImportResponse, UnlinkOrderFromImportRequest>(
            "/api/order-data/unlink-order-from-import",
            body = UnlinkOrderFromImportRequest(idOrder = order.id)
        ).body
    }

    suspend fun getMeasureVariants(): GetMeasureVariantsResponse? {
        return call<GetMeasureVariantsResponse>("/api/order-data/get-measure-variants").body
    }

    suspend fun editMeasureVariants(entity: MeasureVariantsDTO): EditMeasureVariantsResponse? {
        return call<EditMeasureVariantsResponse, EditMeasureVariantsRequest>(
            "/api/order-data/edit-measure-variants",
            body = EditMeasureVariantsRequest(entity)
        ).body
    }

    suspend fun deleteMeasureVariant(entity: MeasureVariantsDTO): DelMeasureVariantsResponse? {
        return call<DelMeasureVariantsResponse, DelMeasureVariantsRequest>(
            "/api/order-data/del-measure-variants",
            body = DelMeasureVariantsRequest(entity)
        ).body
    }

    suspend fun editValue(order: OrderDTO, colum: OrderDataTypeDTO, value: String): EditValueResponse? {
        return call<EditValueResponse, EditValueRequest>("/api/order-data/edit-value", body = EditValueRequest(order.id, colum.id, value)).body
    }
}
