package ru.arty_bikini.crm_frontend.debug

import ru.arty_bikini.crm.dto.orders.OrderDTO
import ru.arty_bikini.crm.dto.orders.PersonalDataDTO
import ru.arty_bikini.crm_frontend.ui.input.types.PropertyReference
import ru.arty_bikini.crm_frontend.ui.input.types.or
import kotlin.reflect.KMutableProperty1

object Test {
    fun test() {
//        testPropertyReference()
    }

    fun testPropertyReference() {
        test(OrderDTO::name, OrderDTO())
        test(OrderDTO::name or { "test" }, OrderDTO())
        test(OrderDTO::personalData, OrderDTO())
        test(OrderDTO::personalData or { PersonalDataDTO() }, OrderDTO())
        test(OrderDTO::personalData or ::PersonalDataDTO, OrderDTO())
        test(OrderDTO::personalData or ::PersonalDataDTO then PersonalDataDTO::name, OrderDTO())
    }

    private fun <T : Any> test(ref: KMutableProperty1<T, *>, value: T) {
        test(PropertyReference(ref), value)
    }
    private fun <T : Any> test(ref: PropertyReference<T, *>, value: T) {
        console.log(arrayOf("full | " + ref.sortName, ref.get(value), value))

        var idx = 1
        var curr: PropertyReference<T, *>? = ref
        while (curr != null) {
            console.log(arrayOf("part-$idx | " + curr.sortName, curr.get(value), value))


            curr = curr.previous
            idx++
        }
    }

}