package ru.arty_bikini.crm_frontend.ui.input.form

import csstype.ClassName
import csstype.Overflow
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import react.ChildrenBuilder
import ru.arty_bikini.crm.dto.UserDTO
import ru.arty_bikini.crm.dto.orders.OrderDTO
import ru.arty_bikini.crm.dto.orders.google.MeasureVariantsDTO
import ru.arty_bikini.crm.dto.orders.google.OrderDataTypeDTO
import ru.arty_bikini.crm_frontend.ClientCore
import ru.arty_bikini.crm_frontend.ClientProps
import ru.arty_bikini.crm_frontend.measure.MeasureType
import ru.arty_bikini.crm_frontend.measure.SpecialMeasure
import ru.arty_bikini.crm_frontend.ui.bootstrap.InputBlockDSL
import ru.arty_bikini.crm_frontend.ui.hint.note
import ru.arty_bikini.crm_frontend.ui.input.table.FieldFlag
import ru.arty_bikini.crm_frontend.ui.input.types.CostValueConverter
import ru.arty_bikini.crm_frontend.ui.input.types.DateValueConverter
import ru.arty_bikini.crm_frontend.ui.input.types.IntValueConverter
import ru.arty_bikini.crm_frontend.ui.input.types.StringValueConverter
import ru.arty_bikini.crm_frontend.ui.root.tryFC
import ru.arty_bikini.crm_frontend.util.percent
import ru.arty_bikini.crm_frontend.util.useCache

external interface FormInputProps<T> : ClientProps {
    var entity: T
    var dsl: FormInputDSL<T>
}

private val FormInput = tryFC<FormInputProps<*>>() { props ->

    val bounded = props as FormInputProps<Any?>

    val orderDataTypes = useCache(props.client.cache.orderDataTypes)
    val measureVariants = useCache(props.client.cache.measureVariants)

    props.dsl.orderDataTypes = orderDataTypes
    props.dsl.measureVariants = measureVariants

    bounded.dsl.content(this, props.entity)

}

fun <T> ChildrenBuilder.FormInput(client: ClientCore, entity: T, init: FormInputDSL<T>.() -> Unit) {
    FormInput {


        val props = this.unsafeCast<FormInputProps<T>>()

        props.client = client
        props.entity = entity

        val dsl = FormInputDSL<T>(client, entity)
        init(dsl)

        props.dsl = dsl

    }
}

class FormInputDSL<T>(val client: ClientCore, val entity: T) {

    var orderDataTypes: List<OrderDataTypeDTO> = emptyList()
    var measureVariants: List<MeasureVariantsDTO> = emptyList()

    var content: ChildrenBuilder.(T) -> Unit = {}
        private set

    var onSave: suspend (T) -> Unit = {}
        private set

    var readOnly: Boolean = false

    val InputBlockDSL.addUser: (T.() -> UserDTO?) -> Unit
        get() = { this@addUser.value(it(entity)?.name) }

    val InputBlockDSL.addText
        get() = FormInputBuilderText(this.cb, entity, readOnly, onSave, StringValueConverter)
    val InputBlockDSL.addInt
        get() = FormInputBuilderText(this.cb, entity, readOnly, onSave, IntValueConverter)
    val InputBlockDSL.addCost
        get() = FormInputBuilderText(this.cb, entity, readOnly, onSave, CostValueConverter)
    val InputBlockDSL.addDate
        get() = FormInputBuilderText(this.cb, entity, readOnly, onSave, DateValueConverter)


    val InputBlockDSL.addTextArea
        get() = FormInputBuilderTextArea(this.cb, entity, readOnly, onSave)

    val InputBlockDSL.addSelect
        get() = FormInputBuilderSelect(this.cb, entity, readOnly, onSave)

    val ChildrenBuilder.addImages
        get() = FormInputBuilderImage(client, this, entity as? OrderDTO, readOnly)

    inline fun hideIf(rule: T.() -> String?, first: () -> Unit, second: () -> Unit) {
        if (!readOnly || client.auth.currentUser?.group?.canEditOrder == true) {
            first()
            second()
        } else {
            if (rule(entity).isNullOrBlank()) {
                first()
            } else {
                second()
            }
        }
    }

    inline fun hideIfInt(rule: T.() -> Int?, first: () -> Unit, second: () -> Unit) {
        if (!readOnly) {
            first()
            second()
        } else {
            if (rule(entity).let { it == null || it == 0 }) {
                first()
            } else {
                second()
            }
        }
    }

    fun content(init: ChildrenBuilder.(T) -> Unit) {
        this.content = init
    }

    fun onSave(action: suspend (T) -> Unit) {
        this.onSave = action
    }

}


val FormInputDSL<OrderDTO>.addSpecial: InputBlockDSL.(
    flag: FieldFlag.FromGoogleForms,
    type: MeasureType<SpecialMeasure>,
    extra: SpecialMeasure
) -> Unit
    get() = { flag, type, extra, ->
        val key = getMeasureType(orderDataTypes, type, extra)

        if (key != null) {
            cb.note(client, "s-m-google-${key.id}", noWrap = true)
        }

        val value = this@addSpecial.entity.getMeasure(orderDataTypes, type, extra)
        value(
            text = value,
            color = ClassName("bg-transparent"),
            style = { maxWidth = 30.percent; overflow = Overflow.hidden }
        )


    }

val FormInputDSL<OrderDTO>.addSpecialWithInput: InputBlockDSL.(
    flag: FieldFlag.FromGoogleForms,
    type: MeasureType<SpecialMeasure>,
    extra: SpecialMeasure,
) -> Unit
    get() = get@{ flag, type, extra, ->


        val key = getMeasureType(orderDataTypes, type, extra)

        val editable = entity.measures?.get(key?.id)

        val variants = measureVariants
            .filter { it.orderDataType?.id == key?.id }
            .sortedWith(
                compareBy<MeasureVariantsDTO> { it.priority }
                    .thenBy { it.name }
            )

        val value = entity.getMeasure(orderDataTypes, type, extra)

        if (key == null || readOnly && client.auth.currentUser?.group?.canEditOrder == false) {
            if (key != null) {
                cb.note(client, "s-m-google-${key.id}", noWrap = true)
            }
            value(editable ?: value, style = { maxWidth = 50.percent; overflow = Overflow.hidden })
            return@get
        }
        addSpecial(flag, type, extra)

        if (variants.isEmpty()) {
            addText(
                flag = FieldFlag.Nullable,
                get = { editable },
                set = { value ->

                    if (value == null) {
                        return@addText
                    }

                    GlobalScope.launch {
                        client
                            .network
                            .orderData
                            .editValue(entity, key, value)
                    }
                }
            )
        } else {
            addSelect<MeasureVariantsDTO>(
                flag = FieldFlag.Nullable,
                variants = variants,
                toKey = { it.id.toString() },
                toText = { it.name },
                get = {
                    if (editable == null) {
                        return@addSelect null
                    }
                    val existing = variants.firstOrNull { it.name == editable }
                    if (existing != null) {
                        return@addSelect existing
                    }
                    return@addSelect MeasureVariantsDTO().apply {
                        this.id = -1
                        this.name = editable
                    }
                },
                set = { value ->

                    if (value == null) {
                        return@addSelect
                    }

                    GlobalScope.launch {
                        client
                            .network
                            .orderData
                            .editValue(entity, key, value.name ?: "")
                    }
                }
            )
        }

    }

private fun <T : Enum<T>> getMeasureType(orderDataTypes: List<OrderDataTypeDTO>, type: MeasureType<T>, extra: T): OrderDataTypeDTO? {

    return orderDataTypes
        .find { it.displayCategory == type.category && it.displayPosition == extra.name }
}

fun <T : Enum<T>> OrderDTO.getMeasure(orderDataTypes: List<OrderDataTypeDTO>, type: MeasureType<T>, extra: T): String? {

    val key = getMeasureType(orderDataTypes, type, extra) ?: return null

    return dataGoogle
        ?.data
        ?.get(key.id)

}

