package ru.arty_bikini.crm_frontend.form.main

import csstype.ClassName
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import react.dom.html.ReactHTML
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.hr
import ru.arty_bikini.crm.dto.enums.OrderFileCategory
import ru.arty_bikini.crm.dto.enums.design.GlueDesign
import ru.arty_bikini.crm.dto.enums.design.TextileOrder
import ru.arty_bikini.crm.dto.orders.OrderDTO
import ru.arty_bikini.crm_frontend.form.calc.AdditionalItemsInput
import ru.arty_bikini.crm_frontend.measure.MeasureType
import ru.arty_bikini.crm_frontend.measure.SpecialMeasure
import ru.arty_bikini.crm_frontend.table.config.AdditionalItemsSpecial
import ru.arty_bikini.crm_frontend.ui.bootstrap.*
import ru.arty_bikini.crm_frontend.ui.input.form.FormInput
import ru.arty_bikini.crm_frontend.ui.input.form.addSpecial
import ru.arty_bikini.crm_frontend.ui.input.form.addSpecialWithInput
import ru.arty_bikini.crm_frontend.ui.input.table.FieldFlag
import ru.arty_bikini.crm_frontend.ui.root.tryFC
import ru.arty_bikini.crm_frontend.util.*

val DesignFormPart = tryFC<FormPartProps> { props ->

    val productTypes = useCache(props.client.cache.productTypes)
    val strapsTypes = useCache(props.client.cache.strapsTypes)

    FormInput(props.client, props.order) {

        readOnly = props.readOnly

        onSave {
            props
                .client
                .network
                .order
                .saveOrder(it)
        }

        content { entity ->

            cardGreenCollapse("Дизайн", ClassName("vstack gap-2"), defaultVisible = false) {


                row {
                    colLg(6) {
                        inputGroup {
                            name("Цвет купальника")
                            hideIf(
                                { this.design().color },
                                { addSpecial(FieldFlag.FromGoogleForms, MeasureType.SPECIAL, SpecialMeasure.COLOR_GOOGLE) },
                                { addText(FieldFlag.Nullable) { this.design()::color } }
                            )
                        }
                    }
                    colLg(6) {
                        inputGroup {
                            name("Категория")
                            hideIf(
                                { this.product?.name },
                                { addSpecial(FieldFlag.FromGoogleForms, MeasureType.SPECIAL, SpecialMeasure.CATEGORY_GOOGLE) },
                                { addSelect(FieldFlag.Nullable, productTypes, { it.name }, { this::product }) }
                            )
                        }
                    }
                }

                row {
                    colLg(6) {
                        inputGroup {
                            name("Ткань")
                            hideIf(
                                { this.design().textile?.displayName },
                                { addSpecial(FieldFlag.FromGoogleForms, MeasureType.SPECIAL, SpecialMeasure.TEXTILE_GOOGLE)},
                                { addSelect(FieldFlag.Nullable, TextileOrder.values()) { this.design()::textile }}
                            )
                        }
                    }
                    colLg(6) {
                        AdditionalItemsInput(props.client, entity, props.readOnly, showPrices = false,  columns = 1, filterSpecial = AdditionalItemsSpecial.FABRIC)
                    }
                }

                hr { className = ClassName("expand-3") }

                row {
                    colSm(6) {
                        inputGroup {
                            name("Клей")
                            hideIf(
                                { this.design().glue?.displayName },
                                { addSpecial(FieldFlag.FromGoogleForms, MeasureType.SPECIAL, SpecialMeasure.GLUE_GOOGLE)},
                                { addSelect(FieldFlag.Nullable, GlueDesign.values()) { this.design()::glue }}
                            )
                        }
                    }
                    colLg(6) {
                        AdditionalItemsInput(props.client, entity, props.readOnly, showPrices = false,  columns = 1, filterSpecial = AdditionalItemsSpecial.GLUE)
                    }
                }


                inputGroup {
                    name("Количество страз")
                    entity.stones?.forEach {

                        val text = "${it.rhinestoneType?.manufacturer} - ${it.rhinestoneType?.sizeType}: ${it.count} штук"

                        warn(BootstrapColor.SECONDARY, text)
                    }
                }

                hr { className = ClassName("expand-3") }

//                if (!props.readOnly || props.client.auth.currentUser?.group?.canEditOrder == true) {
//                    inputGroup {
//                        name("Дизайн от клиента из формы заказа")
//                        addSpecial(FieldFlag.FromGoogleForms, MeasureType.SPECIAL, SpecialMeasure.DESIGN_GOOGLE)
//                    }
//                }

                row {
                    colSm(6) {
                        inputGroup {
                            name("Лямки:")
                            hideIf(
                                { this.design().glue?.displayName },
                                { addSpecial(FieldFlag.FromGoogleForms, MeasureType.SPECIAL, SpecialMeasure.CONECTOR_WERE)},
                                { addSelect(FieldFlag.Nullable, strapsTypes, { it.name.toString() }) { this.design()::straps }}
                            )
                        }
                    }


                    colSm(6) {
                        inputGroup {
                            name("Конекторы:")
                            addSpecialWithInput(FieldFlag.FromGoogleForms, MeasureType.SPECIAL, SpecialMeasure.CONECTOR)
                        }
                    }
                }

                hr { className = ClassName("expand-3") }

                AdditionalItemsInput(props.client, entity, props.readOnly, showPrices = false, filterSpecial = AdditionalItemsSpecial.DESIGN)
                inputColumn {
                    name("Дизайн лифа")

                    if (props.readOnly) {
                        raw {
                            div {
                                className = ClassName("card")

                                div {
                                    className = ClassName("card-body")

                                    + entity.design().commentDesignUP.orEmpty()
                                }
                            }
                        }
                    } else {
                        addTextArea(3) { this.design()::commentDesignUP }
                    }
                }
                inputColumn {
                    name("Дизайн трусов")
                    if (props.readOnly) {
                        raw {
                            div {
                                className = ClassName("card")

                                div {
                                    className = ClassName("card-body")

                                    + entity.design().commentDesignDoun.orEmpty()
                                }
                            }
                        }
                    } else {
                        addTextArea(3) { this.design()::commentDesignDoun }
                    }
                }

                inputColumn {
                    name("Допы")
                }

                AdditionalItemsInput(props.client, entity, props.readOnly, showPrices = false, columns = 3)

                inputColumn {
                    name("Допы (халат, серьги, туфли)")
                    addText(FieldFlag.Nullable) { this.personalData()::addOrder }
                }

                hr { className = ClassName("expand-3") }

                addImages(OrderFileCategory.DESIGN)

                row {
                    colSm(4) {
                        inputGroup {
                            name("Подтвердить")
                            with(cb) {
                                ReactHTML.button {
                                    if (entity.statusInfo().isDesignAll) {
                                        className = BootstrapButton.painted(BootstrapColor.SUCCESS)

                                        disabled = true

                                        +"ОК"
                                    } else {
                                        className = BootstrapButton.painted(BootstrapColor.WARNING)

                                        +"Подтверждаю"

                                        disabled = props.readOnly
                                        if (props.readOnly) return@button

                                        onClick = {

                                            val updated = entity.clone(OrderDTO.serializer())

                                            updated.statusInfo().isDesignAll = true

                                            GlobalScope.launch {
                                                props.client
                                                    .network
                                                    .order
                                                    .saveOrder(updated)
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    colSm(4) {
                        inputGroup {
                            name("дизайн заполнил:")
                            addUser { this.statusInfo().designBy } //user который кнопку нажал
                        }
                    }

                    colSm(4) {
                        inputGroup {
                            name("Таня Дизайн")
                            with(cb) {
                                ReactHTML.button {
                                    if (entity.statusInfo().isDesignAllTanya) {
                                        className = BootstrapButton.painted(BootstrapColor.SUCCESS)

                                        disabled = true

                                        +"ОК"
                                    } else {
                                        className = BootstrapButton.painted(BootstrapColor.WARNING)

                                        +"Подтверждаю"

                                        disabled = props.readOnly
                                        if (props.readOnly) return@button

                                        onClick = {

                                            val updated = entity.clone(OrderDTO.serializer())

                                            updated.statusInfo().isDesignAllTanya = true

                                            GlobalScope.launch {
                                                props.client
                                                    .network
                                                    .order
                                                    .saveOrder(updated)
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }


            }

        }
    }
}
