package ru.arty_bikini.crm_frontend.ui.modal

import kotlinx.serialization.KSerializer
import react.ChildrenBuilder
import react.FC
import ru.arty_bikini.crm.dto.orders.OrderDTO
import ru.arty_bikini.crm_frontend.ClientCore
import ru.arty_bikini.crm_frontend.network.Entity
import ru.arty_bikini.crm_frontend.network.NetworkModule
import ru.arty_bikini.crm_frontend.ui.root.tryFC

class ModalConfig<E>(
    val title: E.() -> String,
    val autoClose: Boolean,
    val trackEntity: EntityTrackRule<E>?,
    val form: FC<FormProps<E>>
) {

    val render: ChildrenBuilder.(client: ClientCore, entity: E) -> Unit = { client: ClientCore, entity: E ->
        form {
            this.client = client
            this.entity = entity
        }
    }

    fun openAction(client: ClientCore): (E) -> Unit {
        return { entity ->
            client.event.showModal(this@ModalConfig, entity)
        }
    }

    fun openNow(client: ClientCore, entity: E) = openAction(client)(entity)

}

class EntityTrackRule<T> private constructor(
    val type: String?,
    val id: (T) -> Int,
    val get: (suspend NetworkModule.(T) -> T?)?,
    val version: ((T) -> String)?,
) {
    companion object {
        operator fun <T : Entity> invoke(serializer: KSerializer<T>): EntityTrackRule<T> {
            return EntityTrackRule<T>(serializer.descriptor.serialName, { it.id }, null, null)
        }
        fun order(): EntityTrackRule<OrderDTO> {
            return EntityTrackRule<OrderDTO>(null, { it.id }, { order.getOrder(it)?.orderDTO }, { it.version.toString() })
        }
    }
}

fun <E> createModal(
    title: E.() -> String = { "Modal title" },
    autoClose: Boolean = true,
    trackEntity: EntityTrackRule<E>? = null,
    block: ChildrenBuilder.(props: FormProps<E>) -> Unit,
): ModalConfig<E> {
    return ModalConfig(title, autoClose, trackEntity, tryFC(block))
}
