package ru.arty_bikini.crm_frontend.util

import csstype.ClassName
import csstype.Length
import csstype.LengthType
import csstype.px

operator fun ClassName.plus(other: ClassName): ClassName {
    return ClassName(this.unsafeCast<String>() + " " + other.unsafeCast<String>())
}

operator fun ClassName?.plus(other: ClassName): ClassName {
    if (this == null) {
        return other
    }
    return ClassName(this.unsafeCast<String>() + " " + other.unsafeCast<String>())
}

operator fun ClassName.plus(other: ClassName?): ClassName {
    if (other == null) {
        return this
    }
    return ClassName(this.unsafeCast<String>() + " " + other.unsafeCast<String>())
}


val Int.percent get() = "${this}%".unsafeCast<Length>()

fun min(vararg values: Length): Length {
    if (values.isEmpty()) return 0.px
    return values.joinToString(", ", "min(", ")") { it.toString() }.unsafeCast<Length>()
}

fun max(vararg values: Length): Length {
    if (values.isEmpty()) return 0.px
    return values.joinToString(", ", "max(", ")") { it.toString() }.unsafeCast<Length>()
}
